import Api from '@/services/Api'

export default {
    getPlaylist(title, userId) {
        return Api().get(`${process.env.VUE_APP_URL}/playlist/${encodeURIComponent(title)}/${userId}`)
    },
    getByUserVideoPlaylist(id, offset = 0) {
        if (offset != 0) {
            return Api().get(`${process.env.VUE_APP_URL}/playlist/userPlaylist/${id}/${offset}`)
        }
        return Api().get(`${process.env.VUE_APP_URL}/playlist/userPlaylist/${id}`)
    },
    getVideo(category, title) {
        return Api().get(`${process.env.VUE_APP_URL}/videos/name/${category}/${title}`)
    },
    createPlaylist(payload) {
        return Api().post(`${process.env.VUE_APP_URL}/playlist/add`, payload);
    },
    addVideo(playId, videoId) {
        return Api().post(`${process.env.VUE_APP_URL}/playlist/addVideo/${playId}`, { id : videoId})
    },
    deleteVideo(playId, videoId) {
        return Api().post(`${process.env.VUE_APP_URL}/playlist/remove/${playId}`, { vidId : videoId})
    }
}