<template>
    <div class="layout-default">
        <sidebar > 
            <playlistNavBar  :playlists="playlists" @createPlaylist="playlistMod=true" :activeSection="activePlaylist" @getPlaylist="getCurrentPlaylist" :lang="langObj" class="playlist-sidebar"/>
        </sidebar>
        <div class="layout-side">
            <navBar :activeLink="'playlist'" :isSection="true"> 
                <!-- <playlistNavBar class="sidebar-mobile" :playlists="playlists" @createPlaylist="playlistMod=true" :activeSection="activePlaylist" @getPlaylist="getCurrentPlaylist" :lang="langObj"/> -->
            </navBar>
            <!-- <div class="playlist-container">
                <Transition name="mobile-trans">
                    <playlistNavBar class="sidebar" :class="{'sidebar-responsive' : isResponsive}" :playlists="playlists" @createPlaylist="playlistMod=true" :activeSection="activePlaylist" @getPlaylist="getCurrentPlaylist" :lang="langObj"/>
                </Transition>
            </div> -->
            <section class="playlist-view viewer">
                <div class="title_section">
                    <h3 class="hdl-sm hdl-sm--sbold"> 
                        {{ langObj.playlist.title }} / {{ activePlaylist }}
                        <div class="line line-100"></div>
                    </h3>  
                </div>
                <div v-if="videoEmpty == true" class="video_empty">
                    <h2> {{ langObj.playlist.unaivalable }} </h2>
                </div>
                <div class="video_list" v-else-if="videoPlaylist.length > 0">
                    <div class="playlist_instance" v-for="play in videoPlaylist" :key="play.id">
                        <div class="playlist_cover">
                            <img class="video-cover" :src="play.coverUrl + '-a.jpg'"/>
                            <div class="play-cover-overlay">
                                <router-link :to="'/Video/' + play.catTitle + '/' + play.title" class="btn-neutral bd-md">
                                    <span>{{ langObj.videoDetail.playlist.viewVideo }}</span> <i class="pi pi-eye"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="playlist_description">
                            <p class="hdl-sm">{{ titleDisplay(play.title) }} </p>
                            <p class="author_desc hot-spot bd-lg bd-lg--sbold">{{ play.firstname }} {{ play.lastname }}</p>
    
                        </div>
                    </div>
                </div>
                <div v-else class="playlist-loading">
                        <div class="play-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                </div>
            
                <div class="pagination" v-if="playlistLength != 0">
                    <div class="pagination-wrapper">
                        <button class="pg-left" v-if="activePage > 0" @click="getPlaylistByPage(activePage -1)">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                        <ul class="pages-number">
                            <li v-for="(page, index) in playlistLength " :key="index" :class="{'page-active' : (index == activePage )}" @click="getPlaylistByPage(index)">
                                {{page}}
                            </li>
                        </ul>
                        <button class="pg-right" v-if="activePage < (playlistLength-1)" @click="getPlaylistByPage(activePage + 1)">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </section>

        </div>
    </div>
    <!-- <div class="playlist-head">
        <h3> {{ langObj.playlist.title }} </h3>
        <button class="menu-btn" @click="isResponsive = !isResponsive">
            <i class="fas fa-bars"></i>
        </button>
    </div> -->
    <footerNav :lang="lang"/>
    <div class="overlay-modal" v-show="playlistMod">
        <Transition name="playlist_modal">
            <playlistComponent class="playlistModal" @closePlaylist="playlistMod = false" :title="langObj.playlist.create.createTitle" :createActive="true" :createProp="createProp" v-show="playlistMod" @successCreated="playlistCreated" :lang="langObj"/>
        </Transition>
    </div>
    <alertModal v-show="isAlert" :text="alertText"/>
</template>

<script>
import navBar from '@/components/navhead';
import sidebar from '@/components/sidebar';
import playlistNavBar from '@/components/playlistnavbar';
import playlistComponent from '@/components/playlistComponent';
import alertModal from '@/components/alertModal';

import { useRoute } from 'vue-router';
import playlistService from '@/services/PlaylistService';
import userService from '@/services/UserService';
//import categoryService from '@/services/CategorieService';
import { ref, onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';
import footerNav from '@/components/footer.vue';

export default {
    name : "Playlist",
    data () {
        return {
            playlistMod : false,
            createProp : {
                text : "A text"
            },
            isResponsive : false,
            //activePlaylist : 'Watch Later',
            activePage : 0,
            isAlert : false,
            alertText : 'This is a simple alert-text'
        }
    },
    setup() {
        const route  = useRoute();
        const store = useStore();
        const videoPlaylist = ref([]);
        const playlists = ref([]);
        //const categories = ref([]);
        const user = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        const lang = ref(user.value.lang);
        const langObj = ref(store.getters['getLang']).value[user.value.lang]
        console.log(route);
        const playlistTitle = ref(route.params.title);
        const playlistId = ref(null)
        const videoEmpty = ref();
        const playlistLength = ref(0);
        const activePlaylist = ref(route.params.title);
        onBeforeMount ( async () => {
            // Getting actual playlist , default : Watch Later
            await playlistService.getPlaylist(playlistTitle.value, user.value.id).then( async (res) => {
                console.log(res)
                if (res.data[0]) {
                    playlistId.value = res.data[0].id 
                    // Get all user's videos of actual playlist
                    await playlistService.getByUserVideoPlaylist(res.data[0].id).then( async (res) => {
                        console.log(res)
                        if (res.data.error) {
                            console.log("no video");
                            videoEmpty.value = true;
                        } else {
                            videoPlaylist.value = res.data
                            // Get all others playlists
                            if (videoPlaylist.value.length != 0) {
                                playlistLength.value = Math.ceil(videoPlaylist.value.length / 6)
                            }
                        }
                        
                        
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
            // Get ctegories
            /*await categoryService.getAllCategories(user.value.lang).then(res => {
                    console.log(res);
                    let categoryData = res.data;
                    categories.value  = categoryData;

                })
                .catch (err => {
                    console.log(err);
                })*/
            // Get users's playlists
            await userService.getUserPlaylists(user.value.id).then( res => {
                            playlists.value = res.data;
                        })
                        .catch(err => {
                            console.log(err)
                        })
        })

        return {
            videoPlaylist,
            videoEmpty,
            playlists,
            activePlaylist,
            user,
            lang,
            langObj,
            playlistLength,
            playlistId
            //categories
        }
    },
    components : {
        navBar,
        sidebar,
        playlistNavBar,
        playlistComponent,
        alertModal,
        footerNav
    },
    methods : {
        titleDisplay(title) {
            return title.split("_").join(" ")
        },
        getCurrentPlaylist(id, title) {
            this.activePlaylist = title;
            this.videoPlaylist = []
            playlistService.getByUserVideoPlaylist(id).then(res => {
                console.log(res)
                if (res.data.error) {
                    this.videoEmpty = true;
                } else {
                    this.videoPlaylist = res.data
                    this.videoEmpty = false;
                    this.playlistLength = this.videoPlaylist.length

                }
            })
            .catch(err => {
                console.log(err)
            })
        },
        getPlaylistByPage(offset) {
            playlistService.getByUserVideoPlaylist(this.playlistId, offset)
            .then(res => {
                if (res.data.error) {
                    console.log("no video");
                    this.videoEmpty = true;
                } else {
                    this.videoPlaylist = res.data
                    // Get all others playlists
                    this.playlistLength = Math.ceil(this.videoPlaylist.length / 6)
                }
            })
            .catch(err => {
                console.log(err)
            })
        },
        playlistCreated() {
            this.alertText = this.langObj.playlist.created
            this.isAlert = true;
            setTimeout(() => {
                this.isAlert = false
            }, 2000);
            this.playlistMod = false
            userService.getUserPlaylists(this.user.id).then( res => {
                this.playlists = res.data;
            })
            .catch(err => {
                console.log(err)
            })
        }

    }
}
</script>

<style lang="scss" scoped>

</style>