<template>
    
        <div class="sup-container">
            <div class="illustration">
                <!-- <div class="overlay"></div> -->
                <div class="logo-ctn">
                    <img :src="brandLogo">
                </div>
                <div class="true-illustraion">
                    <img :src="svgLogo"/>
                    <p class="bd-lg bd-lg--sbold">
                        {{ langue.login.quote.content }}
                    </p>
                </div>
                <!--<blockquote>
                    <h4> {{ langue.login.quote.title }} </h4>
                    <cite> &ldquo; {{ langue.login.quote.content }} &rdquo;</cite>
                </blockquote>-->

            </div>
            <div class="container">
                <div class="sub-header bd-sm bd-sm--sbold">
                    <span>{{ langue.login.account.text }}</span> <a href="https://takafamindset.com" target="_blank">{{ langue.login.account.signup }} </a>
                </div>
                <header>
                    <!--<div class="logo-container">
                    </div>-->
                    <h3 class="hdl-md hdl-md--sbold"> {{ langue.login.text1 }} </h3>
                    <!-- <p> {{ langue.login.text2 }} </p> -->
                </header>
                <!-- <hr/> -->
                <form class="form-container" @submit.prevent="login">
                    <div class="input-instance" :class="{'input-instance--content' : user.email == '', 'input-instance--hascontent' : user.email != '' }">
                        <input type="email" name="email" v-model="user.email" >
                        <label>{{ langue.login.input.field1 }}</label>
                        <div class="error">
                            <span :class="{ 'error--active': error.state }"> {{ error.name }} </span>
                        </div>
                        
                    </div>
                    <div class="input-instance password-field" :class="{'input-instance--content' : user.loginpassword == '', 'input-instance--hascontent' : user.loginpassword != '' }">
                        <input class="input-pass" :type="showPass" name="loginpassword" v-model="user.loginpassword">
                        <label>{{ langue.login.input.field2 }}</label>
                        <span class="eye-show"  @click="passShow = !passShow"><i :class="{'pi pi-eye': passShow, 'pi pi-eye-slash': !passShow}"></i></span>
                        <div class="error">
                            <span :class="{ 'error--active': error.state }"> {{ error.pass }} </span> 
                            <a :href="forgotLink" class="forgot-pass"> {{ langue.login.input.forgot }}</a>
                        </div>
                    </div>
                    <button class="log-in btn" type="submit" :disabled="loading">
                        <span v-show="!loading">{{ langue.login.input.button }}</span>
                        <div v-show="loading" class="lds-ring">
                            <div></div>
                            <div></div>
                            <div class="spinner"></div>
                            <div></div>
                        </div>
                    </button>
                </form>
                <!--<div class="language">  
                    <span>Change Language </span> : <span>Français</span>
                </div>-->
            </div>
        </div>
        <footerNav :lang="currentLang" class="foot"/>

</template> 

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue';
//import navBar from '@/components/navBar.vue';
//import * as Vue from 'vue' // in Vue 3
import takafaLogo from './../../assets/Images/Logo sans slogan.png';
import takafaLogin from './../../assets/Images/login-academy.svg';
//import axios from 'axios'
//import VueAxios from 'vue-axios'
import { useStore, mapState, mapActions, mapMutations } from 'vuex';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import footerNav from '@/components/footer.vue';

export default {
    //inject : ['store'],
    setup() {
        const store = useStore();
        //const count = computed(()=> store.state.count)
        const route = useRoute();
        const langue = ref(null)
        const lang = store.getters['getLang'];
        const currentLang = ref(null)
        let forgotLink = '/forgot-password'
        function hello() {
            alert(store.state.auth.count);
            store.dispatch('increment');
        }
        if (route.params.lang == "fr") {
            langue.value = lang.FR
            forgotLink = '/fr/forgot-password'
            currentLang.value = "FR"
        } else {
            langue.value = lang.EN;
            currentLang.value = "EN"
        }
        return {
            hello,
            langue,
            currentLang,
            forgotLink
        }
    },
    name: 'Login',
    data () {
        return {
            user : {
                email : '',
                loginpassword : '',
            },
            passShow : false,
            error : {
                name : "",
                pass : "",
                state : false
            },
            brandLogo : takafaLogo,
            loading : false,
            svgLogo : takafaLogin
        }
    },
    computed: {
        counter() {
            return console.log(this.$store.state.auth.count);
        },
        ...mapState(['auth']),
        showPass() {
            if (this.passShow) {
                return 'text'
            }
            return 'password'
        }
    },
    components : {
        footerNav
    },
    methods: {
        /*onLogin() {
            let data = new FormData();
            data.append("username", this.user.username);
            data.append("password", this.user.loginpassword);
            axios.post("http://localhost/Takafa%20Streaming/api.php?action=login", data).then(res => {
                //console.log(res);
                if (res.data.error) {
                    console.log("Error",res)
                } else {
                    console.log("success",res);
                    this.$router.push("/home");
                }
            }).catch(error=> {
                console.log(error);
            })
            //alert("okay");
        }*/
        async login() {
            if (this.user.email  == null || this.user.email  == '' ) {
                this.error.state = true;
                this.error.name = "Empty Email";
            } else {
                this.error.state = false;
                this.error.name = "";
            }
            if (this.user.loginpassword  == null || this.user.loginpassword  == '') {
                this.error.state = true;
                this.error.pass = "Empty Password";
            } else {
                this.error.state = false;
                this.error.pass = "";
            }
            if(this.error.state) {
                console.log(this.error);
                return
            } 
            //if (this.loading) return
            this.loading = true;
            console.log(this.user.email + " " + this.user.loginpassword);
            const data = await this.$store
            .dispatch('signIn', { email: this.user.email, password: this.user.loginpassword })
            .catch((err) => {
                this.loading = false
                console.log(err);
                return {
                    "message" : "error connecting"
                };
                /*this.$refs.form.setErrors({
                    Email: ["We don't reconize, this email"],
                    Password: ["We don't reconize, this password"]
                })*/
            })

            console.log(data);

            if (!data || data.error) {
                this.loading = false;
                if(data.message == "Invalid email") {
                    this.error.name = data.message
                } else if(data.message == "Invalid password") {
                    this.error.pass = data.message
                } else if (data.token == (null || "")) return
                
                return
            }
            /*const user = await this.$store
                .dispatch('getCurrentuser', data.token)
                .catch((err) => console.log(err))
            if (!user) return*/
            setTimeout(()=>{
                this.$router.push({ name: 'Home' })
                this.loading = false
            }, 500)
        },
        hello2(){
            //console.log(true);
            this.$store.dispatch('increment');
        },
        ...mapActions([
            'increment',
            'signIn'
        ]),
        ...mapMutations({
            increment:'INCREMENT'
        }),
        
    }

}
</script>

<style lang="scss" scoped>
    

    /*@import './../../assets/styles/_variables.scss';
    @import './../../assets/styles/_globals.scss';

    .sup-container {
        position: relative;
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        //outline: 2px solid black;
        box-sizing: border-box;
        font-size : $base-font;
        .illustration {
            position: relative;
            width: 37%;
            min-height: 100%;
            background: url("https://admin.takafamindset.com/storage/Canva - African American Woman.jpg");
            background-size: cover;
            background-position: 50% 0%;
            position: relative;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $linear_overlay;
                //@include overlayBack(0.4)
            }
            blockquote {
                position: absolute;
                bottom: 150px;
                left: 40%;
                width: 70%;
                transform: translateX(-50%);
                z-index : 4;
                color : $white_text_color;
                font-size: $label-font;
                //outline : 1px solid blue;

                cite {
                    //text-align: justify;
                    width: 100%;
                    text-align: left;
                    line-height: 1.3;
                    //margin: 0;
                }

                h4 {
                    color: $main_color;
                    font-weight: bolder;
                    letter-spacing : 1px;
                    width: 100%;
                    text-align: center;
                }
            }

            @media screen and (max-width:$tablets) {
                //font-size: $fbase;
                display : none;
            }
        }

        .container {
            width: 70%;
            box-sizing: border-box;
            padding: 50px;
            padding-left: 7%;
            //display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            line-height : 1.2;
            @media screen and (max-width:$tablets) {

                width : 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-left : 50px;
                align-items : center;
            }
            header {
                //width: 100%;
                .logo-container {
                    width: 220px;
                    padding: 0;
                    margin-left: -8px;
                    //outline: 2px solid black;
                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                    }
                    @media screen and (max-width:$tablets) {
                        
                        height : 60px;

                    }
                }
                h3 {
                    padding: 0;
                    margin: 0;
                    margin-top: 10px;
                    font-size : $font-x-medium;
                    text-align: left;
                    letter-spacing: .7px;
                    font-weight: bolder;
                    @media screen and (max-width:$tablets) {
                        text-align : center;
                    }
                }
                p {
                    padding: 0;
                    text-align: left;
                    color: $text_color;
                    text-shadow: 0px 1px 1px $shadow_text_color;
                    font-weight: lighter;
                    @media screen and (max-width:$tablets) {
                        text-align : center;
                    }
                }
                @media screen and (max-width:$tablets) {
                    display: flex;
                    flex-direction : column;
                    justify-content: space-between;
                    align-items : center;
                }
            }
            hr {
                width: 80%;
                margin-left: 0;
                @media screen and (max-width:$tablets) {
                    text-align: center;
                    //display : none;
                    width : 100%;
                    //margin-left : 10%;
                }
            }
            .form-container {
                box-sizing: border-box;
                width: 50%;
                margin-top: 10px;
                padding: 30px 0px;
                //outline: 1px solid black;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                @media screen and (max-width:$tablets) {
                    width : 60%;
                    margin-top : -10px;
                }
                @media screen and (max-width:$mobile) {
                    width : 100%;
                    margin-top : -10px;
                }
                .input-instance {
                    position: relative;
                    //height: 40px;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 10px 0;
                    label  {
                        display: block;
                        text-align: left;
                        margin-bottom: 10px;
                        color: $text_color;
                        font-weight: bold;
                        font-size: $label_font;
                    }
                    input {
                        @extend %input-base;
                        height : 40px;
                        width: 100%;
                        margin-bottom: 7px;

                        &:focus + .eye-show {
                            color: $main_color;
                        }
                        
                        
                    }
                    .input-pass {
                        padding-right : 32px
                    }
                    .eye-show {
                        position : absolute;
                        top: 50%;
                        right: 10px;
                        color: $input_border_color;
                        cursor : pointer;
                    }
                    .error {
                        text-align: left;
                        position: relative;
                        margin-left: 0;
                        left: 0;
                        //height: 10px;
                        //outline: 1px solid black;
                        span {
                            color: $form_error;
                            visibility: none;
                        }
                        &--active {
                            visibility: visible;
                        }
                        .forgot-pass {
                            position: absolute;
                            right: 0;
                            font-weight: bold;
                            color: $main_color;
                            font-size: $label_font;
                        }
                    }
                }
                .log-in {
                    @extend %btn;
                    margin-top: 40px;
                    .lds-ring {
                        display: inline-block;
                        position: relative;
                        width: 30px;
                        height: 30px;
                        box-sizing : border-box;
                        //outline : 2px solid red;
                        & div {
                            box-sizing: border-box;
                            display: block;
                            position: absolute;
                            width: 25px;
                            height: 25px;
                            margin: 4px;
                            border: 2px solid #fff;
                            border-radius: 50%;
                            top :0%;
                            left: 0%;
                            //transform : translate(-50%, -50%);
                            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                            border-color: #fff transparent transparent transparent;
                            //outline : 1px solid yellow;
                        }
                        .lds-ring div:nth-child(1) {
                            animation-delay: -0.45s;
                        }
                        .lds-ring div:nth-child(2) {
                            animation-delay: -0.3s;
                        }
                        .lds-ring div:nth-child(3) {
                            animation-delay: -0.15s;
                        }
                        @keyframes lds-ring {
                            0% {
                                transform: rotate(0deg);
                            }
                            100% {
                            transform: rotate(360deg);
                            }
                        }
                    }

                    
                }
            }

            .language {
                margin-top : 50px;
                display : flex;
                justify-content: flex-start;
                align-items: center;
                text-align : left;

                span:last-child {
                    margin-left : 2px;
                    color: $main_color;
                    font-size: $label_font;
                    text-decoration : underline;
                }
            }
        }
    }
    .foot {
        position: relative;
    }*/
    
</style>