import Api from '@/services/Api'

export default {
    getUserSubscription(id) {
        return Api().get(`${process.env.VUE_APP_URL}/subscription/user/${id}`)
    }, 
    renewSubscription(payload) {
        return Api().post(`${process.env.VUE_APP_URL}/subscription/renew`, payload)
    },
    updateSubscription(payload) {
        return Api().put(`${process.env.VUE_APP_URL}/subscription/renew`, payload)
    }
}