
function editGlobalStyle(language = "EN") {
    let popOverCtn = document.querySelector(".wt-popover")
    let popOverTitle = document.querySelector(".wt-title")
    let popOverContent = document.querySelector(".wt-content")

    let btnBack = document.querySelector(".wt-btn-back")
    let btnNext = document.querySelector(".wt-btn-next")
    //popOverCtn.classList.add("")
    //console.log("stylingg...")
    popOverTitle.classList.add("hdl-sm")
    popOverTitle.classList.add("hdl-sm--sbold")
    popOverContent.classList.add("bd-md")

    if (language == "FR") {
        btnBack.textContent = "Précédent"
        btnNext.textContent = "Suivant"
    }

}

export default {
    initOnboard(stepsTarget, tourOption = {}) {
        const wt = new WebTour(tourOption);
        console.log(wt)
        const steps = stepsTarget;       
        wt.setSteps(steps);
        return wt;
    },
    startOnboard(tourInstance) {
        tourInstance.start()
    },
    editBtnStyle(color, element, classes, language) {
        editGlobalStyle(language)
        let elementSelect = document.querySelector(element)
        elementSelect.style.backgroundColor = color;
        elementSelect.classList.add(classes)
    },
    editStyle(element, classes, elapse = true) {
        if (elapse) {
            setTimeout(()=> {
                let elementSelect = document.querySelector(element)
                console.log(elementSelect)
                console.log(typeof(classes))
                if (typeof(classes) == "object") {
                    Object.keys(classes).forEach((key)=> {
                        elementSelect.style[key] = classes[key]
                    })
                } else {
                    elementSelect.classList.add(classes)
                }
                elementSelect.firstElementChild.style.display = "none"
            }, 1000)
        } else {

        }
    }
}