<template>
    <!--<div class="setting-head">
        <h3 class="hdl-md"> {{ langObj.setting.title }} </h3>
        <button class="menu-btn" @click="isResponsive = !isResponsive">
            <i class="fas fa-bars"></i>
        </button>
    </div>-->
    <div class="layout-default">
        <Transition name="mobile-trans">
            <!-- <settingNavBar class="sidebar" :class="{'sidebar-responsive' : isResponsive}" :lang="langObj.setting.sidebar" /> -->
            <sidebar />
        </Transition>
        <div class="settings layout-side">
            <navBar :activeLink="'none'" :isSection="true"> 
                <!-- <settingNavBar class="sidebar" :lang="langObj.setting.sidebar" /> -->
            </navBar>
            <router-view class="viewer" :link="link"/>
        </div>
    </div>
    <footerNav :lang="userLang"/>
</template>

<script>
import navBar from '@/components/navhead';
//import settingNavBar from '@/components/settingnavbar';
import sidebar from '@/components/sidebar';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import footerNav from '@/components/footer.vue';
export default {
    name : "Settings",
    setup() {
        const store = useStore();
        const user = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        const userLang = ref(user.value.lang);
        const langObj = ref(store.getters['getLang']).value[user.value.lang]
        console.log(langObj)
        return {
            langObj,
            userLang
        }
        
    },
    data () {
        return {
            link : 'account',
            isResponsive : false
        }
    },
    //props : ['link_now'],
    components : {
        navBar,
        sidebar,
        //settingNavBar,
        footerNav,

    },
    computed : {
        active () {
            return this.link_now !== "" ? true : false
        }
    },
    methods :{
        dis(){
            if (this.$route.href == '/settings/account') {
                this.link = "account"
            } else if (this.$route.href == '/settings/profile') {
                this.link = "profile"
            }
        }
    },
    mounted() {
        this.dis()
    }
    
}
</script>

<style lang="scss" scoped>
    
    /*
    .setting-head {
        display : flex;
        justify-content: space-between;
        align-items: center;
        padding : 5px 10px;
        h3 {
            margin: 0;
            padding: 0;
            height : 100%;
            display : flex;
            justify-content : center;
            align-items : center;
            padding-left: 30px;
            text-align: left;
            letter-spacing: 1px;
        }
        button {
            @extend %btn-base;
            display : none;
            width: 30px;
            height: 30px;
            margin : 0 5px;
            margin-right : 40px;
            background-color: transparent;
            color: $main_color;
            font-size: $font-icon;
            @media screen and (max-width : $desktop) {
                display: unset;
            }
            @media screen and (max-width : $mid-screen) {
                display: none;
            }
        }
    }
    .settings {
        @extend %layout-side;
        .sidebar {
            display: block;
            min-width: 200px;
            @media screen and (max-width : $desktop) {
                display: none;
                transform: translateX(-100%);
                transition : transform .5s ease-out;
                animation : trans-rev .5s ease-in 1;
            }
            .sidebar-responsive {
                position : absolute;
                display: unset;
                min-width : unset;
                top : 0;
                left : 0%;
                height : 100vh;
                z-index: 5;
                background: $white_text_color;
                transform: translateX(0%);
                transition : transform .5s ease-out;
                animation : trans .5s ease-out 1;
            }
        }

        @keyframes trans {
            from {
                transform: translateX(-100%);
            } to {
                transform: translateX(00%);

            }
        }
        @keyframes trans-rev {
            from {
                transform: translateX(0%);
            } to {
                transform: translateX(-100%);

            }
        }
        .sidebar-mobile {
            
            .style-list {
                height : 40px !important;
                padding : 0 20px;
                
                button { 
                    padding-left : 0px;
                }
            }
        }
        .viewer {
            @media screen and (max-width : $mobile) {
                width: 100%;
                //padding : 10px 15px;
            }
        }
        
    }*/
</style>