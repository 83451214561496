<template>
    <div class="nav">
        <header class="nav_header">
            <nav>
                <router-link :to="'/home'" v-show="isMobile">
                    <img v-bind:src="brandLogo" alt="Takafa mindset">
                </router-link>
                <div class="right-side">
                  <!--<ul>
                    <router-link :to="'/home'" class="link-router" :class="{ 'link-router--active': (activeLink == 'home' ? true : false) }">{{ langObj.nav.home }}</router-link>
                    <router-link :to="'/playlist/' + playlist" class="link-router" :class="{ 'link-router--active': (activeLink == 'playlist' ? true : false) }">{{ langObj.nav.playlist }}</router-link>
                    <router-link :to="'/history/watch-history'" class="link-router" :class="{ 'link-router--active': (activeLink == 'history' ? true : false) }">{{ langObj.nav.history }}</router-link>
                  </ul>-->
                  <div class="right-ul-side">
                    <form class="search-box" @submit.prevent="toSearch">
                      <input  v-model="searchText">
                      <button type="submit" class="hdl-sm"><i class="pi pi-search"></i></button>
                    </form>
                    <div class="profile-box">
                      <button class="hdl-sm" data-not="2">
                        <i class="pi pi-bell"></i>
                      </button>
                      <button @click="isProfileMenu = !isProfileMenu" class="hdl-sm" id="profile-menu-btn">
                          <img :src=" imgUrl + user.photoUrl" alt="profile-user" v-if="user.photoUrl != null">
                          <i class="pi pi-user" v-else></i>
                      </button>
                      <div class="profile-dropdown" v-show="isProfileMenu"> 
                        <div>
                          <div class="profile-image hdl-sm" >
                            <img :src=" imgUrl + user.photoUrl" alt="profile-user" v-if="user.photoUrl != null">
                            <i class="pi pi-user" v-else></i>
                          </div>
                          <p> 
                            <span> {{ user.email }} </span> 
                            
                            <span> {{user.firstname }} {{ user.lastname }}</span> 
                          </p>
                        </div>
                        <ul>
                          <!-- <router-link :to="'/booking'"> <i class="fa fa-calendar-week"></i>{{ langObj.nav.dropdown.book }}</router-link> -->
                          <a v-if="followUp[0]" :href="followUp[1]" target="_blank" id="follow-btn" class="bd-lg"><i class="pi pi-users" ></i>{{ langObj.nav.dropdown.follow }}</a>
                          <!--<a v-else ><i class="fa fa-binoculars"></i>{{ langObj.nav.dropdown.follow }}</a>-->
                          <!-- <router-link :to="'/settings/profile'"> <i class="fas fa-cog"></i> {{ langObj.nav.dropdown.setting }}</router-link> -->
                          <li @click="signOut" class="bd-lg"><i class="pi pi-sign-out"></i> {{ langObj.nav.dropdown.sign }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hamburg">
                  <button class="search-mobile" @click="mobileSearchShow = !mobileSearchShow"><i class="pi pi-search" v-if="!mobileSearchShow"></i> <i class="pi pi-times" v-else></i></button>
                  <Transition name="mobile-search">
                    <div class="search-mobile-container" v-show="mobileSearchShow"> 
                      <button @click="toSearch"><i class="fas fa-search"></i></button>
                      <input type="text" v-model="searchText">
                    </div>
                  </Transition>

                  <button class="menu-btn" @click="mobileSwitch">
                    <i class="pi pi-bars"></i>
                  </button>
                </div>
            </nav>
            <Transition name="mobile-trans">
              <div class="mobile-navbar" v-show="mobileShow">
                <div class="responsive-mobile">
                    <div class="profile-container">
                        <div class="profile-image">
                          <img :src=" imgUrl + user.photoUrl" alt="profile-user" v-if="user.photoUrl != null">
                          <i class="fas fa-user-circle" v-else></i>
                        </div>
                        <p> 
                          <span> {{ user.email }} </span> 
                          
                          <span> {{user.firstname }} {{ user.lastname }}</span> 
                        </p>
                    </div>
                    <ul>
                      <router-link :to="'/booking'"> <i class="fa fa-calendar-week"></i>{{ langObj.nav.dropdown.book }}</router-link>
                      <a v-if="followUp[0]" :href="followUp[1]" target="_blank"><i class="fa fa-binoculars"></i>{{ langObj.nav.dropdown.follow }}</a>
                      <router-link :to="'/settings/profile'"> <i class="fas fa-cog"></i> {{ langObj.nav.dropdown.setting }}</router-link>
                      <li @click="signOut"><i class="fas fa-sign-out-alt"></i> {{ langObj.nav.dropdown.sign }}</li>
                    </ul>
                    <hr/>
                    <ul>
                      <router-link :to="'/home'" :class="{ 'responsive-active-link': (activeLink == 'home' ? true : false) }"> <i class="fa fa-home"></i>{{ langObj.nav.home }}</router-link>
                      <router-link :to="'/playlist/' + playlist" class="link-router" :class="{ 'responsive-active-link': (activeLink == 'playlist' ? true : false) }"><i class="fa fa-binoculars"></i>{{ langObj.nav.playlist }}</router-link>
                      <router-link :to="'/history/watch-history'" class="link-router" :class="{ 'link-router--active': (activeLink == 'history' ? true : false) }"> <i class="fas fa-cog"></i> {{ langObj.nav.history }}</router-link>
                    </ul>
                    <hr v-show="isSection"/>
                    <div class="secondary-navigation">
                      <slot></slot>
                    </div>
                  </div>
              </div>
            </Transition>

        </header>
    </div>
</template>

<script>

import takafaLogo from './../assets/Images/Logo sans slogan.png';
//import profile from './../assets/Images/profile.svg';
//import playlistNavBar from '@/components/playlistnavbar';
import Api from '@/services/Api';
import { useRoute } from 'vue-router';
import { useStore} from 'vuex';
import { ref, computed, inject } from 'vue';
export default {
    name: "navbar",
    setup() {
        //console.log(localStorage.getItem("user"));
        const route = useRoute();
        const store = useStore();
        const isMobile = inject('isMobile')
        const imgUrl = store.getters['getImgUrl']; 
        console.log(imgUrl);
        let playlist = "Watch Later"
        //const user = ref({})
        if (store.getters['currentUser'] == (undefined || "" || null)) {
          route.push( {
            name : 'login'
          })
          return
        }
        const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
        const followUp = ref (computed(()=> store.getters['getUserFollowUp']))
        console.log(followUp)
        /*const update = ref(computed(()=> store.getters['getStateUpdate']));
        const token = ref(computed(()=> store.getters['getToken']))
        onBeforeMount(async ()=> {
          if (update.value) {
              let getMe = await store.dispatch('getCurrentUser', token, user.value.id );
              console.log(getMe);
              if(getMe.authorized) {
                //console.log(res.data)
                user.value = computed(()=> JSON.parse(store.getters['currentUser']))
                store.commit('SET_UPDATE', false)
              }
          } 

        })*/
        const langObj = ref(store.getters['getLang']).value[user.value.lang]
      
        console.log(user)
        if (user.value.lang == "FR") {
            playlist = "Regarder plus tard"
        }

      return {
        user,
        langObj,
        playlist,
        imgUrl,
        //program,
        //programFollow,
        isMobile,
        followUp
      }
    },
    props: {
        activeLink : String,
        isSection : {
          type : Boolean,
          default : false
        },
        thisSection : String

    },
    data() {
        return {
            brandLogo : takafaLogo,
            //avatar : profile,
            isProfileMenu : false,
            ActiveUser : null,
            searchText : '',
            mobileShow : false,
            mobileSearchShow : false
        }
    },
    computed : {
      //...mapGetters(['currentUser', 'getUrl' , 'isAuthenticated']),
      
    },
    components : {
      //playlistNavBar
    },
    methods : {
      async signOut() {
        await this.$store.dispatch('signOut').then((res)=> {
          console.log(res)
          if(res) {
            setTimeout(()=> {
              this.$router.push('/')
            }, 1000)
          }
        })
        .catch(err=> console.log(err))
        
      },
      toSearch() {
        Api().post(`${process.env.VUE_APP_URL}/history/add`, {type : "search", search : this.searchText , videoId : null, userId : this.user.id}).then(res => {
          console.log(res);

        })
        .catch (err => {
          console.log(err)
        })
        this.$router.push({
          name : 'Search',
          query : {
            'searchText' : this.searchText, 
          }
        })
        /*const searchLoad = {

        }*/
      },
      noFollowUp() {

      },
      mobileSwitch() {
        this.$store.commit('switchMobile')
        console.log("yes")
      }
    },
    created () {
      //console.log(this.currentUser);
      
    }

}
</script>

<style lang="scss" scoped>
  

</style>
