import Api from '@/services/Api'

export default {
    
    getSearchHistory(userId) {
        return Api().get(`${process.env.VUE_APP_URL}/history/search/${userId}`)
    },
    updateUserInfo(userId, data) {
        return Api().put(`${process.env.VUE_APP_URL}/users/updateInfo/${userId}`, data)
    },
    updateUserPassword(userId, data) {
        return Api().put(`${process.env.VUE_APP_URL}/users/updatePassword/${userId}`, data)
    },
    deleteById(userId) {
        return Api().delete(`${process.env.VUE_APP_URL}/booking/${userId}`)
    }
}