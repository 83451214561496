//import Vue from "vue";
import { createApp, } from 'vue'
import App from './App.vue'
import router from './router'
import { createStore } from 'vuex'
import AuthenticationService from '@/services/AuthenticationService';
import 'primeicons/primeicons.css';
import './assets/scss/main.scss';

//import * as a from './store/index.js'
//import VueCoreVideoPlayer from 'vue-core-video-player';
//import Embed from 'v-video-embed';

//createApp.use(VueCoreVideoPlayer);

const appLang = {
    EN : {
        login : {
            text1  : 'Log in to access transformational masterclasses ',
            text2 : 'Please enroll into a program to earn your access',
            input : {
                field1 : 'Email',
                field2 : 'Password',
                button : 'Login',
                forgot : 'Forgot Password?'
            },
            quote : {
                title : 'WHAT MAKES US UNIQUE',
                content : 'Leveraging education technology and smart science to provide the best content possible. Learn at your own pace and hone your soft skills for real.'
            },
            account : {
                text : 'Didn\'t have a account?',
                signup : 'Sign up here'
            }
        },
        forgot : {
            text1  : 'Forgot your password',
            text2 : 'Enter your email below to continue',
            input : {
                field : 'Email',
                button : 'Continue',
                error : 'Email invalide'
            },
            quote : {
                title : 'WHAT MAKES US UNIQUE',
                content : 'Leveraging education technology and smart science to provide the best content possible. Learn at your own pace and hone your soft skills for real.'
            },
            account : {
                text : 'Didn\'t have a account?',
                signup : 'Sign up here'
            }
        },
        reset : {
            text1  : 'Reset your password',
            text2 : 'Enter your new password below to continue',
            invalidLink : 'Invalid reset Link',
            input : {
                field1 : 'New Password',
                field2 : 'Confirm Password',
                button : 'Continue',
                error1 : 'Empty Password',
                error2 : 'Password not equal'
            },
            validReset : 'Password Changed successfully',
            quote : {
                title : 'WHAT MAKES US UNIQUE',
                content : 'Leveraging education technology and smart science to provide the best content possible. Learn at your own pace and hone your soft skills for real.'
            },
            account : {
                text : 'Didn\'t have a account?',
                signup : 'Sign up here'
            }
        },
        verify : {
            text1  : 'Email Confirmation',
            text2 : 'Enter your new password below to continue',
            invalidLink : 'Invalid Link',
            load : 'Loading...',
            verified : 'Your email have been verified',
            //validReset : 'Password Changed successfully',
            quote : {
                title : 'WHAT MAKES US UNIQUE',
                content : 'Leveraging education technology and smart science to provide the best content possible. Learn at your own pace and hone your soft skills for real.'
            },
            account : {
                text : 'Didn\'t have a account?',
                signup : 'Sign up here'
            }
        },
        nav : {
            home : 'Home',
            playlist : 'Playlists',
            history : 'History',
            dropdown : {
                book : 'Book a debrief session',
                follow : 'Launch Follow up',
                setting : 'Setting',
                sign : 'Sign Out'
            },
            sidebar : {
                home : 'Home',
                playlist : 'Playlists',
                booking : 'Booking',
                history : 'History',
                account : 'Account',
                setting : 'Setting',
                tour : 'Take a tour'
            }
        },
        home : {
            banner : {
                title : 'Takafa Academy',
                description : 'Reach your full potential, become a full fledge educated being, and transform your well-being, career, and relationships with these cutting-edge videos and masterclasses',
            },
            video : {
                unaivalable : 'Unavailable in this plan'
            },
            error : {
                load : 'Something went wrong. Please try reloading the page'
            }
        },
        videoDetail : {
            button : {
                addPlaylist : 'Add to Playlist',
                like : 'Like'
            },
            playlist : {
                title : 'Watch Later',
                viewVideo : 'Watch now',
                empty : 'No videos added to this playlist',
                add : 'Add to'
            },
            info : {
                host : 'Host'
            },
            comments : {
                title : 'All comments',
                comment : 'Send'
            },
            related : {
                title : 'Related',
                play : 'Play now'
            },
            unaivalable : {
                title : 'This video is unavailable in this plan',
                back : 'Return to home'
            }
        },
        playlist : {
            title : 'Playlist',
            unaivalable : 'No videos added to this playlist',
            create : {
                title : 'Create',
                createTitle : 'Create a playlist',
                playname : 'Playlist name',
                submit : 'Create'
            },
            created : 'Playlist created'
        },
        history : {
            title : 'History',
            sidebar : {
                watch  : 'Watch History',
                search : 'Search History',
                like : 'Liked Videos'
            },
            watch  : {
                empty : 'You haven\'t watch any video yet'
            },
            search : {
                empty : 'No recent search'
            },
            like : {
                empty : 'No videos liked'
            }
        },
        setting : {
            title : 'Setting',
            sidebar : {
                account : 'Account',
                profile : 'Profile'
            },
            button : {
                save : 'Save',
                cancel : 'Cancel'
            },
            profile : {
                title : 'Settings - Profile',
                info : {
                    title : 'Profile Information',
                    firstname : 'Firstname',
                    lastname : 'Lastname',
                    error : 'Invalid input',
                    placeholder : {
                        firstname : 'firstname',
                        lastname : 'lastname'
                    },
                    image : {
                        error1 : 'Only image files are allowed',
                        error2 : 'Maximum size should be 2MB'
                    }
                },
                email : {
                    title : 'Email',
                    email : 'Email',
                    error : 'Invalid Input',
                    placeholder : {
                        email : 'email'
                    }
                },
                password : {
                    title : 'Password',
                    password : 'Password',
                    currentPass : 'Current password',
                    newPass : 'New password',
                    confirmPass : 'Confirm Password',
                    error : 'Invalid input',
                    error2 : 'Password not match'
                },
                alertBox : {
                    profile : 'Profile Saved',
                    password : 'Password Changed',
                    image : 'Profile picture saved'
                }
            },
            account : {
                //title : 'Enrollment Details',
                title : 'Account',
                program : {
                    title : 'Program',
                    current : 'Current program',
                    enroll : 'Enrolled on',
                    expire : 'Expire on'
                },
                plan : {
                    title : 'Subscription',
                    current : 'Current plan',
                    renew : 'Renew my subscription'
                }
            }
        },
        booking : {
            title : 'Book a debrief session',
            unaivalable : 'Debrief sessions are unvailable in your plan',
            limit : 'You\'ve reached your booking limit'
        },
        payment : {
            title : 'Choose your plan',
            description : 'Choose a suitable plan to renew your subscription',
            plan : {
                text : (plan)=> {
                    return plan
                },
                currency  : 'In your currency',
                checkout : 'Get this plan'
            },
            process : {
                default : 'Processing',
                fail : 'Payment Imcomplete',
                complete : 'Payment Complete'
            }
        },
        footer : {
            rights : 'All rights reserved',
            follow : 'Follow us',
            takafa : {
                title : 'Takafa Mindset',
                list : [
                    'About us',
                    'Terms & Conditions',
                    'Privacy Policy'
                ]
            },
            services : {
                title : 'Services',
                list : [
                    'Academy',
                    'Events',
                    'Consulting'
                ]
            },
            support : {
                title : 'Support',
                list : [
                    'Help Center',
                    'Email us',
                    //3 : 'Consulting'
                ]
            }
        },
        onboard : {
            steps : [
                {
                    element: '#onStart',
                    title:  `Hello  `,
                    content: 'Welcome to Takafa Academy. Let\'s take you through a wonderful and quick tour to get you started.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#home-btn',
                    title: 'Home',
                    content: 'Access here the series of masterclasses available.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#playlist-btn',
                    title: 'Playlists',
                    content: 'Here you can create and access your various playlists.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#booking-btn',
                    title: 'Booking of debrief sessions ',
                    content: 'Here you can book the debrief sessions of your coaching package in order to have a conversation with a certified practitioner. Please book your sessions at the end of this tour if you\'ve just started the program.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#history-btn',
                    title: 'History',
                    content: 'Here you can view both your watch and search history as well as the videos you liked.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#account-btn',
                    title: 'Account',
                    content: 'Here you can view the details of the program you are in as well as your subscription plan. You can also renew your subscription plan.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#profile-btn',
                    title: 'Settings',
                    content: 'Here you can edit your profile, change your email and password.',
                    placement: 'right-start',
                    
                },
            ]
        }
    },
    FR : {
        login : {
            text1  : 'Connectez-vous pour accéder à des masterclasses de transformation',
            text2 : 'Inscrivez-vous à un programme pour obtenir votre accès',
            input : {
                field1 : 'Email',
                field2 : 'Mot de passe',
                button : 'Connexion',
                forgot : 'Mot de passe oublié'
            },
            quote : {
                title : 'CE QUI NOUS REND UNIQUE',
                content : 'Nous exploitons la technologie éducative et la science intelligente pour fournir les meilleurs contenus possibles. Apprenez à votre propre rythme et affinez vos soft skills pour de vrai. '
            },
            account : {
                text : 'Vous n\'avez pas de compte ?',
                signup : 'Inscrivez-vous ici'
            }
        },
        forgot : {
            text1  : 'Mot de passe oublié',
            text2 : 'Veuillez entrer votre email pour continuer',
            input : {
                field : 'Email',
                button : 'Continuer',
                error : 'Email invalide'
            },
            quote : {
                title : 'CE QUI NOUS REND UNIQUE',
                content : 'Nous exploitons la technologie éducative et la science intelligente pour fournir les meilleurs contenus possibles. Apprenez à votre propre rythme et affinez vos soft skills pour de vrai. '
            },
            account : {
                text : 'Vous n\'avez pas de compte ?',
                signup : 'Inscrivez-vous ici'
            }
        },
        reset : {
            text1  : 'Rénitialiser votre mot de passe',
            text2 : 'Entrez votre nouveau mot de passe pour continuer',
            invalidLink : 'Lien invalide',
            input : {
                field1 : 'Nouveau mot de passe',
                field2 : 'Confirmer le mot de passe',
                button : 'Continuer',
                error1 : 'Mot de passe invalid',
                error2 : 'Mot de passe non conforme'
            },
            validReset : 'Le mot de passe a été changé avec succès',
            quote : {
                title : 'CE QUI NOUS REND UNIQUE',
                content : 'Nous exploitons la technologie éducative et la science intelligente pour fournir les meilleurs contenus possibles. Apprenez à votre propre rythme et affinez vos soft skills pour de vrai.'
            },
            account : {
                text : 'Vous n\'avez pas de compte ?',
                signup : 'Inscrivez-vous ici'
            }
        },
        verify : {
            text1  : 'Confirmation d\'email',
            text2 : 'Enter your new password below to continue',
            invalidLink : 'Lien invalide',
            load : 'Chargement...',
            verified : 'Votre email a été vérifié avec succès',
            //validReset : 'Password Changed successfully',
            quote : {
                title : 'CE QUI NOUS REND UNIQUE',
                content : 'Nous exploitons la technologie éducative et la science intelligente pour fournir les meilleurs contenus possibles. Apprenez à votre propre rythme et affinez vos soft skills pour de vrai.'
            },
            account : {
                text : 'Vous n\'avez pas de compte ?',
                signup : 'Inscrivez-vous ici'
            }
        },
        nav : {
            home : 'Accueil',
            playlist : 'Playlist',
            history : 'Historique',
            dropdown : {
                book : 'Réserver une séance de debrief',
                follow : 'Lancer le suivi',
                setting : 'Paramètres',
                sign : 'Déconnexion'
            },
            sidebar : {
                home : 'Accueil',
                playlist : 'Playlists',
                booking : 'Rendez-vous',
                history : 'Historique',
                account : 'Compte',
                setting : 'Paramètres',
                tour : 'Visite guidée'
            }
        },
        home : {
            banner : {
                title : 'Académie Takafa',
                description : 'Réalisez votre plein potentiel, devenez un être éduqué à part entière et transformez votre bien-être, votre carrière et vos relations grâce à ces vidéos et masterclasses de pointe.'
            },
            video : {
                unaivalable : 'Indisponible dans cette formule'
            },
            error : {
                load : 'Un problème s\'est produit. Veuillez essayer de recharger la page'
            }
        },
        videoDetail : {
            button : {
                addPlaylist : 'Ajouter a une liste de lecture',
                like : 'J\'aime'
            },
            playlist : {
                title : 'Regarder plus tard',
                viewVideo : 'Regarder',
                empty : 'Aucune vidéo ajouté à cette playlist',
                add : 'Ajouter à'
            },
            info : {
                host : 'Formateur'
            },
            comments : {
                title : 'Tous les commentaires',
                comment : 'Envoyer'
            },
            related : {
                title : 'Vidéos Similaires',
                play : 'Lire'
            },
            unaivalable : {
                title : 'Vidéo non disponible dans cette formule',
                back : 'Revenir à l\'accueil'
            }
        },
        playlist : {
            title : 'Playlists',
            unaivalable : 'Aucune vidéo ajouté à cette playlist',
            create : {
                title : 'Créer',
                createTitle : 'Créer une playlist',
                playname : 'Nom de la playlist',
                submit : 'Créer'
            },
            created : 'Playlist créée'
        },
        history : {
            title : 'Historique',
            sidebar : {
                watch  : 'Historique de Lecture',
                search : 'Historique de recherche',
                like : 'Videos Aimées'
            },
            watch  : {
                empty : 'Aucune vidéo regardé'
            },
            search : {
                empty : 'Aucune recherche récente'
            },
            like : {
                empty : 'Aucune vidéo aimé'
            }
        },
        setting : {
            title : 'Paramètres',
            sidebar : {
                account : 'Compte',
                profile : 'Profile'
            },
            button : {
                save : 'Enregistrer',
                cancel : 'Annuler'
            },
            profile : {
                title : 'Paramètres - Profile',
                info : {
                    title : 'Information du profil',
                    firstname : 'Prénoms',
                    lastname : 'Nom',
                    error : 'Champ invalide',
                    placeholder : {
                        firstname : 'prénoms',
                        lastname : 'nom'
                    },
                    image : {
                        error1 : 'Seulement les images sont autorisées',
                        error2 : 'Taille maximale de 2MB'
                    }
                },
                email : {
                    title : 'Email',
                    email : 'Email',
                    error : 'Champ invalide',
                    placeholder : {
                        email : 'email'
                    }
                },
                password : {
                    title : 'Sécurité',
                    password : 'Mot de passe',
                    currentPass : 'Mot de passe actuel',
                    newPass : 'Nouveau mot de passe',
                    confirmPass : 'Confirmer mot de passe',
                    error : 'Champ invalide',
                    error2 : 'Mot de passe non conforme'
                },
                alertBox : {
                    profile : 'Profile enregistré',
                    password : 'Mot de passe changé',
                    image : 'Photo de profil enregistré'
                }
            },
            account : {
                title : 'Détails de l\'inscription',
                program : {
                    title : 'Programme',
                    current : 'Program actuel',
                    enroll : 'Inscrit le',
                    expire : 'Expire le'
                },
                plan : {
                    title : 'Abonnement',
                    current : 'Formule actuelle',
                    renew : 'Renouveller mon abonnement'
                }
            }
        },
        booking : {
            title : 'Réserver votre séance de debrief',
            unaivalable : 'Les séances de debrief ne sont pas disponibles dans votre formule',
            limit : 'Vous avez atteint votre limite de réservation'
        },
        payment : {
            title : 'Choisissez votre formule',
            description : 'Choisissez un plan adapté pour renouveler votre abonnement',
            plan : {
                text : (plan)=> {
                    if (plan == "1 Month") return "1 Mois"
                    else if (plan == "3 Months") return "3 Mois"
                    else if (plan == "1 Year") return "1 An"
                },
                currency  : 'Dans votre devise',
                checkout : 'Obtenir cette formule'
            },
            process : {
                default : 'Traitement',
                fail : 'Paiement non réussi',
                complete : 'Paiement terminé'
            }
        },
        footer : {
            rights : 'Tous droits réservés',
            follow : 'Suivez nous',
            takafa : {
                title : 'Takafa Mindset',
                list : [
                    'A propos de nous',
                    'Termes & Conditions',
                    'Politique de Confidentialité'
                ]
            },
            services : {
                title : 'Services',
                list : [
                    'Academie',
                    'Evènements',
                    'Consultation'
                ]
            },
            support : {
                title : 'Support',
                list : [
                    'Centre d\'aide',
                    'Envoyer un mail',
                    //'Consulting'
                ]
            }
        },
        onboard : {
            steps : [
                {
                    element: '#onStart',
                    title:  `Bonjour  `,
                    content: 'Bienvenue à l\'Académie Takafa. Nous allons vous faire faire une visite rapide et merveilleuse pour vous aider à démarrer.',
                    //placement: 'middle-center',
                    
                },
                {
                    element: '#home-btn',
                    title: 'Acceuil',
                    content: 'Accédez ici aux séries de masterclasses disponibles.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#playlist-btn',
                    title: 'Playlists',
                    content: 'Vous pouvez y créer et accéder à vos différentes listes de lecture.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#booking-btn',
                    title: 'Réservation de séances de débriefing',
                    content: 'Ici, vous pouvez réserver les sessions de débriefing de votre package de coaching afin d\'avoir une conversation avec un praticien certifié. Veuillez réserver vos séances à la fin de cette visite si vous venez de débuter le programme.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#history-btn',
                    title: 'Historique',
                    content: 'Vous pouvez y consulter votre historique de visionnage et de recherche, ainsi que les vidéos que vous avez aimées.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#account-btn',
                    title: 'Compte',
                    content: 'Vous pouvez y consulter les détails du programme auquel vous participez ainsi que votre plan d\'abonnement. Vous pouvez également renouveler votre abonnement.',
                    placement: 'right-start',
                    
                },
                {
                    element: '#profile-btn',
                    title: 'Paramètres',
                    content: 'Ici, vous pouvez modifier votre profil, changer votre adresse électronique et votre mot de passe.',
                    placement: 'right-start',
                    
                },
            ]
        }
    }
};
const store  = createStore ({
    state : {
        
        lang : appLang,
        mobileShow : false
    },
    getters : {
        getLang:(state) => {
            return state.lang
        },
        getMobileShow : (state) => {
            return state.mobileShow
        }
    },
    mutations : {
        switchMobile(state) {
            state.mobileShow = !state.mobileShow
        }
    },
    modules : {
        
        auth : {
            state : {
                count : 1,
                url : process.env.VUE_APP_URL,
                token: sessionStorage.getItem('token') || null,
                user: sessionStorage.getItem('user') || null,
                isUserLoggedIn: sessionStorage.getItem('token') || false,
                stateUpdate : false,
                imgUrl : process.env.VUE_APP_IMG_URL || '',
                followUp : [false, '']
            
            },
            getters : {
                getUrl:(state) => {
                    return state.url
                },
                getImgUrl:(state) => {
                    return state.imgUrl
                },
                isAuthenticated:(state) => {
                    return state.isUserLoggedIn
                },
                getToken:(state) => {
                    return state.token
                },
                currentUser:(state) => {
                    return state.user
                },
                getCount : (state) => {
                    return state.count
                },
                getStateUpdate : (state) => {
                    return state.stateUpdate
                },
                getUserFollowUp : (state) => {
                    return state.followUp
                }
            },
            mutations : {
                SET_TOKEN(state, token) {
                    state.token = token
                    if (token) {
                        state.isUserLoggedIn = true
                    } else {
                        state.isUserLoggedIn = false
                    }
                },
                SET_USER_DATA(state, data) {
                    state.user = data
                },
                CLEAR_AUTH(state) {
                    state.token = null
                    state.user = null
                    state.isUserLoggedIn = false
                    sessionStorage.clear()
                },
                SET_UPDATE(state, toState) {
                    state.stateUpdate = toState
                },
                INCREMENT(state) {
                    state.count++;
                },
                SET_USER_FOLLOW_UP (state) {
                    const follow = {
                        sep : 'https://t.me/SE_Program_Bot?start=eyJjIjoiNjIwZjkxMzUwMzgxZTA3Y2I1NDVjZDQ1In0',
                        ccpd : 'https://t.me/CCD_Program_Bot?start=eyJjIjoiNjIxMGU4Y2NjODJiMzIzN2UxMGY2YTdiIn0'
                    }
                    console.log(state.user)
                    //const store = useStore()
                    //const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
                    const user =  JSON.parse(store.getters['currentUser']);
                    console.log(user)
                    if (!user) return
                    const programFollow = user.program_status == 3 ? false : true; 
                    const program = user.program_status == 1 ? follow.sep : user.program_status == 2 ? follow.ccpd : '';
                    state.followUp = [programFollow, program]
                    console.log(state.followUp)
                }
            },
            actions : {
                signIn({ commit }, credentials) {
                    return new Promise((resolve, reject) => {
                        AuthenticationService.signIn(credentials)
                        .then(({ data }) => {
                            console.log(data);
                            if (data.error) {
                                resolve(data);
                                return;
                            }
                            sessionStorage.setItem('token', data.token)
                            commit('SET_TOKEN', data.token)
                            sessionStorage.setItem('user', JSON.stringify(data.data))
                            commit('SET_USER_DATA', JSON.stringify(data.data))
                            //commit('SET_USER_DATA', data.data)
                            store.commit('SET_USER_FOLLOW_UP')

                            resolve(data)
                        })
                        .catch((err) => reject(err))
                    })
                },
                getCurrentUser({ commit }, credentials) {
                    return new Promise((resolve, reject) => {
                        AuthenticationService.me(credentials)
                        .then(({ data }) => {
                            console.log(data);
                            if (data.authorized) {
                                sessionStorage.setItem('user', JSON.stringify(data.data))
                                
                                commit('SET_USER_DATA', JSON.stringify(data.data))
                                //commit('SET_USER_DATA', data.data)
    
                                resolve(data)
                            } else {
                                reject(data)
                            }
                        })
                        .catch((err) => reject(err))
                    })
                },
                signOut({ commit }) {
                    return new Promise((resolve, reject) => {
                        let user = JSON.parse(sessionStorage.getItem('user'))
                        if (user) {
                            AuthenticationService.signout(user)
                            .then((res)=> {
                                console.log(res)
                                if (res.data.success) {
                                    sessionStorage.removeItem('token')
                                    sessionStorage.removeItem('user')
                                    commit('CLEAR_AUTH')
                                    resolve(true);
                                }
                            })
                            .catch((err)=> {
                                //console.log(err)
                                reject(err)
                            })
                        }

                    })
                },
                increment({commit}) {
                    commit('INCREMENT')
                }
            }
        } 
    }
});
const app = createApp (App);
app.use(router).use(store);
app.mount('#app');
//console.log(app);
//console.log(store);
//createApp(App).use(router, store).mount('#app');
/*const app = createApp({

})*/
//Vue.use(VueCoreVideoPlayer);