<template>
    <div class="layout-default">
        <sidebar>
            <historyNavBar class="history-sidebar"  :activeSection="activeRoute" :lang="langObj.history.sidebar"/>
        </sidebar>
        <div class="layout-side">
            <navBar :activeLink="'history'" :isSection="true">
            </navBar>
            <router-view class="history-view viewer" />
            <!--<div class="history-container">
                <Transition name="mobile-trans">
                    <historyNavBar class="sidebar"  :activeSection="activeRoute" :lang="langObj.history.sidebar" :class="{'sidebar-responsive' : isResponsive}"/>
                </Transition>
            </div>-->
        </div>
    </div>
    <!-- <div class="history-head">
        <h3> {{ langObj.history.title }} </h3>
        <button class="menu-btn" @click="isResponsive = !isResponsive">
            <i class="fas fa-bars"></i>
        </button>
    </div> -->
    <footerNav :lang="langue"/>
</template>

<script>
import navBar from './../components/navhead';
import sidebar from '@/components/sidebar';
import historyNavBar from './../components/historynavbar';
import { ref , computed } from 'vue'
import {useStore } from 'vuex';
import { useRoute } from 'vue-router';
import footerNav from '@/components/footer.vue';

export default {
    name : "History",
    setup () {
        const route = useRoute();
        const activeRoute = ref("");
        const store = useStore();
        const user = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        const langue = ref(user.value.lang);
        const langObj = ref(store.getters['getLang']).value[user.value.lang] 
        console.log(route);
        activeRoute.value = route.name
        return {
            activeRoute,
            langObj,
            langue
        }
    },
    data () {
        return {
            isResponsive  : false
        }
    },
    components : {
        navBar,
        sidebar,
        historyNavBar,
        footerNav
    }
}
</script>

<style lang="scss" scoped>
    /*@import '../assets/styles/_variables.scss';
    @import '../assets/styles/_globals.scss';
    .history-head {
        display : flex;
        justify-content: space-between;
        align-items: center;
        padding : 5px 10px;
        h3 {
            margin: 0;
            padding: 0;
            height : 100%;
            display : flex;
            justify-content : center;
            align-items : center;
            padding-left: 30px;
            text-align: left;
            letter-spacing: 1px;
        }
        button {
            @extend %btn-base;
            display : none;
            width: 30px;
            height: 30px;
            margin : 0 5px;
            margin-right : 40px;
            background-color: transparent;
            color: $main_color;
            font-size: $font-icon;
            @media screen and (max-width : $desktop) {
                display: unset;
            }
            @media screen and (max-width : $mid-screen) {
                display: none;
            }
        }
    }
    .history-container {
        @extend %layout-side;
        min-height: 100vh;
        width: 100%;
        .sidebar {
            display: block;
            min-width: 200px;
            @media screen and (max-width : $desktop) {
                display: none;
                transform: translateX(-100%);
                transition : transform .5s ease-out;
                animation : trans-rev .5s ease-in 1;
            }
        }
        .sidebar-responsive {
            position : absolute;
            display: unset;
            min-width : unset;
            top : 0;
            left : 0%;
            height : 100vh;
            z-index: 5;
            background: $white_text_color;
            transform: translateX(0%);
            transition : transform .5s ease-out;
            animation : trans .5s ease-out 1;
        }
        .viewer {
            width : 100%;
        }
        @keyframes trans {
            from {
                transform: translateX(-100%);
            } to {
                transform: translateX(00%);

            }
        }
        @keyframes trans-rev {
            from {
                transform: translateX(0%);
            } to {
                transform: translateX(-100%);

            }
        }
    }*/

</style>