import axios from 'axios';

export default() => {
    const axiosInstance = axios.create({ 
        baseURL : `${process.env.VUE_APP_URL}`
    })

    const token = sessionStorage.getItem('token')
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status === 401) {
                sessionStorage.removeItem('token')
                sessionStorage.removeItem('user')
                location.reload()
            }
            return Promise.reject(error)
        }
    )

    return axiosInstance
}