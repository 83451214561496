<template>
    <article>
        <ul>
            <router-link :to="'/history/watch-history'" :class="{'active' : ('Watch-History' == activeSection ? true : false)}"> 
                <!-- <i class="fa fa-clock"> </i> -->
                {{ lang.watch }}
            </router-link>
            <router-link :to="'/history/search-history'" :class="{'active' : ('SearchHistory' == activeSection ? true : false)}">
                {{ lang.search }}
            </router-link>
            <router-link :to="'/history/liked-video'" :class="{'active' : ('LikedVideo' == activeSection ? true : false)}">
                {{ lang.like }}
            </router-link>
        </ul>
    </article>
    

</template>

<script>

export default {
    name : 'historynavbar',
    //emits : ['createPlaylist'],
    data ()  {
        return {
            isHistory : false,
            isSearch : false,
        }
    },
    props : {
        activeSection : String,
        //playlists : Array
        lang : Object
    },
    computed : {
        
    },
    components  : {
        
    },
    methods : {
        activeLink(link) {
            this.isHistory = false
            this.isSearch = false
            if (link == "account") {
                this.isHistory = true
                return [this.isHistory, this.isSearch]
            } else if(link == "profile") {
                this.isSearch = true
                return [this.isHistory, this.isSearch]
                
            }
        },
        
    },
    mounted() {
        //this.activeLink("account")[0]
    }
}
</script>

<style lang="scss" scoped>
    /*@import '../assets/styles/_variables.scss';
    @import '../assets/styles/_globals.scss';

    article {
        @extend %side-list-style;

        ul {
            & > * {

                button {
                    @extend %btn-base;
                    height: fit-content;
                    width: fit-content;
                    padding: 5px;
                    //font-size: $font-medium;
                    background-color: $white_text_color;
                    color: $main_color;
                    margin-right: 10px;
                    & i {
                        margin-right: 0;
                    }
                    &:hover {
                        background-color: $hover_white_back;
                    }
                }
                &:hover {
                    background-color: $white_text_color;
                    color : $main_color;
                    & i {
                        margin-left: 0px;
                        color : $main_color;
                    }
                }
            }
        }

    }*/
    
</style>