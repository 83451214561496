<template>
    <div class="sup-container">
        <div class="illustration">
            <div class="logo-ctn">
                    <img :src="brandLogo">
            </div>
            <div class="true-illustraion">
                <img :src="svgLogo"/>
                <p class="bd-lg bd-lg--sbold">
                    {{ langue.verify.quote.content }}
                </p>
            </div>
            <!-- <div class="overlay"></div>
            <blockquote>
                <h4> {{ langue.verify.quote.title }} </h4>
                <cite> &ldquo; {{ langue.verify.quote.content }} &rdquo;</cite>
            </blockquote> -->
        </div>
        <div class="container">
            <div class="sub-header bd-sm bd-sm--sbold">
                <span>{{ langue.verify.account.text }}</span> <a href="https://takafamindset.com" target="_blank">{{ langue.verify.account.signup }}</a>
            </div>
            <header>
                <!-- <div class="logo-container">
                    <img :src="brandLogo">
                </div> -->
                <h3 class="hdl-md hdl-md--sbold"> {{ langue.verify.text1 }} </h3>
                <!-- <p> Enter your email below to continue </p> -->
            </header>
            <!-- <hr/> -->
            <!--<form class="form-container" @submit.prevent="forgot">
                <div class="input-instance">
                    <label>{{ langue.EN.login.input.field1 }}</label>
                    <input type="email" name="email" v-model="User.email">
                    <div class="error">
                        <span :class="{ 'error--active': error.state }"> {{ error.name }} </span>
                    </div>
                    
                </div>
                
                <button class="log-in" type="submit" :disabled="loading">
                    <span v-show="!loading">Continue <i class="fa fa-arrow-right"></i></span>
                    <div v-show="loading" class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </button>
            </form>-->
            <div class="verify-container">
                <p v-if="isVerify">
                    {{ message }}
                </p>
                <p v-else-if="isVerify == null" class="loader">
                    {{ langue.verify.load }}
                </p>
                <p class="invalid" v-else>
                    {{ langue.verify.invalidLink }}
                </p>
            </div>
            <!--<div class="language">  
                <span>Change Language </span> : <span>Français</span>
            </div>-->
        </div>
        <Transition name="success-email">
            <div class="alert-success" v-show="isResetSuccess">
                {{ resetMessage }}
            </div>
        </Transition>
        <!-- <button @click="isResetSuccess = !isResetSuccess">reset</button> -->
    </div>
    <footerNav :lang="currentLang"/>
</template> 

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue';
//import navBar from '@/components/navBar.vue';
//import * as Vue from 'vue' // in Vue 3
import takafaLogo from './../../assets/Images/Logo sans slogan.png';
import takafaLogin from './../../assets/Images/login-academy.svg';

//import VueAxios from 'vue-axios'
import { useStore,} from 'vuex';
import { useRoute } from 'vue-router';
import AuthenticationService from '@/services/AuthenticationService.js';
import { onBeforeMount, ref } from 'vue';
//import { computed } from 'vue';
import footerNav from '@/components/footer.vue';

export default {
    //inject : ['store'],
    name: 'Verify',
    setup() {
        const store = useStore();
        const route = useRoute();
        const isVerify = ref(null);
        const langue = ref(null)
        const lang = store.getters['getLang'];
        const currentLang = ref(null)
        if (route.params.lang == "fr") {
            langue.value = lang.FR;
            currentLang.value = "FR"
        } else {
            langue.value = lang.EN
            currentLang.value = "EN"
        }
        const link = route.query.link;
        const message = ref("")
        //console.log(route, link, isVerify);
        onBeforeMount(async () => {
            if (link == "") {
                return
            }
            await AuthenticationService.verifyMail(link)
            .then( res => {
                console.log(res)
                if (res.data.success) {
                    isVerify.value = true
                    message.value = res.data.message[currentLang];
                } else {
                    isVerify.value = false
                }
            })
            .catch( err =>{
                console.error(err)
            })

        })
        return {
            langue,
            currentLang,
            isVerify,
            message
        }
    },
    data () {
        return {
            User : {
                email : null,
                
            },
            passShow : false,
            error : {
                name : "",
                pass : "",
                state : false
            },
            brandLogo : takafaLogo,
            svgLogo : takafaLogin,
            loading : false,
            resetMessage : this.langue.verify.verified,
            isResetSuccess : false
        }
    },
    computed: {
        
    },
    components : {
        footerNav
    },
    methods: {
        
        
        
    }

}
</script>

<style lang="scss" scoped>
    /*
    @import './../../assets/styles/_variables.scss';
    @import './../../assets/styles/_globals.scss';

    

    .sup-container {
        position: relative;
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        //outline: 2px solid black;
        box-sizing: border-box;
        font-size : $base-font;
        .illustration {
            width: 37%;
            min-height: 100%;
            background: url("https://admin.takafamindset.com/storage/Canva - African American Woman.jpg");
            background-size: cover;
            background-position: 50% 0%;
            position: relative;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $linear_overlay;
                //@include overlayBack(0.4)
            }
            blockquote {
                position: absolute;
                bottom: 150px;
                left: 40%;
                width: 70%;
                transform: translateX(-50%);
                z-index : 4;
                color : $white_text_color;
                font-size: $label-font;
                //outline : 1px solid blue;

                cite {
                    //text-align: justify;
                    width: 100%;
                    text-align: left;
                    line-height: 1.3;
                    //margin: 0;
                }

                h4 {
                    color: $main_color;
                    font-weight: bolder;
                    letter-spacing : 1px;
                    width: 100%;
                    text-align: center;
                }
            }

            @media screen and (max-width:$tablets) {
                //font-size: $fbase;
                display : none;
            }
        }

        .container {
            width: 70%;
            //min-height: 70vh;
            box-sizing: border-box;
            padding: 50px;
            padding-left: 7%;
            //display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            line-height : 1.2;
            @media screen and (max-width:$tablets) {

                width : 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-left : 50px;
                align-items : center;
            }
            header {
                //width: 100%;
                .logo-container {
                    width: 220px;
                    padding: 0;
                    margin-left: -8px;
                    //outline: 2px solid black;
                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                    }
                    @media screen and (max-width:$tablets) {
                        
                        height : 60px;

                    }
                }
                h3 {
                    padding: 0;
                    margin: 0;
                    margin-top: 10px;
                    font-size : $font-x-medium;
                    text-align: left;
                    letter-spacing: .7px;
                    font-weight: bolder;
                    @media screen and (max-width:$tablets) {
                        text-align : center;
                    }
                }
                p {
                    padding: 0;
                    text-align: left;
                    color: $text_color;
                    text-shadow: 0px 1px 1px $shadow_text_color;
                    font-weight: lighter;
                    @media screen and (max-width:$tablets) {
                        text-align : center;
                    }
                }
                @media screen and (max-width:$tablets) {
                    display: flex;
                    flex-direction : column;
                    justify-content: space-between;
                    align-items : center;
                }
            }
            hr {
                width: 80%;
                margin-left: 0;
                @media screen and (max-width:$tablets) {
                    text-align: center;
                    //display : none;
                    width : 100%;
                    //margin-left : 10%;
                }
            }
            .form-container {
                box-sizing: border-box;
                width: 50%;
                margin-top: 10px;
                padding: 30px 0px;
                //outline: 1px solid black;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                @media screen and (max-width:$tablets) {
                    width : 60%;
                    margin-top : -10px;
                }
                @media screen and (max-width:$mobile) {
                    width : 100%;
                    margin-top : -10px;
                }
                .input-instance {
                    position: relative;
                    //height: 40px;
                    width: 100%;
                    box-sizing: border-box;
                    margin: 10px 0;
                    label  {
                        display: block;
                        text-align: left;
                        margin-bottom: 10px;
                        color: $text_color;
                        font-weight: bold;
                        font-size: $label_font;
                    }
                    input {
                        @extend %input-base;
                        height : 40px;
                        width: 100%;
                        margin-bottom: 7px;

                        &:focus + .eye-show {
                            color: $main_color;
                        }
                        
                        
                    }
                    .input-pass {
                        padding-right : 32px
                    }
                    .eye-show {
                        position : absolute;
                        top: 50%;
                        right: 10px;
                        color: $input_border_color;
                        cursor : pointer;
                    }
                    .error {
                        text-align: left;
                        position: relative;
                        margin-left: 0;
                        left: 0;
                        //height: 10px;
                        //outline: 1px solid black;
                        span {
                            color: $form_error;
                            visibility: none;
                        }
                        &--active {
                            visibility: visible;
                        }
                        .forgot-pass {
                            position: absolute;
                            right: 0;
                            font-weight: bold;
                            color: $main_color;
                            font-size: $label_font;
                        }
                    }
                }
                .log-in {
                    @extend %btn;
                    margin-top: 40px;
                    .lds-ring {
                        display: inline-block;
                        position: relative;
                        width: 30px;
                        height: 30px;
                        box-sizing : border-box;
                        //outline : 2px solid red;
                        & div {
                            box-sizing: border-box;
                            display: block;
                            position: absolute;
                            width: 25px;
                            height: 25px;
                            margin: 4px;
                            border: 2px solid #fff;
                            border-radius: 50%;
                            top :0%;
                            left: 0%;
                            //transform : translate(-50%, -50%);
                            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                            border-color: #fff transparent transparent transparent;
                            //outline : 1px solid yellow;
                        }
                        .lds-ring div:nth-child(1) {
                            animation-delay: -0.45s;
                        }
                        .lds-ring div:nth-child(2) {
                            animation-delay: -0.3s;
                        }
                        .lds-ring div:nth-child(3) {
                            animation-delay: -0.15s;
                        }
                        @keyframes lds-ring {
                            0% {
                                transform: rotate(0deg);
                            }
                            100% {
                            transform: rotate(360deg);
                            }
                        }
                    }

                    
                }
            }
            .verify-container {
                width: 100%;
                min-height: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                
                & p {
                    margin-left: 20%;
                    font-size: $font-large;
                }
            }
            .language {
                margin-top : 50px;
                display : flex;
                justify-content: flex-start;
                align-items: center;
                text-align : left;

                span:last-child {
                    margin-left : 2px;
                    color: $main_color;
                    font-size: $label_font;
                    text-decoration : underline;
                }
            }
        }
        .alert-success {
            position : fixed;
            top : 50px;
            left : 60%;
            //transform: translateX(-50%);
            padding: 7px 20px;
            font-size: $font-x-medium;
            background-color: $main_color;
            border-radius: 4px;
            color : $white_text_color;
            box-shadow: -2px 2px 15px $box-shadow-color;
        }
        .success-email-enter-active {
            transition: all 0.3s ease-out;
        }

        .success-email-leave-active {
            transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
        }

        .success-email-enter-from,
        .success-email-leave-to {
            transform: translateY(-50px);
            opacity: 0;
        }
    }*/
</style>