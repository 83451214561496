import Api from '@/services/Api';

export default {
    signIn(credentials) {
        return Api().post(`${process.env.VUE_APP_URL}/auth/login`, credentials)
    },
    signout(user) {
        return Api().post(`${process.env.VUE_APP_URL}/auth/logout`, user)
    },
    /*signUp(data) {
        return Api().post('auth/register', data)
    },*/

    verifyMail(link) {
        return Api().get(`${process.env.VUE_APP_URL}/auth/verify-email/${link}`)
    },
    me(credentials) {
        return Api().post(`${process.env.VUE_APP_URL}/auth/me/${ credentials[1] }`)
        /*return Api().post(`${process.env.VUE_APP_URL}/auth/me/${ credentials[1] }`, {
            headers: { Authorization: `Bearer ${ credentials[0] }` }
        })*/
    }
}
