import Api from '@/services/Api'

export default {
    getAll(lang, userId) {
        return Api().get(`${process.env.VUE_APP_URL}/videos/${lang}/${userId}`)
    },
    getById(id) {
        return Api().get(`${process.env.VUE_APP_URL}/videos/${id}`)
    },
    getVideo(category, title, userId) {
        return Api().get(`${process.env.VUE_APP_URL}/videos/name/${category}/${encodeURIComponent(title)}/${userId}`)
    },
    uploadVideo(data, optional) {
        return Api().post('videos', data, optional)
    },
    updateVideo(id, data) {
        return Api().put(`videos/${id}`, data)
    },
    updateViews(id) {
        return Api().put(`videos/${id}/views`)
    },
    uploadThumbnail(id, data) {
        return Api().put(`videos/${id}/thumbnails`, data)
    },
    deleteById(id) {
        return Api().delete(`videos/${id}`)
    }
}