<template>
  <div class="layout-default">
    <sidebar />
    <div class="layout-side">
      <navbar :activeLink="'home'"/>
      <section class="home-view viewer">
        <div class="banner-section">
          <!--<div class="banner-image">
            <h1> {{ langObj.home.banner.title }}</h1>
            <p>
              {{ langObj.home.banner.description }}
            </p>
          </div>-->
          <div class="banner-slide-ctn">
            <div class="image-banner" v-for="banner in slideBanner" :key="banner" :style='"background-image : url(" + imgUrl + banner.toString() + ")"'>
            </div>
          </div>
        </div>
        <div class="alert-error" v-if="errored">
            {{ langObj.home.error.load  }}
        </div>
        <main v-else-if="categories.length == 0" :class="{'load-hide' : categories.length !== 0}">
          <div class="loading-video">
            <div class="title_section animated-background">
              <div class=""></div>
            </div>
            <div class="agile_container">
              <div class="arrow left_arrow animated-background"></div>
              <div class="slider_part">
                <div class="slider_container">
                  <div class="wrapper">
                      <div class="video-container" >
                          <div class="image-container animated-background animated-background-video" >
                              <div class="cover"></div>
                          </div>
                          <div class="video-description">
                              <h5 class="animated-background">
      
                              </h5>
                              <p class="animated-background">
      
                              </p>
                          </div>  
                      </div>
                      <div class="video-container" >
                          <div class="image-container animated-background animated-background-video" >
                              <div class="cover"></div>
                          </div>
                          <div class="video-description">
                              <h5 class="animated-background">
      
                              </h5>
                              <p class="animated-background">
      
                              </p>
                          </div>  
                      </div>
                      <div class="video-container" >
                          <div class="image-container animated-background animated-background-video" >
                              <div class="cover"></div>
                          </div>
                          <div class="video-description">
                              <h5 class="animated-background">
      
                              </h5>
                              <p class="animated-background">
      
                              </p>
                          </div>  
                      </div>
                  </div>
                </div>
              </div>
              <div class="arrow right_arrow animated-background"></div>
            </div>
          </div>
      
        </main>
        <main class="main-content" v-else>
          <videoSlider v-for="category in categories" :key="category.id" :titleCategory="category.title" :datas="categoryMap(category.id, dataVideo)" v-show="categoryVideoEmpty(categoryMap(category.id, dataVideo))" class="video-section"></videoSlider>
        </main>
      </section>
    </div>
  </div>
  
  <footerNav :lang="userLang"/>

</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue';
//import navbar from './../components/navbar.vue';
import navbar from './../components/navhead.vue';
import sidebar from '@/components/sidebar';

import videoSlider from './../components/video-slider.vue';
import takafaLogo from './../assets/Images/Logo sans slogan.png';
//import axios from 'axios';
import Api from '@/services/Api';
import videosService from '@/services/VideosService';
import footerNav from '@/components/footer.vue';

//import VideoService from '@/services/VideosService'
import { ref, onBeforeMount, computed, provide } from 'vue';
import { useStore, mapGetters } from 'vuex';
export default {
  name: 'Home',
  setup () {
    const dataVideo = ref([]);
    const categories = ref([]);
    const store = useStore();
    //const suscribe = ref([]);     
    const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
    console.log(user);
    const langObj = ref(store.getters['getLang']).value[user.value.lang]
    console.log(langObj);
    const userLang = ref(user.value.lang);
    let thisloading = false;
    const imgUrl = store.getters['getImgUrl']
    //const categories = ref([]);
onBeforeMount( async()=> {
      
      await videosService.getAll(userLang.value, user.value.id).then(async res => {
          console.log(res);
          let videosData = res.data;
          dataVideo.value  = videosData;          //return videosData;
          await Api().get(`${process.env.VUE_APP_URL}/categories/${userLang.value}/`).then(res => {
              console.log(res);
              let categoryData = res.data;
              categories.value  = categoryData;
              //return videosData;
              thisloading = true
          })
          .catch (err => {
            console.log(err);
          })
      })
      .catch (err => {
        console.log(err);
      })
      
      
      
    });
    /*Api().get(`${process.env.VUE_APP_URL}/program/getVideos/${user.value.program_package}`).then(res => {
      console.log(res)
        suscribe.value = res.data
      })
      .catch(err => {
        console.log(err)
      }) 
      provide('videoSubscription', suscribe);*/
      provide('homeLang', langObj)
    //provide('category', categories)

    return {
      dataVideo,
      categories,
      langObj,
      userLang,
      thisloading,
      //suscribe,
      imgUrl
    }
  },
  data () {
    return {
      brandLogo : takafaLogo,
      bannerImg : `storage/Home/banner.jpeg`,
      slideBanner : [
        `storage/Home/banner.jpeg`,
        `storage/Home/banner3.jpg`,
        `storage/Home/banner4.jpg`,
      ] ,
      datasVideo : [
        {
          "id_video" : "3",
          "video_name" : "Takafa Mindset"
        },
        {
          "id_video" : "5",
          "video_name" : "Takafa Mindset 2"
        }
      ],
      another : [
        1, 2 ,3 , 4, 5 , 6, 7
      ],
      atitle : "Emotional Well Being",
      errored : false,
      loading : this.thisloading || false
    }
  },
  /*provide () {
    return {
      videoSubscription : this.suscribe
    }
  },*/
  components: {
    //HelloWorld,
    navbar,
    videoSlider,
    footerNav,
    sidebar
  },
  computed : {
    ...mapGetters({
      //imgUrl  : 'getImgUrl', 
    })
  },
  methods: {
    /*bannerLoad() {
      axios.get("http://localhost/Takafa%20Streaming/api.php?action=getBanner").then(res => {
          console.log(res);
          let banner = res.data.link;
          this.bannerImg = banner;
      })
      .catch (err => {
        console.log(err);
      })
    }*/
    categoryMap(categoryId, videoArray) {
      return videoArray.filter(video => video.categoryId === categoryId);
    },
    categoryVideoEmpty(categoryArray) {
      return categoryArray.length <=0 ? false : true
    }
    
  },
  beforeMount() {
    //this.fetchVideoData();
    //this.dataVideo = this.fetchVideoData();
    //console.log(this.dataVideo);
    //this.getVideos()
  }
}
//this.bannerLoad();
</script>


<style lang="scss" scoped>
  
  @import '../assets/styles/_variables.scss';
  @import '../assets/styles/_globals.scss';

  .banner-section {
    
  }
  .videos {
    position: relative;
    min-height: 300px;
    width: 100%;
    padding: 5px 10px;
    // overflow: hidden;
    // outline: 1px solid black;
    h3 {
      width: 100%;
      text-align: left;
      padding-left: 50px;
    }

  }
  .load-hide {
    display : none;
    z-index : -100;
  }
  main {
    padding-bottom: 100px;
    .loading-video {
      background-color: #fff;
      width: 100%;
      height : 300px;
      padding: 50px 5vw;
      padding-bottom: 100px;
      //outline: 1px solid black;
      & > div {
        display: block;
      }
      .title_section {
        //outline: 1px solid blue;
        width: 20%;
        height : 20px;
        margin-bottom: 50px;

      }
      .agile_container {
        width: 90%;
        //height: ;
        //outline: 1px solid purple;
        position: relative;
        .arrow {
          position : absolute;
          width: 5vh;
          height: 5vh;
          border: none;
          //outline: 1px solid yellow;
          border-radius: 50%;
          top : 50%;
          z-index : 5;
          transform: translateY(-50%);

        }
        .left_arrow {
          left : 0;
        }
        .right_arrow {
          right : 0;
        }
        .slider_part {
          width : 100%;
          //outline: 1px solid green;

          .slider_container {
            height: 250px;
            width: 100%;
            //outline: 1px solid crimson;
            .wrapper {
              width : 100%;
              //outline: 3px solid cyan;
              display: grid;
              grid-template-columns: auto auto auto;
              //justify-content: space-between;
              //align-items: center;
              @media screen and (max-width : 1000px) {
                grid-template-columns: auto auto;
              }
              @media screen and (max-width : 640px) {
                grid-template-columns: auto;
              }
              justify-items : center;
              .video-container {
                width: 320px;
                //outline: 1px solid gray;
                @media screen and (max-width : 1000px) {
                  &:last-child {
                    display: none;
                  }
                  width : 250px;
                }
                @media screen and (max-width : 640px) {
                  &:nth-child(2) {
                    display: none;
                  }
                  &:last-child {
                    display: none;
                  }
                }
                height: fit-content;
                .image-container {
                  width:100%;
                  height: 181px;
                  @media screen and (max-width : 1000px) {
                  
                    height : 142px;
                  }
                  //outline: 1px solid pink;
                  //animation-name: placeHolderVideo;
                }
                .video-description {
                  margin-top: 8px;
                  padding: 5px;
                  h5 {
                    width: 70%;
                    height: 20px;
                    //outline: 1px solid orange;

                    
                  }
                  p {
                    height: 47px;
                    width : 100%;
                    //outline: 1px solid brown;

                  }
                }
              }
            }
          }
        }
      }
      @keyframes placeHolderTitle {
        0% {
          background-position: -320px 0
        }
        100% {
          background-position: 320px 0
        }
      }
      @keyframes placeHolderVideo {
        0% {
          background-position: -320px 0
        }
        100% {
          background-position: 320px 0
        }
      }
      @keyframes placeHolderVideoTitle {
        0% {
          background-position: -320px 0
        }
        100% {
          background-position: 320px 0
        }
      }
      @keyframes placeHolderVideoDescription {
        0% {
          background-position: -320px 0
        }
        100% {
          background-position: 320px 0
        }
      }

      .animated-background {
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-color: #f6f7f8;
        //background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
        background: $loading_background;
        animation-name: placeHolderVideo;
        background-size: 320px 181px;
        //background-size: 800px 104px;
        //height: 70px;
        position: relative;
        //&-video {
        //}
      }

    }
  }
  
</style>