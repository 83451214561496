
<template>
  <div class="base-layout" id="base-layout">
    <router-view :key="$route.fullPath" />

  </div>
  <sessionExp/>
  <!-- <onboardComp/> -->
</template>

<script>

/* eslint-lint-disable */
import { useStore } from 'vuex';
import { computed, onMounted, ref, provide } from 'vue';
//eslint-lint-disable
import sessionExp from '@/components/autoLogout.vue';
//import onboardComp from '@/components/onboarding.vue';

export default {
  //inject : ['store']
  setup() {
    /*const count = computed(()=> store.state.count);
    return {count1}*/
    /*const user = ref({
      firstname : ''
    })*/
      const store = useStore();
      const isMobile = ref(false)
      const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
      const followUp = ref (computed(()=> store.getters['getUserFollowUp']))
      console.log(followUp.value)
      if (!followUp.value[0]) {
        console.log("user")
        store.commit('SET_USER_FOLLOW_UP')
        //return
      }
      console.log(user)
      
      
      onMounted(()=> {
        
      })

      provide('isMobile', isMobile)
      provide('user', user)

    return {
      isMobile
    }
  },
  data()  {
    return {
      //isMobile : false
    }
  },
  components : {
    sessionExp,
    //footerNav,
    //onboardComp
  },

  methods : {
    initScripts() {
      /*let flutterwave =  document.createElement('script');
      flutterwave.setAttribute('src' , 'https://checkout.flutterwave.com/v3.js');
      document.body.appendChild(flutterwave);*/
    },
    onResize() {
      if (window.innerWidth <= 768) {
          this.isMobile = true;
      } else {
          this.isMobile = false
      }
    },
  },

  mounted () {
    this.$nextTick(()=> {
            let lastPosition = 0;
            window.addEventListener('resize', this.onResize)
            
            
    })
    this.onResize();
  }

  
}
</script>


<style>

/*@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
body {
  padding: 0;
  height: fit-content;
}
#app {
  /* font-family: Questrial, Helvetica, Arial, sans-serif; */
  /* font-family: 'Uni Neue', Helvetica, Arial, sans-serif; */
  /* font-family: 'Open Sans', Helvetica, Arial, sans-serif; */
  /* font-family: Montserrat, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-align: center;
  /* color: #2c3e50; */
  /* color: black; */
  /* min-height: 100vh; */
  /* outline: 2px solid black; */
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height : 100vh;
}
#app  * {
  box-sizing: border-box;
}
/*
#nav {
  padding: 0px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/


#main-body {
  position: relative;
  width: 100%;
  padding: 0;
  /* height: calc(100% - 140px); */
  min-height: 100vh;
  z-index : 2;
}
.router-view, .base-layout {
  min-height : 100vh;
  position: relative;
}
/*.footer {
  position: relative;
  
  width: 100%;
  color: white;
  font-size: 1rem;
  background: transparent;
  height: 100px;
  
  z-index: -5;
  
}

.footer > footer  {
  box-sizing: border-box;
  width: 100%;
  background-color: #20A7E6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;

}
.footer > footer a {
  text-decoration: none;
  color: white;
  text-align: center;
}
.footer > footer > p {
  width: 100%;
  text-align: center;
  padding: 1px;
  margin: 2px 0;
}
.footer > footer > ul {
  list-style-type:none ;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline-start: 0;
  margin: 2px 0;
}

.footer > footer > ul li{
  margin: 0 15px;
}*/

/* footer {
  width : 100%;
  box-sizing: border-box;
  padding: 10px 30px;
  background-color: #20A7E6;
}
footer > .footer-container {
  min-height: 100px;
  display : grid;
  box-sizing: border-box;
  grid-template-columns: auto auto auto auto auto;
  justify-items: center;
  align-items: center;
  
}

footer .image {
  font-size : .875rem;
  color: #fff;
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .image > img {
  height: 3.5rem;
}

footer .image > .icon a {
  margin: 0 5px;
  font-size: 1.125rem;
  color : #fff;
  cursor: pointer;
}
footer > .footer-container .last-part{
  grid-column: 3 / span 4;
  width : 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  color : white;
  justify-items: center;
  align-items: center;
}
footer > .footer-container .last-part .col-foot{
  padding : 5px;
  box-sizing: border-box;

}
footer > .footer-container .last-part .col-foot > h4{
  color: #fff;
  font-size: 1rem;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: left;
}
footer > .footer-container .last-part .col-foot ul {
  padding-inline-start: 0;
  text-align : left;
  color : white;

}
footer > .footer-container .last-part .col-foot ul a {
  text-decoration: none;
  color : inherit;
  width: fit-content;
  display: block;
}
footer > .footer-container .last-part .col-foot ul a:hover .line {
  width : 100%;
}
footer > .footer-container .last-part .col-foot ul a h4 {
  width: 100%;
  font-weight: 400;
}
footer > .footer-container .last-part .col-foot ul a .line {
  position: relative;
  margin-top : 1px;
  height: 2px;
  background-color: white;
  width: 50%;
  transition: width .2s ease-out;
}

@media screen and (max-width:468px) {
  footer > .footer-container {
    display: flex;
    flex-direction: column-reverse;
  }
  footer > .footer-container .last-part {
    grid-template-columns: auto auto;
    flex-basis: 100%;
  }
  footer > .footer-container .last-part .col-foot ul {
    text-align: center;
  }
  footer > .footer-container .last-part .col-foot ul a .line {
    width : 100%;
  }
} */
</style>
