<template>
  <div class="footer">
      <!--<footer>
        <p class=""><a href="terms">Terms and conditions</a> </p>
        <p class=""><a href="privacy">Privacy Policy</a></p>
          <ul class="">
              <li class=""><a href="https://www.facebook.com/Takafa-Mindset-105092634439064" target="_blank"><i class="fab fa-facebook"></i></a></li>
              <li class=""><a href="https://www.instagram.com/takafamindset/" target="_blank"><i class="fab fa-instagram" ></i></a></li>
              <li class=""><a href="https://www.linkedin.com/company/64909942/admin/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
              <li class=""><a href="https://www.youtube.com/channel/UCAT2jfsVsvTi_XxojpLK-kw" target="_blank"><i class="fab fa-youtube"></i></a></li>
          </ul>
          <p class=""> 2020-2021 |  All Rights reserved </p>
      </footer>-->
        <footer>
            <div class="footer-container">
              <div class="true-footer">
                <div class="brand-container">
                  <img :src="brandLogo" alt="Takafa Mindset" class=""/>
                  
                </div>
                <div class="links">
                  <div class="col-foot">
                    <p class="bd-lg bd-lg--sbold">Takafa Mindset</p>
                    <ul>
                      <a target="_blank" :href="langRoute + 'about-us'" class="title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.takafa.list[0] }}
                          <div class="line"></div>
                        </h4>
                      </a>
                      <a target="_blank" :href="langRoute + 'terms'" class="title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.takafa.list[1] }}
                          <div class="line"></div>
                        </h4>
                      </a>
                      <a target="_blank" :href="langRoute + 'privacy'" class="title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.takafa.list[2] }}
                          <div class="line"></div>
                        </h4>
                      </a>
                    </ul>
                  </div>
                  <div class="col-foot">
                    <p class="bd-lg bd-lg--sbold">{{ langue.footer.services.title }}</p>
                    <ul >
                      <a target="_blank" :href="langRoute" class="title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.services.list[0] }}
                          <div class="line"></div>
                        </h4>
                      </a>
                      <a target="_blank" :href="langRoute + 'events'" class="title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.services.list[1] }}
                          <div class="line"></div>
                        </h4>
                      </a>
                      <a target="_blank" :href="langRoute + 'consulting'" class="title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.services.list[2] }}
                          <div class="line"></div>
                        </h4>
                      </a>
                    </ul>
                  </div>
                  <div class="col-foot">
                    <p class="bd-lg bd-lg--sbold">{{ langue.footer.support.title }}</p>
                    <ul >
                      <a href="#" class="title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.support.list[0] }}
                          <div class="line"></div>
                        </h4>
                      </a>
                      <a href="mailto:support@takafamindset.com" class="pointer title-base bd-md bd-md--sbold">
                        <h4>
                          {{ langue.footer.support.list[1] }} <i  class="fa fab-envelope ml-1 mt-1"> </i>
                          <div class="line"></div>
                        </h4>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="line-footer"></div>
              <div class="copyright bd-md bd-md--sbold">
                <p>&copy; 2020-{{(new Date()).getFullYear()}} {{ langue.footer.rights }}</p>
                  <p>{{ langue.footer.follow }}</p>
                  <div class="icon">
                    <a target="_blank" :href="fbLink"><i class="pi pi-facebook"></i></a>
                    <a target="_blank" :href="inLink"><i class="pi pi-instagram"> </i></a>
                    <a target="_blank" :href="inDinLink"><i class="pi pi-linkedin"> </i></a>
                    <a target="_blank" :href="ytLink"><i class="pi pi-youtube"> </i></a>
                  </div>
              </div>
            </div>
        </footer>
    </div>
</template>

<script>
//import takafaLogo from './../assets/Images/Logo sans slogan.png';

export default {
    name : 'footerNav',
    data () {
        return {
            langue : this.$store.getters.getLang[this.lang],
            brandLogo : "https://admin.takafamindset.com/takafa_backend/storage/main/Logo%20blanc.png",
        }
    },
    props : {
        lang : {
            type : String,
            default : 'EN'
        }
    },
    computed : {
        langRoute() {
            if (this.lang == "FR") {
                return "https://takafamindset.com/fr/"
            }
            return 'https://takafamindset.com/'
            },
        fbLink() {
          if (this.lang == "FR") {
              return "https://www.facebook.com/Takafa-Mindset-Fran%C3%A7ais-100216498452656"
          }
          return 'https://www.facebook.com/Takafa-Mindset-105092634439064'
        },
        inLink() {
          if (this.lang == "FR") {
              return "https://www.instagram.com/takafamindset_fr/"
          }
          return 'https://www.instagram.com/takafamindset/'
        },
        ytLink() {
          
          return 'https://www.youtube.com/channel/UCAT2jfsVsvTi_XxojpLK-kw'
        },
        inDinLink() {
          return 'https://www.linkedin.com/company/64909942/admin/'
        }
    },
    
    mounted () {
        console.log(this.langue)
    }
}
</script>

<style lang="scss">

</style>