<template>
    
        <transition name="slide" >
            <div class="wrapper" >
                <div class="video-container" v-for="(video, index) in datas" :key="video.title" :class="{'video-container-active' : !video.isActive}">
                    <div class="image-container" >
                        <router-link   :to="checkVideo(video).link">
                        <!--<div class="cover-header" :class="{'active' : !video.isActive}">
                            <h5>{{titleDisplay(video.title)}}</h5>
                        </div>-->
                        <img class="video-cover" v-bind:src="video.coverUrl + '-a.jpg'"/>
                        <!-- <img class="video-cover" v-bind:src="video.coverUrl"/> -->
                        <button class="play_btn" v-if="video.isActive">
                            <i class="pi pi-play"></i>
                        </button>
                        <button class="play_btn active" v-else>
                            <i class="pi pi-lock"></i>
                        </button>
                        <div class="video_overlay" :class="{'active' : !video.isActive}">
                            <h4 class="btn-neutral">
                                {{ lang.home.video.unaivalable }}
                            </h4>
                        </div>
                        </router-link>
                    </div>
                    <div class="video-description">
                        <div class="first">
                            <h5 class="bd-sm bd-sm--sbold" :class="{'word-trunc' : wordCount(titleDisplay(video.title))[1] }">
                                {{wordCount(titleDisplay(video.title))[0]}}
                            </h5>
                            <div class="about-vid">
                                <h6 class="bd-sm bd-sm--sbold">
                                    {{ video.firstname }}  {{ video.lastname }}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <span class="tag bd-sm bd-sm--sbold" v-show="index % 2 == 0">New</span>
                </div>
            </div>
        </transition>
    
</template>

<script>
//import axios from 'axios';
//import VideoService from '@/services/VideosService'
//import { useStore } from 'vuex';
import { ref, onBeforeMount, inject } from 'vue';
//import Api from '@/services/Api';

export default {
    setup (props) {
        const subscribVideo = ref([]);

        //const store = useStore(); 
        const videoData = ref(props.datas)
        //const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
        //const videoSub = inject('videoSubscription')
        const categoryName = inject('categoryTitle');
        const lang = inject('homeLang');
        //console.log(videoSub)
        //console.log(categoryName)
        onBeforeMount( async ()=> {
            /*await Api().get(`${process.env.VUE_APP_URL}/program/getVideos/${user.value.program_package}`).then(res => {
                ////console.log(res);
                subscribVideo.value = res.data;
                for (let j = 0; j < videoData.value.length; j++) {
                    const video = videoData.value[j];
                    let checking = validVideo(video, subscribVideo.value)
                    videoData.value[j]['link'] = checking.link
                    videoData.value[j]['isValid'] = checking.isValid
                }
                //console.log(videoData)
            })
            .catch(err => {
                //console.log(err)
            })*/
        })

        // functions
        /*function validVideo(video, videoCheckingArray) {
            let checkValue = {
                isValid : false,
                link : '/Video/' + categoryName + '/' + video.title
            };
            
            for (let index = 0; index < videoCheckingArray.length; index++) {
                const suscribe = videoCheckingArray[index];
                if (video.id == suscribe.videoId) {
                    checkValue.isValid = true
                }
            }

            return checkValue;

        }*/
        return {
            subscribVideo,
            videoData,
            //videoSub,
            categoryName,
            lang
        }
    },    
    name : "singleSlide",
    //inject : ['videoSubscription'],
    props : {
        datas : Array,
        //indexSlider : Number,
        //categoryTitle : String,
    },
    computed : {
        
    },
    methods : {
        titleDisplay(title) {
            return title.split("_").join(" ")
        },
        wordCount(title) {
            if ((title.split(" ").length > 5)) {
                return [((title.split(" ")).slice(0, 6)).join(" "), true]
            } else {
                return [title, false]
            }
        },
        checkVideo(video) {
            let checkValue = {
                isValid : false,
                link : '/Video/' + this.categoryName + '/' + video.title
            };
            
            return checkValue;

        },
        
    }
}
</script>

<style lang="scss" scoped>
    /*@import '../assets/styles/_variables.scss';
    @import '../assets/styles/_globals.scss';
    .wrapper {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        //overflow: hidden;
        // float: left;
        margin: 0px;
        // flex-basis: 100%;
        
        // top: 0;
        // left: 0;
        //outline: 5px solid red;
        // margin: 0 auto;
        //display: grid;
        display: flex;
        //justify-items: center;
        justify-content: space-around;
        align-items: center;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: center;
        grid-template-columns: auto auto auto;
        //grid-template-columns: repeat(3, minmax(0, 1fr));
        //grid-template-columns: 100px / auto auto auto;
        
        .video-container {
            @extend %video-style-base;
            &-active:hover {
                transform: none;
            }
        }
    }*/
    

</style>