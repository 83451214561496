<template>
    <div class="search-container">
        <div class="title_section">
            <h3 class="hdl-sm hdl-sm--sbold"> 
                {{ langObj.history.title }} / {{ langObj.history.sidebar.search }}
                <div class="line line-100"></div>
            </h3>  
        </div>
        <div v-if="noSearch == true" class="video_empty">
            <h2> {{ langObj.history.search.empty }} </h2>
        </div>
        <div v-else-if="searchedText.length > 0" class="searched">
            <div class="wrapper">
                <span v-for="search in searchedText" :key="search.id" class="bd-lg" @click="searchText(search.searched)">
                    {{ search.searched }} <button><i class="pi pi-times"></i></button>
                </span>
            </div>
        </div>
        <div v-else class="playlist-loading">
            <div class="play-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
import historyService from '@/services/HistoryService';
//import searchService from '@/services/SearchService';
import { ref, computed } from 'vue'
import { useStore } from 'vuex';
export default {
    name : 'SearchHistory',
    setup () {
        const searchedText = ref ([]);
        const noSearch = ref (null);
        const store = useStore();
        const user = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        const langObj = ref(store.getters['getLang']).value[user.value.lang];
        historyService.getSearchHistory(user.value.id)
        .then( res => {
            console.log(res)
            if (res.data.status == "empty") {
                noSearch.value = true;
            } else {
                searchedText.value = res.data
            }
        })
        .catch(err => {
            console.log(err)
        });
        return {
            searchedText,
            noSearch,
            langObj,
            user
        }
    },
    methods : {
        searchText(text) {
            this.$router.push({
                name : 'Search',
                query : {
                    searchText : text
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    /*
    @import '../../assets/styles/_variables.scss';
    @import '../../assets/styles/_globals.scss';
    .search-container {
        padding : 0;
        box-sizing: border-box;
        position: relative;
        //outline : 2px solid blue;
        min-height : 100vh;
        width: 100%;
        .video_empty {
            width: 100%;
            h2 {
                font-weight : normal;

            }
        }
        .searched {
            //outline : 2px solid red;
            height: 70vh;
            width: 100%;
            display : flex;
            justify-content: flex-start;
            align-items: flex-start;
            .wrapper {
                box-sizing: border-box;
                max-width: 80%;
                max-height: 50%;
                display: grid;
                padding: 5px;
                grid-template-columns: auto auto auto auto auto;
                grid-gap : 30px 15px;
                //outline: 1px solid olive;
                @media screen and (max-width : $mobile) {
                    grid-template-columns: auto auto;
                    max-width : unset;
                    max-height: unset;
                    width: 100%;
                    
                }
                & span {
                    height: 40px;
                    box-sizing: border-box;
                    padding: 5px;
                    border: 2px solid $main_color_border;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50px;
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        background-color: $hover_white_back;
                    }
                    & button {
                        @extend %btn-base;
                        margin-left: 4px;
                        font-size: $label_font;
                        height: 1.1rem;
                        width: 1.1rem;
                        border-radius: 50%;
                        background-color: transparent;
                        color : $main_color;

                    }
                }
            }
        }
        .playlist-loading {
            position : relative;
            display : flex;
            height : 70vh;
            width :100%;
            //outline : 2px solid red;
            justify-content: center;
            align-items : center;
            .play-loader {
                @extend %lds-ring;
                width : 40px;
                height : 40px;
                & div {
                    border-color : $main_color transparent transparent transparent;
                    border-width : 4px;
                    height : 35px;
                    width : 35px;
                    margin : 0;
                }
            }
            @media screen and (max-width : $mobile) {
                width: 100vw;
            }
        }
    }
    */
</style>