<template>
    <Transition name="alert-box">
        <div class="alert bd-lg" :class="{'alert-success' : isSuccess, 'alert-error' : isError, 'alert--mobile' : isMobile}">
            <span>
                {{ text }}
            </span>
            <button v-show="isStand" @click="close" class="bd-lg bd-lg--sbold">
                <i class="pi pi-times"></i>
            </button>
        </div>
    </Transition>
</template>

<script>
import { inject } from "vue"
export default {
    name : 'alertModal',
    setup () {
        const isMobile = inject("isMobile")

        return {
            isMobile
        }
    },  
    props : {
        text : {
            type : String
        },
        isStand : {
            type : Boolean,
            default : false,
        },
        isSuccess : {
            type : Boolean,
            default : false,
        },
        isError : {
            type : Boolean,
            default : false,
        },
        
    },
    emit  : ['closing'],
    methods : {
        close () {
            this.$emit('closing')
        }
    }
}
</script>

<style lang="scss" scoped>
    /*@import '../assets/styles/_variables.scss';
    @import '../assets/styles/_globals.scss';
    .alert {
        padding : 10px 20px;
        background: $main_color;
        color : $white_text_color;
        position : fixed;
        bottom  : 20px;
        left : 50px;
        display: flex;
        justify-content: space-between;
        border-radius: 2px;
        z-index : 99;
        button {
            @extend %btn-base;
            margin-left: 5px;
            height: 20px;
            width: 20px;
            padding: 5px;
            font-size: $label_font;
            //background-color: $white_text_color;
            //color: $main_color;
            &:hover {
                background-color: $hover_white_back;
                
            }
        }
    }
    .alert-box-enter-active {
        transition: all 0.3s ease-out;
    }

    .alert-box-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .alert-box-enter-from,
    .alert-box-leave-to {
        transform: translateY(50px);
        opacity: 0;
    }*/
</style>