<template>
    <div id="onStart" class="onboard-start"></div>
</template>

<script>
import {  ref, onMounted, computed, inject } from 'vue'
import { useStore } from 'vuex';
//import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import onboardJs from '@/composables/onboard.js'
//import 'v-onboarding/dist/style.css'
import cookieManager from '@/composables/cookies'

export default  {
    name : 'onboardComp',
    emits : ['isTourStart', 'tourStart'] ,
    setup(props, { emit }) {
        //const isPlayTour = props.triggerTour || ref(false)
        //const emit = defineEmits(['isTourStart'])
        const startingTour = ref(false)
        const store = useStore();
        const isUser = ref(computed(()=> store.getters['isAuthenticated']));
        console.log(isUser)
        if (!isUser.value) return
        //const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
        const user = inject('user')
        const followUp = ref (computed(()=> store.getters['getUserFollowUp']))
        const langObj = ref(store.getters['getLang']).value[user.value.lang]
        console.log(langObj.onboard)
        const steps = ref(langObj.onboard.steps)
        const tourOpt = {
            offset: 20,             //distance from popover to target element
            borderRadius: 5,        //popover border radius
            allowClose: false,       //close on click outside
            highlight: true,        //show overlay
            highlightOffset: 5,     //overlay offset from target element
            keyboard: true,         //enable/disable keyboard controll
            width: '300px',         //specify popover's width
            zIndex: 20000,          //specify z-index 
            removeArrow: false,     //show/hide popover arrow
        }
        if (followUp.value[0]) {
            console.log(followUp.value[0])
            console.log("push")
            let step =  {
                element: '#follow-btn',
                title: user.value.lang == "EN" ? 'Follow up' : 'Suivi',
                content: user.value.lang == "EN" ? 'Here you can launch the follow-up of the program you\'re enrolled in so as to receive daily updates, reminders, and assignments related to the program.' : 'Ici, vous pouvez lancer le suivi du programme auquel vous êtes inscrit afin de recevoir quotidiennement des mises à jour, des rappels et des missions liées au programme.',
                placement: 'left-start',
                onNext : ()=> {
                    //emit('isTourStart')
                    //console.log(user)
                    setTimeout(()=> {
                        onboardJs.editBtnStyle("#0DADE7", ".wt-btns", "btn")
                    }, 1)
                },
                onPrevious : () => {
                    setTimeout(()=> {
                        onboardJs.editBtnStyle("#0DADE7", ".wt-btns", "btn")
                    }, 1)
                }
            }
            //if (steps.value[steps.value.length - 1].element != "#follow-btn") 
            steps.value.push(step)
        }
        // if (user.value) {
        // }
        const currentOnboard = cookieManager.getCookie("onBoard")
        console.log(currentOnboard)
        onMounted(() => {
            //console.log("I'm onboard")
            console.log(steps.value)
            for (let stepInd = 0; stepInd < steps.value.length; stepInd++) {
                if (stepInd == 0) {
                    steps.value[stepInd].title = `${ user.value.lang == "EN" ?'Hello' : 'Bonjour'} ` + user.value.firstname
                    
                }
                if (stepInd == steps.value.length - 1) {
                    steps.value[stepInd].onNext = () => {
                        console.log("emitting the end")
                        let menuBtn = document.getElementById("profile-menu-btn");
                        menuBtn.click()
                        //cookieManager.deleteCookie("onBoard")
                        emit('isTourStart', 'end')
                    }
                } else if (steps.value[stepInd].element == "#account-btn") {
                    steps.value[stepInd].onNext = () => {
                        setTimeout(()=> {
                            onboardJs.editBtnStyle("#0DADE7", ".wt-btns", "btn", user.value.lang)
                        }, 10)
                        let menuBtn = document.getElementById("profile-menu-btn");
                        menuBtn.click()
                    }
                } else {
                    steps.value[stepInd].onNext = () => {
                        setTimeout(()=> {
                            onboardJs.editBtnStyle("#0DADE7", ".wt-btns", "btn", user.value.lang)
                        }, 10)
                    }
                    
                }
                if (stepInd == 0) {
                    steps.value[stepInd].onPrevious = () => {
                        
                        emit('isTourStart')
                    }
                } else {
                    steps.value[stepInd].onPrevious = () => {
                        setTimeout(()=> {
                            onboardJs.editBtnStyle("#0DADE7", ".wt-btns", "btn", user.value.lang)
                        }, 10)
                    }
                }
                
            }
            if (user.value.user_type == "New" && !currentOnboard) {
                emit('tourStart', 'play')
                
                //startingTour.value = true
                /*console.log(followUp.value)
                console.log(steps.value)
                const tour = onboardJs.initOnboard(steps.value, tourOpt)
                tour.start()
                */
                onboardJs.editStyle(".wt-popover", { left: "50%", top : "50%", transform : "translate(-50%, -50%)", transition : "all 0.0s linear" })
                //onboardJs.editBtnStyle("#0DADE7", ".wt-btns", "btn", user.value.lang)
                cookieManager.createCookie("onBoard", true)
            }
        })
        

        return {
            steps,
            tourOpt,
            startingTour,
            user
            //tour
        }
    },
    props : {
        /*triggerTour : {
            type : Boolean,
            default : false
        }*/
    },
    components : {
        
    },
    methods : {
        replayTour(start) {
            this.$emit('isTourStart')
            console.log(start)
            if (start == "replay") {
                if (this.steps[0].element == "#onStart") {
                    this.steps.shift()
                    this.stepsCheck()
                }
            }
            console.log(this.steps)
            const tourReplay = onboardJs.initOnboard(this.steps, this.tourOpt)
            tourReplay.start()
            setTimeout(()=> {
                onboardJs.editBtnStyle("#0DADE7", ".wt-btns", "btn", this.user.lang)
                let startBtn = document.querySelector(".wt-btn-back")
                if (this.user.lang == "FR") startBtn.textContent = "Fermer"
            }, 10)
            return
            return
        },
        stepsCheck() {
            this.steps[0].onPrevious = () => {
                this.$emit('isTourStart')
            }
        }
    },
    onUpdated() {
        //replayTour()
    },
    /*onMounted() {

    }*/
    

}
</script>