<template>
    <div class="layout-default">
        <sidebar/>
        <div class="layout-side">
            <navBar/>
            <div class="search-result viewer">
                <div class="playlist-loading" v-if="!searchLoad">
                    <div class="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div v-else-if="videoSearch.length <= 0" class="video_empty">
                    <h2> No result found </h2>
                </div>
                <div class="video_list" v-else>
                    <div class="playlist_instance" v-for="play in videoSearch" :key="play.id">
                        <div class="playlist_cover">
                            <img class="video-cover" :src="play.coverUrl + '-a.jpg'"/>
                            <div class="play-cover-overlay">
                                <router-link :to="'/Video/' + categoryNameMap(play.categoryId) + '/' + play.title" class="btn-neutral bd-md">
                                    {{ langObj.videoDetail.playlist.viewVideo }} <i class="pi pi-eye"></i>
                                </router-link>
                            </div>
                        </div>
                        <div class="playlist_description">
                            <h4 class="hdl-sm">{{ titleDisplay(play.title) }} </h4>
                            <!-- <p class="author_desc hot-spot bd-lg bd-lg--sbold">{{ play.firstname }} {{ play.lastname }}</p> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <footerNav :lang="userLang"/>
</template>

<script>
import navBar from '@/components/navhead';
import { useRoute } from 'vue-router';
import Api from '@/services/Api';
import { ref, onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';
import sidebar from '@/components/sidebar';
import footerNav from '@/components/footer.vue';
export default {
    setup () {  
        const route =  useRoute();
        const videoSearch = ref([]);
        const store = useStore();
        const user = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        const userLang = user.value.lang
        const langObj = ref(store.getters['getLang']).value[user.value.lang];
        const searchLoad = ref(false);
        const categories = ref([]);
        //let videoEmpty = false;
        onBeforeMount( async() => {
            let payload = {
                searchText : route.query.searchText,
                lang : user.value.lang
            }
            await Api().post(`${process.env.VUE_APP_URL}/search/text`, payload).then( res => {
                console.log(res);
                videoSearch.value = res.data;
                searchLoad.value = true
            })
            await Api().get(`${process.env.VUE_APP_URL}/categories/${userLang}`).then(res => {
                console.log(res)
                categories.value  = res.data;
            })
            .catch (err => {
                console.log(err);
            })
        })
        return {
            videoSearch,
            langObj,
            userLang,
            searchLoad,
            categories
        }

    },
    name : 'search',
    components : {
        navBar,
        footerNav,
        sidebar
    },
    methods : {
        titleDisplay(title) {
            return title.split("_").join(" ");
        },
        categoryNameMap(videoCategoryId) {
            if (this.categories.length == 0) {
                return
            }
            return this.categories.filter(category => category.id === videoCategoryId)[0].title;
        }
    }

}
</script>

<style lang="scss" scoped>
    /*
    @import '../assets/styles/_variables.scss';
    @import '../assets/styles/_globals.scss';
    .search-result {
        padding : 0;
        position: relative;
        min-height : 100vh;
        //outline : 2px solid red;
        box-sizing: border-box;
        padding: 2.5% 5%;
        .video_empty {
            width: 100%;
            h2 {
                font-weight : normal;

            }
            .loader {
                @extend %lds-ring;
                width : 40px;
                height : 40px;

                & div {
                    border-color : $main_color transparent transparent transparent;
                    border-width : 4px;
                    height : 35px;
                    width : 35px;
                    margin : 0;
                }
            }
        }
        .video_list {
            @extend %playlist-layout;
            
            //outline : 2px solid blue;
            box-sizing : border-box;
            grid-template-columns : auto auto;
            grid-gap : 20px;
            //outline :  2px solid red;
            margin-top :  0;
            padding-left: 10px;
            @media screen and (max-width: $mid-screen) {
                grid-gap : 0px 0px;
            }
            @media screen and (max-width: $tablets) {
                grid-template-columns : auto;
                justify-content: center;
            }
            &:hover {
                
                scrollbar-color: $main_color;
                scrollbar-width: thin;
                overflow: none;
            }
            .playlist_instance {
                
                .playlist_cover {
                    width: 250px;
                    height: 141.25px;
                    @media screen and (max-width: $mid-screen) {
                        width : 170px;
                        height : 96px;
                    }
                }
                .playlist_description {
                    //outline : 1px solid blue;
                    padding-top: 10px;
                    h4 {
                        margin: 0;
                        padding: 0;
                        font-size: $font-medium;
                        font-weight: normal;
                        text-align: left;
                        @media screen and (max-width: $tablets) {
                            font-size: $label-font;
                        }
                    }
                }
            }
        }
    }
    */
</style>