export default {
    createCookie(cookieName, cookieValue, cookieExp = 1, path = "/") {
        const date = new Date();
        console.log(date)
        if (!cookieExp) {
            date.setTime(date.getTime() - (20 * 24 * 60 * 60 *1000))
        } else {
            date.setTime(date.getTime() + (cookieExp * 24 * 60 * 60 *1000))
        }
        //console.log(new Date(new Date(cookieExp).getTime()).toUTCString())
        document.cookie = `${cookieName}=${cookieValue};expires=${date.toString()};path=${path}`;
    },
    setCookie(cookieName) {
        
    },
    deleteCookie(cookieName) {
        this.createCookie(cookieName, null, null)
    },
    getCookie(cookieName) {
        const cookieDecode = decodeURIComponent(document.cookie)
        console.log(cookieDecode)
        if (!cookieDecode) return null
        const allCookies = cookieDecode.split("; ")
        let cookieValue = null;
        allCookies.forEach(cookie => {
            if (cookie.indexOf(cookieName) == 0) {
                cookieValue = cookie.substring(cookieName.length + 1)
            }
        })
        return cookieValue
    },
    displayCookies(elementSelector) {
        let element = document.querySelector(elementSelector)
        element.textContent = document.cookie
        console.log(decodeURIComponent(document.cookie))
    }
}