<template>
    <div class="layout-default">
      <sidebar/>
      
      <div class="layout-side">
        <!-- Include Navbar -->
        <navBar :activeLink="'home'"></navBar>
        <!-- All of the details of the video container -->
        <section class="detail-container">
          <section class="player_info">
            <div class="vid_playing_container">
              <article class="video_playing" v-if="video.id">
                <div class="player_container">
                  <player :video_src="video.url" @history="addHistory" v-if="checkVideo">
                  </player>
                  <div class="inactive-video" v-else>
                    <img :src="video.coverUrl + '-b.jpg'">
                  </div>
                  
                  <!--<div class="video-btn list-btn"> 
                    <div> 
                      {{ langObj.videoDetail.button.addPlaylist }}
                    </div> 
                    <button @click="playlistMod = true" class="control-btn">  
                      <i class="fas fa-plus"></i> 
                    </button> 
                  </div>-->
                  <!--<div class="video-btn like-btn"> 
                    <div>{{ numberLikes }}</div> 
                    <button @click="createFeeling" class="control-btn" :class="{'btn-like' : videoLiked}">
                      <i class="far fa-thumbs-up" :class="{'fas fa-thumbs-up' : videoLiked }"></i> 
                    </button> 
                  </div>-->
                  <!-- <div class="model-icon"></div> -->
                </div>
                <div class="video_info">
                  <div class="video_title">
                    <div class="left-info">
                      <div class="video_author">
                        <h4 class="hot-spot bd-lg bd-lg--sbold">
                          {{ langObj.videoDetail.info.host }} : {{ video.firstname }} {{ video.lastname }}
                        </h4>
                      </div>
                      <h4 class="bd-lg bd-lg--sbold">{{titleDisplay(video.title)}}</h4>

                    </div>
                    <div class="right-info" :class="{'right-info--active' : videoLiked}">
                      <span @click="createFeeling"><i class="bd-lg" :class="{'pi pi-thumbs-up-fill' : videoLiked, 'pi pi-thumbs-up' :!videoLiked }"></i> {{ numberLikes }}</span>
                      <span @click="playlistMod = true"><i class="bd-lg pi pi-plus"></i> Add to playlist</span>
                    </div>
                  </div>
                  <!-- <h5 class="video_author"> {{video.video_author}}</h5> -->
                  <div class="video_description">
                    <h4 class="hot-spot bd-md bd-md--sbold">Summary</h4>
                    <p class="bd-md"> {{video.description}} </p>
                  </div>
                </div>
                <div class="comments" :class="{'comments-expand': isCommentExpand}">
                  <div class="all-comments">
                    <div class="title_section">
                      <h3 class="bd-md"> {{ langObj.videoDetail.comments.title }}</h3>
                    </div>
                    <!-- <div>
                      <inputs :type="'select'" :textHolder="'Sort by'" class="bd-md select-comment" :selectOption="['recent', 'newest']"></inputs>
                      
                    </div> -->
                  </div>
                  <div class="user-comment">
                    <div class="profile">
                      <img :src="imgUrl + thisUser.photoUrl" v-if="thisUser.photoUrl">
                      <i class="fas fa-user-circle" v-else></i>
                    </div>
                    <input ref="commentInput" type="text" class="bd-md" v-model="userComment.message">
                    <button :disabled="!userComment.isMessage" class="btn bd-md" @click="addComment">
                      <span v-if="!commentResize">
                        {{ langObj.videoDetail.comments.comment}}
                      </span>
                      <i class="pi pi-paper-plane" v-else> </i>
                    </button>
                  </div>
                  <!-- <button @click="sendMessage"> Send Socket </button> -->
                  <div class="comments-list">
                    <div class="single-comment" v-for="comment in allComments" :key="comment.id">
                      <div class="profile"> 
                        <img :src="imgUrl + comment.photoUrl" v-if="comment.photoUrl">
                        <i class="fas fa-user-circle" v-else></i>
                      </div>
                      <div class="comment-detail bd-md"> 
                        <span class="bd-md bd-md--sbold"> {{ comment.firstname + " " + comment.lastname }} </span>
                        <p class="text-comment bd-sm"> {{ comment.text }} </p>
                        <!--<div class="interactions">
                          <span>Reply</span>
                        </div>
                        <div class="replies">
                          <div class="all-comments all-replies">
                            <span> All Replies </span>
                            <button> <i class="fas fa-angle-right"></i></button>
                          </div>
                        </div>-->
                      </div>
                    </div> 
                    <div class="load-part" v-if="allComments.length > 5">
                      <button class="load-more bd-md btn-neutral">Load more</button>
                    </div>
                  </div>
                </div>
              </article>
              <!-- Video loder-->
              <article class="video_playing playing-loader" v-else>
                <div class="player_container animated-background">
                  
                </div>
                <div class="video_info">
                  <h4 class="animated-background">
    
                  </h4>
                  
                  <p class="animated-background"> </p>
                </div>
                <div class="comments" :class="{'comments-expand': isCommentExpand}">
                  <div class="all-comments">
                    <span class="animated-background">  </span>
                    <button> <i class="fas fa-angle-right" ></i></button>
                  </div>
                
                </div>
              </article>
            </div>
          </section>
          <section class="playlist_container">
            <article class="playlist" v-if="allplaylist.length > 0">
              <!--<div ref="selc" class="custom-select" >
                <div class="select-selected" :class="{'select-arrow-active' : selectControls.isDropdown}" @click.stop="selectControls.isDropdown = !selectControls.isDropdown">
                  {{changePlaylist}}
                </div>
                <div class="select-items select-hide" v-show="selectControls.isDropdown"> 
                  <div v-for="list in allplaylist" :key="list" :class="{'same-as-selected':(list[0] == changePlaylist)}" @click="thislog(list)">
                    {{ list[0] }}
                  </div>
                </div>
              </div>-->
              <div class="playlist-select">
                <inputs :type="'select'" :textHolder="'Watched Later'" class="bd-md" :selectOption="allplaylist.map((play) => play[0])" :isSelectEmit="true" v-model:modelValueText="changePlaylist" @selectClick="thislog"></inputs>
              </div>
                
              <div class="video_list">
                <div v-if="defaultPlaylist.error">
                  {{ langObj.videoDetail.playlist.empty }}
                </div>
                <div v-else-if="playlistLoad" class="playlist-loading">
                    <div class="play-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                </div>
                <div class="playlist_instance" v-for="play in defaultPlaylist" :key="play.id" v-else>
                  <div class="playlist_cover">
                    <img class="video-cover" :src="play.coverUrl + '-a.jpg'"/>
                    <div class="play-cover-overlay">
                      <router-link :to="'/Video/' + play.catTitle + '/' + play.title" class="btn-neutral bd-md">
                        <span>{{ langObj.videoDetail.playlist.viewVideo }}</span>  <i class="pi pi-eye"></i>
                      </router-link>
                    </div>
                  </div>
                  <div class="playlist_description">
                    <p class="bd-sm" :class="{'word-trunc' : wordCount(titleDisplay(play.title), 4)[1] }">{{ wordCount(titleDisplay(play.title), 4)[0] }}</p>
                    <p class="author_desc hot-spot bd-md">{{ play.firstname }} {{ play.lastname }}</p>
                  </div>
                </div>
                
              </div>
            </article>
            <!-- Video loder-->
            <article class="playlist playlist-loader" v-else>
              
              <div ref="selc" class="custom-select" >
                <div class="playlist-head animated-background">
                  
                </div>
              </div>
                
              <div class="video_list">
                <div class="playlist_instance">
                  <div class="playlist_cover animated-background">
                    
                  </div>
                  <div class="playlist_description">
                    <h5 class="animated-background"> 
                      
                    </h5>
                  </div>
                </div>
                <div class="playlist_instance">
                  <div class="playlist_cover animated-background">
                    
                  </div>
                  <div class="playlist_description">
                    <h5 class="animated-background"> 
                      
                    </h5>
                  </div>
                </div>
                <div class="playlist_instance">
                  <div class="playlist_cover animated-background">
                    
                  </div>
                  <div class="playlist_description">
                    <h5 class="animated-background"> 
                      
                    </h5>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section class="related" >
            <article class="related_video" v-if="relatedVideos.length != 0">
              <div class="title_section">
                <h3 class="hdl-sm hdl-sm--sbold"> {{ langObj.videoDetail.related.title }} <div class="line"></div></h3>
              </div>
              <div class="wrapper">
                <!--<div class="video-container" v-for="video in relatedVideos" :key="video.id">
                  <div class="image-container">
                    <router-link :to="'/Video/' + categoryNameMap(video.categoryId) + '/' + video.title">
                        
                        <img class="video-cover" v-bind:src="video.coverUrl + '-a.jpg'"/>
                        <div class="play_now">
                          <h4> {{ langObj.videoDetail.related.play }} </h4>
                          <button class="play_btn">
                              <i class="fas fa-play"></i>
                          </button>
                        </div>
                        <div class="video_overlay"></div>
                      </router-link>
                  </div>
                  <div class="video-description">
                    <h5>
                        {{titleDisplay(video.title)}}
                    </h5>
                    <p>
                        {{video.description}}
                    </p>
                  </div>
      
                </div>-->
                <div class="video-container" v-for="(video, index) in relatedVideos" :key="video.title" :class="{'video-container-active' : !video.isActive}">
                      <div class="image-container" >
                          <router-link   :to="'/Video/' + categoryNameMap(video.categoryId) + '/' + video.title">                        
                          <img class="video-cover" v-bind:src="video.coverUrl + '-a.jpg'"/>
                          <button class="play_btn" v-if="video.isActive">
                              <i class="pi pi-play"></i>
                          </button>
                          <button class="play_btn active" v-else>
                              <i class="pi pi-lock"></i>
                          </button>
                          <div class="video_overlay" :class="{'active' : !video.isActive}">
                              <h4>
                                  {{ langObj.home.video.unaivalable }}
                              </h4>
                          </div>
                          </router-link>
                      </div>
                      <div class="video-description">
                          <div class="first">
                              <h5 class="bd-sm bd-sm--sbold" :class="{'word-trunc' : wordCount(titleDisplay(video.title))[1] }">
                                  {{wordCount(titleDisplay(video.title))[0]}}
                              </h5>
                              <div class="about-vid">
                                  <h6 class="bd-sm bd-sm--sbold">
                                      {{ video.firstname }}  {{ video.lastname }}
                                  </h6>
                              </div>
                          </div>
                      </div>
                      <span class="tag bd-sm bd-sm--sbold" v-show="index % 2 == 0">New</span>
                  </div>
              </div>
            </article>
            <article class="related_video related-loader" v-else>
              <div class="title_section">
                <h3 class="animated-background" >  </h3>
              </div>
              <div class="wrapper">
                <div class="video-container">
                  <div class="image-container animated-background">
                    
                  </div>
                  <div class="video-description">
                    <h5 class="animated-background">
                        
                    </h5>
                    <p class="animated-background">
                        
                    </p>
                  </div>
      
      
                </div>
                <div class="video-container">
                  <div class="image-container animated-background">
                    
                  </div>
                  <div class="video-description">
                    <h5 class="animated-background">
                        
                    </h5>
                    <p class="animated-background">
                        
                    </p>
                  </div>
      
      
                </div>
                <div class="video-container">
                  <div class="image-container animated-background">
                    
                  </div>
                  <div class="video-description">
                    <h5 class="animated-background">
                        
                    </h5>
                    <p class="animated-background">
                        
                    </p>
                  </div>
      
      
                </div>
              </div>
            </article>
          </section>
          
        </section>

      </div>
      <div class="overlay-modal" v-show="playlistMod">
        <Transition name="playlist_modal">
        <playlistComponent class="playlistModal" @closePlaylist="playlistMod = false" :userPlaylists="playlist" @fetchPlay="refreshPlaylist" :videoId="video.id" :title="langObj.videoDetail.playlist.add" :createActive="false" v-show="playlistMod" @added="playlistAdd" @removed="playlistRemoved" ref="playCtn">
        </playlistComponent>
      </Transition>
      </div>
      <alertModal :text="alertText" v-show="isAlert"/>
      <div class="unavailable" :class="{'not-loaded' : (typeof(checkVideo) == 'object' ) ? true : false, }" v-show="!checkVideo">
        <div class="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1 class="hdl-lg hdl-lg--sbold">
          {{ langObj.videoDetail.unaivalable.title }}
        </h1>
        <router-link :to="'/home'" class="router-block hdl-md">
          <i class="pi pi-arrow-left"></i> {{ langObj.videoDetail.unaivalable.back }}
        </router-link>
        
      </div>
    </div>
    <footerNav :lang="userLang"/>
</template>

<script>
import navBar from '@/components/navhead';
import sidebar from '@/components/sidebar';
import player from '@/components/player';
import alertModal from '@/components/alertModal';
import inputs from '@/components/inputs';
import playlistComponent from '@/components/playlistComponent';
import takafaLogo from './../assets/Images/Logo sans slogan.png';
//import profile from './../assets/Images/profile.svg';
import utils from '@/composables/function';
import { ref, onBeforeMount, computed } from 'vue';
//import axios from 'axios';
import { useRoute } from 'vue-router';
import Api from '@/services/Api';
import videoApi from '@/services/VideosService';
import {  mapGetters, useStore } from 'vuex';
//import VueSelect from 'vue-next-select';
import 'vue-next-select/dist/index.css';
import footerNav from '@/components/footer.vue';

//import VueCoreVideoPlayer from 'vue-core-video-player';

//Vue.use(VueCoreVideoPlayer);

export default {
    setup () {
        const store = useStore();
        //intialiasition of empty object of video
        const video  = ref({});
        const route = useRoute();
        //console.log(route)
        // sstoring related Videos here
        const relatedVideos = ref([]);

        const videoLiked = ref(); // if video has been liked
        const likes  = ref(null);
        // storing all playlist here
        const playlist = ref([]);
        const defaultPlaylist =  ref([])
        const allComments = ref([]);
        //const subscribeVideo = ref([]);
        const categories = ref([]);
        const categoryName = route.params.category;
        const checkVideo = ref(null);
        //const dataComputed = computed (...mapGetters['currentUser']);
        const thisUser = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        const imgUrl = store.getters['getImgUrl'];
        const userLang = thisUser.value.lang;
        //console.log(userLang)
        const langObj = ref(store.getters['getLang']).value[thisUser.value.lang]
        const changePlaylist = langObj.videoDetail.playlist.title;
        //const user = thisUser.value;
        
        const loading = ref(false);
        console.log(langObj); 
        /*videoApi.getVideo(route.params.category, route.params.title, thisUser.value.id)
            .then(async res => {
              //console.log(res)
              let vid = res.data
              await Api().get(`${process.env.VUE_APP_URL}/program/getVideos/${thisUser.value.program_package}`).then(async res => {
                  console.log(res);
                  let sbvideo;
                  sbvideo = res.data;
                  let  mapping = sbvideo.filter( videoPack => videoPack.videoId == vid.id );
                  if (mapping.length == 1) {
                    console.log("mapp")
                    checkVideo.value = true;
                  } else {
                    checkVideo.value = false;
                  }
                  console.log(checkVideo.value)

              })
              .catch(err => {
                  console.log(err)
              })
              await Api().get(`${process.env.VUE_APP_URL}/categories/${userLang}`).then(res => {
                  console.log(res)
                  categories.value  = res.data;
              })
              .catch (err => {
                console.log(err);
              })
              
            })*/
            Api().get(`${process.env.VUE_APP_URL}/categories/${userLang}`).then(res => {
                  console.log(res)
                  categories.value  = res.data;
              })
              .catch (err => {
                console.log(err);
              })
        onBeforeMount ( async () => {
            // Getting current video
            await videoApi.getVideo(route.params.category, route.params.title, thisUser.value.id).then( async res => {
                console.log(res);
                video.value = res.data;
                console.log(video.value);
                //this.videos  = videosData;
                checkVideo.value = video.value.isActive
                let currentVideo = video.value          //return videosData;
                // Getting related videos
                await videoApi.getAll(userLang, thisUser.value.id).then(res => {
                  relatedVideos.value = res.data.filter(aVideo => (aVideo.categoryId === currentVideo.categoryId && aVideo.id != currentVideo.id));

                  console.log(relatedVideos);
                })
                .catch (err =>{
                  console.log(err);
                })
                .finally(async ()=> {
                  
            
                  loading.value = true;
                })
                const payload = { videoId : video.value.id , userId : thisUser.value.id};
                //const payload = { videoId : 34 , userId : 1};
                await Api().post(`${process.env.VUE_APP_URL}/feel/check`, payload)
                .then(res => {
                  console.log(res); 
                  videoLiked.value = Boolean(res.data.liked);
                  likes.value = res.data.likes
                  console.log(videoLiked);
                })
                .catch (err => {
                  console.log(err);
                })
                //Getting user playlists
                await Api().get(`${process.env.VUE_APP_URL}/users/playlists/${thisUser.value.id}`)
                  .then(res => {
                    console.log(res); 
                    playlist.value = res.data;
                    console.log(playlist);
                        let playCheck = "Watch Later";
                        if (thisUser.value.lang == "FR") {
                            playCheck = "Regarder plus tard";
                        }
                        let playId = playlist.value.filter( play => play.title == playCheck ? play.id : 0)[0].id;
                        console.log(playId)
                        Api().get(`${process.env.VUE_APP_URL}/playlist/userPlaylist/${playId}`)
                        .then(res => {
                          console.log(res); 
                          defaultPlaylist.value = res.data;
                          console.log(defaultPlaylist);

                        })
                        .catch (err => {
                          console.log(err);
                        })
                  })
                  .catch (err => {
                    console.log(err);
                  })
                await Api().get(`${process.env.VUE_APP_URL}/comments/video/${video.value.id}`)
                .then( res => {
                  console.log(res)
                  allComments.value = res.data;
                })
                .catch ( err => {
                  console.log(err)
                })
            })
            .catch (err => {
                console.log(err);
            });
          
        });
        
        return {
          video,
          relatedVideos,
          videoLiked,
          thisUser,
          playlist,
          defaultPlaylist,
          checkVideo,
          userLang,
          langObj,
          changePlaylist,
          allComments,
          loading,
          imgUrl,
          categoryName,
          categories,
          likes
        }
    },
    name: "VideoDetail",
    data () {
        return {
            brandLogo : takafaLogo,
            //avatar : profile,
            isCommentExpand : false,
            //loading : false,
            playlistMod : false,
            playerSize : {
              height : 406,
              width : 720
            },
            selectControls : {
              isDropdown : false
            },
            //checkVideo : false,
          //changePlaylist : "Watch Later",
          playOption : ["One", "Two", "Three"],
          userComment : {
            message : "",
            isMessage : false,
          },
          commentResize : false,
          isAlert : false,
          alertText : '',
          playlistLoad : false,
          socketConn : null,
        }
    },
    //props :[''],
    components: {
      //VueCoreVideoPlayer,
      player,
      navBar,
      playlistComponent,
      //VueSelect,
      alertModal,
      footerNav,
      sidebar,
      inputs
    },
    computed : {
      ...mapGetters(['currentUser', 'isAuthenticated', 'langObj']),
      allplaylist() {
        if (!this.playlist) {
          return ['Watch Later']
        }
        let newplay = this.playlist.map( play =>  [ play.title , play.id ])
        console.log(newplay)
        return newplay;
      },
      numberLikes() {
        if (this.likes <= 0 || this.likes == null) {
          if (this.userLang == "EN") {
            return "no likes"
          }
          return "Aucune réaction"
        } else {
          if (this.userLang == "EN") {
            return `${this.likes} likes`
          }
            return `${this.likes} aimés`
        }
      }
    },
    watch : {
      'userComment.message' () {
        if (this.userComment.message.trim() != "") {
          this.userComment.isMessage = true;
        } else {
          this.userComment.isMessage = false;
        }
      }
    },
    methods : {
      titleDisplay(title) {
            return title.split("_").join(" ")
      },
      wordCount(title, length = 5) {
        return utils.titleCount(title, length)
        /*if ((title.split(" ").length > 5)) {
            return [((title.split(" ")).slice(0, 6)).join(" "), true]
        } else {
            return [title, false]
        }*/
      },
      onResize() {
        this.resize();
        if (window.innerWidth >= 960) {
          this.playerSize.height = 406;
          this.playerSize.width = 720;
          console.log("okay");
        } else {
          this.playerSize.height = 339;
          this.playerSize.width = 600;
        }
      },
      resize() {
        if (window.innerWidth <= 468) {
          this.commentResize = true;
        } else {
          this.commentResize =  false;
        }
      },
      expandComment() {
        if(this.isCommentExpand) {
          this.isCommentExpand = false
          return
        }
        this.isCommentExpand = true
      },
      playlistAdd() {
        if (this.userLang == "EN") {
          this.alertText = "Video added"
        } else {
          this.alertText = "Vidéo ajoutée"
        }
        this.isAlert = true;
        setTimeout(() => {
          this.isAlert = false
        }, 2000);
      },
      playlistRemoved() {
        if (this.userLang == "EN") {
          this.alertText = "Video removed"
        } else {
          this.alertText = "Vidéo supprimée"
        }
        this.isAlert = true;
        setTimeout(() => {
          this.isAlert = false
        }, 2000);
      },
      async createFeeling() {
        //alert("hello");
        this.videoLiked = !this.videoLiked;
        await Api().post(`${process.env.VUE_APP_URL}/users/feel/${ this.thisUser.id }`, { id : this.video.id}).then(res => {
          console.log(res);
          //this.videoLiked = Boolean(res.data.liked);
          this.likes = res.data.number
        })
        .catch (err => {
          console.log(err)
        })
      },
      async addHistory() {
        await Api().post(`${process.env.VUE_APP_URL}/history/add`, { type : "watch", search:'watch', videoId : this.video.id, userId : this.thisUser.id}).then(res => {
          console.log(res);
        })
        .catch (err => {
          console.log(err)
        })
      },
      async getVideoPlaylist(playlistName) {
        //let playId = this.playlist.map( play => play.title == playlistName ? play.id : false)[0];
        let playId = playlistName;
        
        Api().get(`${process.env.VUE_APP_URL}/playlist/userPlaylist/${playId}`)
        .then(res => {
          console.log(res); 
          this.defaultPlaylist = res.data;
          this.playlistLoad = false;
          console.log(this.defaultPlaylist)
        })
        .catch (err => {
          console.log(err);
        })
      },
      thislog() {
        this.playlistLoad = true;
        if(this.changePlaylist != "") {
          let idPlaylist = this.allplaylist.filter(play => play[0] == this.changePlaylist)[0][1]
          console.log(idPlaylist)
          this.getVideoPlaylist(idPlaylist)
        }
        //console.log(playlistName[1]);
        //this.changePlaylist = playlistName[0];
        //this.selectControls.isDropdown = false;
      },

      async refreshPlaylist(type) {
        await Api().get(`${process.env.VUE_APP_URL}/users/playlists/${this.thisUser.id}`)
        .then( res => {
          this.playlist = res.data;
          if (type == 'remove') {
            this.$refs.playCtn.isAdding = false;
            this.$refs.playCtn.alertRemoved();
          } else if (type == 'add') {
            this.$refs.playCtn.isAdding = false;
            this.$refs.playCtn.alertBox();
          }
          //return true;
        }).catch(err => {
          console.log(err)
        })
      },
      async addComment() {
        let payload = {
          text : this.userComment.message,
          user : this.thisUser.id,
          video : this.video.id
        }
        await Api().post(`${process.env.VUE_APP_URL}/comments/add`, payload).then( async res => {
          console.log(res);
          await this.updateComments();
        })
        .catch( err => {
          console.log(err);
        })
      },
      async updateComments() {
        await Api().get(`${process.env.VUE_APP_URL}/comments/video/${this.video.id}`)
        .then( res => {
          console.log(res)
          this.allComments = res.data;
        })
        .catch ( err => {
          console.log(err)
        })
      },
      videoAv() {
        if (this.checkVideo) {
          return false
        } else {
          return true
        }
      },
      categoryNameMap(videoCategoryId) {
        if (this.categories.length == 0) {
          return
        }
        //console.log(this.categories.filter(category => category.id === videoCategoryId)[0].title);
        return this.categories.filter(category => category.id === videoCategoryId)[0].title;
      },
      sendMessage() {
        console.log(this.connection);
        this.connection.send(`${this.thisUser.email} send sometheming`)
      },
      streamConnect(user) {
        this.connection = new WebSocket("ws://localhost:8082");
        this.connection.onopen = function (event) {
          console.log(event);
          console.log (`Succes connect from ${user.email}`)
        }
        this.connection.onmessage = function (event) {
          console.log(event);
        }
      }

      
      
    },
    created () {
      this.streamConnect(this.thisUser);
    },
    mounted() {
      //console.log(this.currentUser)
      console.log(this.thisUser)
      this.$nextTick(()=> {
        window.addEventListener('resize', this.onResize)

      })
    },
    updated () {
      if (this.playlistMod) {
        //console.log("yes")
        //console.log(this.$refs.playCtn.userPlaylists)
        this.$refs.playCtn.checkVideoPlaylist()
      }
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.onResize);
    }


}
</script>

<style lang="scss" scoped>

</style>