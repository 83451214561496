
<template>
    <!--<div class="video_container">-->
        <!--<iframe ref="playCtn" :src="video_src" frameborder=0 allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
        </iframe>-->
        <vueVimeoPlayer class="video_container"  ref="playCtn" :video-url="video_src" :video-id="newId" :player-height="playerSize.height" :player-width="playerSize.width" :options="playerOption" @play.once ="onPlay"  @seeking="apause"/>
        
    <!--</div>-->
</template>
<script>
//import Player from '@vimeo/player';
//import { ref , onMounted } from 'vue';
import { vueVimeoPlayer } from 'vue-vimeo-player';
export default {
    setup () {
        /*const playCtn = ref(null);
        onMounted(()=> {
            console.log(playCtn.value)
        })*/
        return {
            //playCtn
        }
    },
    name : "player",
    data () {
        return {
            player : null,
            playerSize : {
                height : 406,
                width : 720
            },
            playerOption : {
                loop : true,
                responsive : true
            }
        }
    },
    props : ['video_src', 'video_height', 'video_width'],
    computed : {
        newId() {
            if (this.video_src) {
                return this.video_src.split('https://player.vimeo.com/video/')[1].split('?h')[0]
            } else {
                return ""
            }
        }
    },
    components: {
        vueVimeoPlayer,
    },
    methods : {
        autoplay() {
            //this.player = new Player (this.$refs.playCtn);
            //this.player.play();
        },
        onReady() {
			this.playerReady = true
		},
        onResize() {
        if (window.innerWidth >= 960) {

          //this.playerSize.height = 406;
            this.$refs.playCtn.height = this.playerSize.height = 406;
            this.playerSize.width = 720;
            console.log("okay");
        } else {
            this.playerSize.height = 339;
            this.playerSize.width = 600;
            console.log("okay1")
        }
        },
        onPlay(){
            this.$emit('history');
        },
        apause() {
            this.$refs.playCtn.pause()
        },
        setStop() {
            this.$refs.playCtn.getCurrentTime().then(res => {
                console.log(res)
            })
        }
    },
    mounted() {
        //this.autoplay();
        //console.log(this.video_height);
        this.$nextTick(()=> {
            window.addEventListener('resize', this.onResize)

        })
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    }


}
</script>

<style lang="scss" scoped>
    .video_container {
        position: relative;
        //box-sizing: border-box;
        width: 100%;
        height: 100%;
        //padding-bottom: 52.6%;
        //outline: 1px solid blue;
        iframe, .iframe {
            position: absolute;
            box-sizing: border-box;
            //width: 100%;
            //height: 100%;
            top: 0;
            left: 0;
            outline: 1px solid red;
            padding: 0;
            
        }
    }
</style>

