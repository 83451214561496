
<template>
    <div>
        <!-- Your are about to logout -->
    </div>
</template>

<script>
export default {
    name : 'sessionExp',
    data () {
        return {
            events : ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'],
            warningTimer : null,
            logoutTimer : null
        }
    },

    methods :{
        setTimers() {
            //this.warningTimer = setTimeout(this.warningMessage, 4 * 1000);
            this.logoutTimer = setTimeout(this.autoLogout,  10 * 60 * 1000)
        },
        resetTimer() {
            //clearTimeout(this.warningTimer);
            clearTimeout(this.logoutTimer);
            this.setTimers();
        },
        warningMessage() {
            alert("Warn");
        },
        autoLogout() {
            //alert("You've about to get logout");
            this.$store.dispatch('signOut')
            this.$router.push('/')
        }
        
    },
    mounted () {
        console.log(this.$route)
        //console.log(this.$route.name)
        if (this.$route.fullPath != "/" ) {
            this.events.forEach(function (event) {
                window.addEventListener(event, this.resetTimer)
            }, this)
            console.log("events mounted")
            this.setTimers()

        }
    },
    beforeUnmount() {
        this.events.forEach(function (event) {
            window.removeEventListener(event, this.resetTimer)
        }, this)
        
    }
}
</script>

<style lang="scss">

</style>