<template>
    <div class="layout-default">
        <sidebar/>
        <div class="layout-side">
            <navBar/>
            <section class="booking-view viewer">
                <div class="title_section">
                    <h3 class="hdl-sm hdl-sm--sbold">
                        {{ langObj.booking.title }}
                        <div class="line line-100"></div>
                    </h3>
                </div>
                <div class="no-booking" v-if="limit == 0">
                    <p>
                        {{ langObj.booking.unaivalable }}
                    </p>
                </div>
                <div class="calendly-container" ref="calendly" id="calendlyBook" data-auto-load="false" v-else-if="(count - 1) < limit">
        
                </div>
                <div class="no-booking" v-else>
                    <p>
                        {{ langObj.booking.limit }}
                    </p>
                </div>
            </section>
        </div>
    </div>
    <footerNav :lang="lang"/>
</template>

<script>
import navBar from '@/components/navhead';
import sidebar from '@/components/sidebar';
import axios from 'axios';
import { onBeforeMount, ref, computed } from 'vue';
import { useStore } from 'vuex';
import BookingApi from '@/services/Booking.js';
import footerNav from '@/components/footer.vue';

export default {
    name : 'Booking',
    setup () {
        //let events = [];
        const store = useStore();
        const limit = ref(0);
        const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
        const lang = ref(user.value.lang)
        const langObj = ref(store.getters['getLang']).value[user.value.lang]

        console.log(user.value.program_package);
        let currentPackage = user.value.program_package;
        if (currentPackage == 2 || currentPackage == 6) {
            limit.value = 2
        } else if (currentPackage == 3 || currentPackage == 7) {
            limit.value = 3
        }
        let currentProgram = user.value.program_status;
        const count = ref(null);
        const bookingLink = {
            en : {
                sep : 'https://calendly.com/mensah89/debrief_sessions_self_empowerment_program',
                ccpd : 'https://calendly.com/mensah89/debrief-sessions-character-and-career-development-program'
            },
            fr : {
                sep : 'https://calendly.com/mensah89/seances-de-debrief-programme-dautonomisation-de-soi',
                ccpd : 'https://calendly.com/mensah89/seances-de-debrief-pdcc'
            }
        }
        const userBookLink = (user.value.lang == "EN") ? ((currentProgram == 1) ? bookingLink.en.sep: bookingLink.en.ccpd) : ((currentProgram == 1) ? bookingLink.fr.sep: bookingLink.fr.ccpd)
        onBeforeMount(async () => {
            await BookingApi.getBookByUserId(user.value.id).then(async res => {
                console.log(res);
                    if(res.data.error) {
                        count.value =  1;
                    } else {
                        await axios.get(res.data.bookInvUid, {
                            headers : {
                                'Content-Type': 'application/json', 
                                Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ4MjMwMDMyLCJqdGkiOiJlNjgyMWQzMS00OWYwLTQyMzAtYWYzOC01NDkxNDI0OTY3MjQiLCJ1c2VyX3V1aWQiOiJCSEdCSTVCUlNDT0NVU0tXIn0.glb5dOw6ck1nNWLRFOta_pF6cZ--hK-8A7ktqvlyzRI'
                            }
                        }
                        ).then(response => {
                            console.log(response);
                            if (response.data.resource.status == "canceled") {
                                count.value = res.data.count  
                            } else {
                                count.value = res.data.count + 1
                            }
                            function eventListen() {
                                //let id = this.user.id;
                                function isCalendlyEvent(e) {
                                    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
                                }
                                //let numberTimes = this.count;
                                console.log(count.value)
                                window.addEventListener("message", function(e) {
                                    if(isCalendlyEvent(e)) {
                                        /* Example to get the name of the event */
                                        console.log("Event name:", e.data.event);
                    
                                        /* Example to get the payload of the event */
                                        console.log("Event details:", e.data.payload);
                    
                                        if (e.data.event == "calendly.event_scheduled") {
                                            const payload = {
                                                userId : user.value.id,
                                                bookUid : e.data.payload.event.uri,
                                                bookInvUid : e.data.payload.invitee.uri,
                                                count : count.value
                                            }
                                            console.log(payload)
                                            if (count.value <= 1) {
                                                BookingApi.insertBooking(payload).then(res => {
                                                    console.log(res)
                                                }).catch(err => console.log(err))
                    
                                            } else {
                                                BookingApi.updateBooking(payload.userId, payload).then(res => {
                                                    console.log(res)
                                                }).catch(err => console.log(err))
                                            }
                                        }
                                    }
                                });
                            }
                            eventListen();
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    }
                    console.log(count.value)
            })

            /*await axios.get(`https://api.calendly.com/event_types`, {
                params : {
                    organization : 'https://api.calendly.com/organizations/ABEFL4AWVMT77BKU',
                    user : 'https://api.calendly.com/users/BHGBI5BRSCOCUSKW'
                },
                headers : {
                    'Content-Type': 'application/json', 
                    Authorization: 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ4MjMwMDMyLCJqdGkiOiJlNjgyMWQzMS00OWYwLTQyMzAtYWYzOC01NDkxNDI0OTY3MjQiLCJ1c2VyX3V1aWQiOiJCSEdCSTVCUlNDT0NVU0tXIn0.glb5dOw6ck1nNWLRFOta_pF6cZ--hK-8A7ktqvlyzRI'
                }
            }
            ).then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })*/
        })

        return {
            user,
            count,
            userBookLink,
            limit,
            langObj,
            lang
            //eventListen,
        }
    },
    data () {
        return {
            
        }
    },
    components : {
        navBar,
        sidebar,
        footerNav
    },
    methods : {
        bookinscript(link) {
            let book =  document.createElement('script');
            book.setAttribute('src' , 'https://assets.calendly.com/assets/external/widget.js');
            book.onload = ()=> {
                this.initBook(link);
            }
            document.body.appendChild(book);
        },
        initBook(calendLink) {
            let calendlyBook =  document.createElement('script');
            calendlyBook.innerHTML = `
                Calendly.initInlineWidget({
                    url: '${calendLink}?hide_gdpr_banner=1',
                    parentElement: document.getElementById('calendlyBook'),
                    prefill: {
                        name: '${this.user.firstname}',
                        email: '${this.user.email}'
                        
                    },
                    utm: {}
                });
            `
            document.body.appendChild(calendlyBook);
            
        },
        /*eventListen() {
            let id = this.user.id;
            function isCalendlyEvent(e) {
                return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
            }
            let numberTimes = this.count;
            console.log(numberTimes)
            window.addEventListener("message", function(e) {
                if(isCalendlyEvent(e)) {
                    

                    if (e.data.event == "calendly.event_scheduled") {
                        const payload = {
                            userId : id,
                            bookUid : e.data.payload.event.uri,
                            bookInvUid : e.data.payload.invitee.uri,
                            count : numberTimes
                        }
                        if (numberTimes <= 1) {
                            BookingApi.insertBooking(payload).then(res => {
                                console.log(res)
                            }).catch(err => console.log(err))

                        } else {
                            BookingApi.updateBooking(payload.userId, payload).then(res => {
                                console.log(res)
                            }).catch(err => console.log(err))
                        }
                    }
                }
            });
        }*/
    },
    beforeMount() {
    },
    mounted() {
        console.log(this.user.id)
        if (this.limit != 0) {
            this.bookinscript(this.userBookLink);
        }
        //this.initBook();
        //this.eventListen();
    }
}
</script>

<style lang="scss" scoped>
    /*@import '../assets/styles/_variables.scss';
    @import '../assets/styles/_globals.scss';
    .booking {
        padding: 20px;
        min-height: 100vh;
        //padding-bottom : 100px;
        position  : relative;
        .title_section {
            @extend %title-base;
            
        }
        .calendly-container {
            box-sizing : border-box;
            min-width: 320px;
            height : 120vh;
            padding : 20px 0px;


        }
        .no-booking {
            width : 100%;
            height : 60vh;
            display : flex;
            align-items: center;
            justify-content: center;
            font-size : $font-x-medium;
        }
    }*/
</style>