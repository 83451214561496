<template>
    <article>
        <ul class="style-ul">
            <!-- <router-link :to="'playlist'" :class="{'active' : activeLink(activeSection)[0]}" > <i class="fa fa-user-cog"></i>Account</router-link> -->
            <li class="style-list" @click="openPlaylist"> <i class="pi pi-plus" @click="openPlaylist"></i> {{ lang.playlist.create.title }}  </li>
            <span class="style-list"  v-for="play in playlists" :key="play.id" :class="{'active' : (play.title == activeSection ? true : false)}" 
            @click="routePlaylist(play.title)"
            > 
                <i v-if="play.title == 'Watch Later'" class="fa fa-clock"> </i>
                {{ play.title }} 
            </span>
            
        </ul>
    </article>
    

</template>

<script>

export default {
    name : 'playlistNavBar',
    emits : ['createPlaylist', 'getPlaylist'],
    data ()  {
        return {
            isAccount : false,
            isProfile : false,
        }
    },
    props : {
        activeSection : String,
        playlists : Array,
        lang : Object
    },
    computed : {
        
    },
    components  : {
        
    },
    methods : {
        activeLink(link) {
            this.isAccount = false
            this.isProfile = false
            if (link == "account") {
                this.isAccount = true
                return [this.isAccount, this.isProfile]
            } else if(link == "profile") {
                this.isProfile = true
                return [this.isAccount, this.isProfile]
                
            }
        },
        openPlaylist() {
            this.$emit('createPlaylist')
        },
        emitPlaylist(playId, title) {
            this.$emit('getPlaylist', playId, title)
            console.log('emitted');
        },
        routePlaylist(playTitle) {
            this.$router.push(`${playTitle}`)
        },
        testBtn() {

        }
    },
    mounted() {
        //this.activeLink("account")[0]
    }
}
</script>

<style lang="scss" scoped>
    @import '../assets/styles/_variables.scss';
    @import '../assets/styles/_globals.scss';

    
    
</style>