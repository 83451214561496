//import Api from '@/services/Api'
function getPaymentOption(currency) {
  switch(currency) {
    case 'GHS' :
      return 'card, ussd, mobilemoneyghana'
      //break;
    case 'RWF' :
      return 'card, mobilemoneyrwanda'
      //break;
    case 'UGX' :
      return 'card, mobilemoneyuganda'
      //break;
    case 'ZMW' :
      return 'card, mobilemoneyzambia'
      //break;
    case 'KES' :
      return 'card, mpesa'
      //break;
    case 'NGN' :
      return 'card, account, ussd, barter, ussd'
      //break;
    case 'XOF' :
      return 'card, mobilemoneyfranco'
      //break;
    case 'XAF' :
      return 'card, mobilemoneyfranco'
      //break;
    case 'default' :
      return 'card'
      //break;
  }
}

async function callBackFlutter(payment, success = null, error = null, modal) {
  //console.log(payment)
  if(payment.status && payment.status == "successful") {
    let payload = {
      payment : 'Flutterwave',
      transaction : payment.transaction_id,
      email : payment.customer.email,
      //fullname : payloadData.firstname + " " + payloadData.lastname,
      //langue : payloadData.langue,
      status : 'success',
      meta : payment
    }
    await success(payload, modal)
    //console.log(state)
    //return state
  } else {
    let payload = {
      error : true
    }
    error(payload)
  }
  
}

function oncloseFlutter(imcomplete, error) {
  console.log(imcomplete)
  error(imcomplete)
}

async function callBackFeda(payment, success = null, error = null, modal) {
  if(payment.reason && payment.reason == "CHECKOUT COMPLETE") {
    let payload = {
      payment : 'Fedapay',
      transaction : payment.transaction.reference,
      //email : payment.customer.email,
      //fullname : payloadData.firstname + " " + payloadData.lastname,
      //langue : payloadData.langue,
      status : 'success',
      meta : payment
    }
    await success(payload, modal)
    //console.log(state)
    //return state
  } else {
    let payload = {
      error : true
    }
    error(payload)
  }
}

export default {
    processPayment(paymentType, payload, success = null, error = null) {
        switch(paymentType) {
            case 'Flutterwave':
                console.log(payload)
                //Api()
                // eslint-disable-next-line
                const modal =  FlutterwaveCheckout({
                    public_key: process.env.VUE_APP_FLUTTERWAVE_KEY,
                    tx_ref: payload.tx_ref,
                    amount: payload.amount,
                    currency: payload.currency,
                    //payment_options: "card, mobilemoneyghana, ussd",
                    payment_options: getPaymentOption(payload.currency),
                    //redirect_url: "https://glaciers.titanic.com/handle-flutterwave-payment",
                    callback: async function(payment) {
                        console.log(payment)
                        await callBackFlutter(payment, success, error, modal)
                        /*if (updateState == "success") {
                          console.log("okk ohh")
                          modal.close()
                        }*/
                    },
                    onclose: function(imcomplete) {
                      oncloseFlutter(imcomplete, error)
                    },
                    /*meta: {
                      consumer_id: 23,
                      consumer_mac: "92a3-912ba-1192a",
                    },*/
                    customer: {
                      email: payload.email,
                      //phone_number: "08102909304",
                      name: `${payload.firstname} ${payload.lastname}`,
                    },
                    customizations: {
                      title: 'Takafa Mindset',
                      description: 'Happy people, better organization',
                      logo: 'https://admin.takafamindset.com/takafa_backend/storage/email/takafamindset%20slogan%201.png',
                    },
                  });
                break
            case 'Fedapay':
                // eslint-disable-next-line
              let widget = FedaPay.init({
                public_key: process.env.VUE_APP_FEDAPAY_KEY,
                transaction: {
                  amount: payload.amount,
                  description: 'Takafa Mindset subscription',
                  custom_metadata : {
                    tx_ref : payload.tx_ref,

                  }
                },
                customer: {
                  email: payload.email,
                  lastname: payload.lastname,
                  firstname : payload.firstname,
                },
                //container: '#payment-modal'
                onComplete : async (obj)=> {
                  await callBackFeda(obj, success, error)
                }
              });
              widget.open();
              break
            default:
                break
        }
    }

}