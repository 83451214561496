
export default {
    displayTitle(title) {
        return title.split("_").join(" ");
    },
    titleCount(title, length){
        if ((title.split(" ").length > length)) {
            return [((title.split(" ")).slice(0, length + 1)).join(" "), true]
        } else {
            return [title, false]
        }
    }
}  

//module.exports = displayTitle