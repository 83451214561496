import Api from '@/services/Api'

export default {
    getAllProgram() {
        return Api().get(`${process.env.VUE_APP_URL}/program`)
    }, 
    getProgram(programId) {
        return Api().get(`${process.env.VUE_APP_URL}/program/${programId}`)
    },
    getPackage(packageId) {
        return Api().get(`${process.env.VUE_APP_URL}/program/package/${packageId}`)
    },
    
}