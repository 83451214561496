<template>
    <div class="playlist_component" >

        <div class="header">
            <div class="title_section">
                <h3 class="bd-lg bd-lg--sbold"> {{ title }} </h3>
            </div>
            <button @click="closeModal" :disabled="isAdding" class="bd-lg "><i class="pi pi-times"></i></button>
        </div>
        <div v-if="createActive" class="playlist_create">
            <input type="text" v-bind:placeholder="lang.playlist.create.playname" v-model="playlistName" class="bd-md input_sample">
            <button @click="createPlaylist" :disabled="!isPlayReady" class="btn">
                <span v-show="!isCreatePlay"> {{ lang.playlist.create.submit }} </span>
                <div v-show="isCreatePlay" class="play-create">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                </div>
            </button>
        </div>
        <ul class="play_list" v-else> 
            <li v-for="list in userPlaylists" :key="list.id">
                <span>
                    <input type="checkbox"   :ref="'listBox' + list.id"  v-model="thisplaylist" :value="list.id"  @change="addVideoPlaylist(list, videoId, $event)" :disabled="isAdding" v-if="!playObj[list.title]">
                    <div class="loading-add" v-else>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </span>
                <span class="bd-lg"> {{ list.title }} </span>
            </li>
            
        </ul>

    </div>
</template>

<script>
//import axios from 'axios';
import Api from '@/services/Api';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import playlistService from '@/services/PlaylistService';

export default {
    name : 'playlistComponent',
    setup () {
        const store  = useStore();
        const user = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        return {
            user
        }
    },
    data () {
        return {
            
            thisplaylist : [],
            //checked : [],
            playlistName : null,
            isAdding : false,
            isActifAdding : false,
            isPlayReady : false,
            isCreatePlay : false,
            //onShown : this.$refs.playCont.style.display
            //onShown : true,
        }
    },
    emits : ['closePlaylist', 'fetchPlay', 'added'],
    //props : ['userPlaylists', 'videoId', 'title', 'createProp'],
    props : {
        userPlaylists : {
            type : Array,
        },
        lang : Object,
        videoId : Number,
        title : String,
        createProp : Object,
        createActive : Boolean,
    },
    computed : {
        playObj() {
            let objPlay = new Object();
            for (let j = 0; j < this.userPlaylists.length; j++) {
                const playName = this.userPlaylists[j];
                objPlay[playName.title] = false
            }
            return objPlay;
        },
        
    },
    watch : {
        playlistName () {
            if (this.playlistName.trim() != "") {
                this.isPlayReady = true;
            } else {
                this.isPlayReady = false;
            }
        },
        userPlaylists () {
            if (this.videoId != undefined && this.userPlaylists != undefined) {
                //this.checkVideoPlaylist()
                return
            }
        },
        
    },
    methods : {
        closeModal() {
            this.$emit('closePlaylist');
            //alert("okay");
        },
        alertBox() {
            this.$emit('added')
        },
        alertRemoved() {
            this.$emit('removed')
        },
        autoRef(listId) {
            return `checkbox${listId}`
        },
        testShow() {
            console.log(this.onShown)
        },
        checkVideoPlaylist() {
            //alert ("okay");
            //console.log(playlist.videos);
            //console.log(event.target.checked);
            console.log(this.videoId)
            for(let i = 0; i < this.userPlaylists.length; i++) {
                let playlist = this.userPlaylists[i];
                //let event = new Event('change');
                //console.log(playlist)
                let videoExist = playlist.videos.filter(vid => vid.videoId == this.videoId);
                console.log(videoExist.length);
                if (videoExist.length == 1) {
                    console.log(`checked${i}`)
                    console.log(this.$refs[`listBox${playlist.id}`]);
                    this.$refs[`listBox${playlist.id}`].checked = true;
                    //this.$refs[`listBox${playlist.id}`].dispatchEvent(event)
                    //return true
                }
            }
            //return false
        },
        setEvent(event) {
            for(let i = 0; i < this.userPlaylists.length; i++) {
                let playlist = this.userPlaylists[i];
                this.$refs[`listBox${playlist.id}`].addEventListener('change', this.addVideoPlaylist(playlist, this.videoId, event));
                
            }
        },
        async addVideoPlaylist(list, videoId, event) {
            console.log(list, videoId, event.target.checked)
            if (event.target.checked == true) {
                this.isAdding = true;
                this.playObj[list.title] = true
                await Api().post(`${process.env.VUE_APP_URL}/playlist/addVideo/${list.id}`, { id : videoId}).then( async res => {
                    console.log(res);
                     //this.videoLiked = Boolean(res.data.liked);
                    this.playObj[list.title] = false;
                    await this.$emit('fetchPlay', 'add')
                    //this.isAdding = false;
                    //this.alertBox();
                })
                .catch (err => {
                    console.log(err)
                })
            } else if (event.target.checked == false) {
                this.isAdding = true;
                this.playObj[list.title] = true
                await playlistService.deleteVideo(list.id, videoId)
                .then(async res => {
                    console.log(res)
                    if (res.data.success) {
                        this.playObj[list.title] = false;

                            this.$emit('fetchPlay', 'remove');
                            //this.isAdding = false;
                            //this.alertRemoved();
                        
                        
                        
                        
                    }
                })
                .catch(err => {
                    console.log(err)
                })  
            }
        },
        getPayload() {
            return {
                title : this.playlistName,
                id : this.user.id
            }
        },
        
        async createPlaylist() {
            this.isPlayReady = false;
            this.isCreatePlay = true;
            const payload = this.getPayload()
            console.log(payload);
            await playlistService.createPlaylist(payload).then( res => {
                console.log(res);
                if (res.data.success) {
                    this.isCreatePlay = false
                    this.$emit('successCreated')
                }
            })
        }
    },
    mounted() {
        //this.setEvent(this.$event);
        //this.checkVideoPlaylist()
    }
}
</script>

<style lang="scss" scoped>
/*@import '../assets/styles/_variables.scss';
@import '../assets/styles/_globals.scss';
    .playlist_component {
        background-color: $white_text_color;
        box-sizing: border-box;
        border-radius : 2px;
        .header {
            @extend %flex-row;
            justify-content: space-between;
            //outline: 1px solid red;
            box-sizing: border-box;
            padding: 15px 15px;
            border-bottom: 2px solid $main_color;
            h4 {
                font-size: $font-medium;
                margin: 0;
            }
            button {
                @extend %btn-base;
                height: 20px;
                width: 20px;
                padding: 2px;
                font-size: $font-medium;
                background-color: $white_text_color;
                color: $main_color;
                &:hover {
                    background-color: $hover_white_back;
                    
                }
            }
        }

        ul {
            @extend %ul-reset;
            box-sizing: border-box;
            //outline : 2px solid purple;
            //display: flex;
            //flex-direction: column;
            //justify-content: center;
            //align-items: space-around;
            padding : 0 15px;
            li {
                @extend %flex-row;
                //box-sizing: border-box;
                justify-content: flex-start;
                padding: 5px 15px;
                input {
                    margin-right: 10px;
                    //margin-left: -5px;
                    height: 20px;
                }
                .loading-add {
                    @extend %lds-ring;
                    width : 15px;
                    height : 15px;
                    margin-top : 5px;
                    margin-right : 10px;
                    & div {
                        border-color : $main_color transparent transparent transparent;
                        height : 13px;
                        width : 13px;
                        margin : 0;
                    }
                }
            }
        }
        .playlist_create {
            padding: 5px 15px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 300px;
            height : 75px;
            input {
                @extend %input-base;
                box-sizing : border-box;
                width: 200px;
                outline : none;
                //border : 2px solid transparent;
                border-radius : 0px 4px 4px 0;
                border-left : 3px solid $main_color;
                transition : border border-left .1s ease-out;
                height: 40px;
                &:focus {
                    border : 2px solid $main_color_darker;
                }
            }

            button {
                @extend %btn-base;
                height : 40px;
                box-sizing: border-box;
                padding : 5px 10px;
                font-size : $label-font;
                font-weight: lighter;
                &:hover {
                    background-color: $main_color_darker;
                }
                .play-create {
                    @extend %lds-ring;
                    width: 20px;
                    height: 20px;
                    & div {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }*/
</style>