import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Login from '../views/Auth/login.vue';
import ForgotPassword from '../views/Auth/forgotpass.vue';
import ResetPassword from '../views/Auth/resetpass.vue';
import VideoView from '../views/VideoDetail.vue';
import Playlist from '../views/Playlist.vue';
import History from '../views/History.vue';
import WatchHistory from '../views/History/History.vue';
import SearchHistory from '../views/History/Search.vue';
import VideoLiked from '../views/History/LikedVideo.vue';
import Settings from '../views/Settings.vue';
import Account from '../views/Settings/Account.vue';
import Profile from '../views/Settings/Profile.vue';
import Search from '../views/Search.vue';
import Booking from '../views/Booking.vue';
import Verify from '../views/Auth/verify.vue';

const routes = [
  {
    path: '/:lang(fr)?',
    name: 'login',
    component: Login,
    /*beforeEnter : (to) => {
      console.log(to)
      
    },*/
    meta : { requiredAuth : false}
  },
  {
    path: '/login/:lang?',
    //name: 'login',
    
    redirect : to => {
      console.log(to)
      if (to.params.lang == "fr") {
        return '/fr'
      }
      return '/'
    }
  },
  {
    path: '/:lang(fr)?/forgot-password',
    name: 'forgotPass',
    component: ForgotPassword,
    meta : { requiredAuth : false}
  },
  {
    path: '/:lang(fr)?/reset-password',
    name: 'resetPass',
    component: ResetPassword,
    meta : { requiredAuth : false}
  },
  {
    path: '/:lang(fr)?/verify-email',
    name: 'verifyEmail',
    component: Verify,
    meta : { requiredAuth : false}
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta : { requiredAuth : true}
  },
  /*{
    path: '/about',
    name: 'About',
    
    component: () => import( '../views/About.vue'),
    meta : { requiredAuth : true}

  },*/
  {
    path: '/Video/:category/:title',
    name: "VideoDetail",
    component : VideoView,
    meta : { requiredAuth : true}

  },
  {
    path: '/playlist/:title',
    name : 'Playlist',
    component : Playlist,
    meta : { requiredAuth : true}

  },
  {
    path: '/search',
    name : 'Search',
    component : Search,
    meta : { requiredAuth : true}

  },
  {
    path: '/history',
    name : 'History',
    component : History,
    children : [
      {
        path: 'watch-history',
        name : 'Watch-History',
        component : WatchHistory,
      },
      {
        path: 'search-history',
        name : 'SearchHistory',
        component : SearchHistory
      },
      {
        path : 'liked-video',
        name : 'LikedVideo',
        component : VideoLiked
      }
    ],
    props : true,
    meta : { requiredAuth : true}

  },
  {
    path : '/settings',
    name : 'Settings',
    component : Settings,
    children : [
      {
        path: 'account',
        name : 'Account',
        component : Account,
      },
      {
        path: 'profile',
        name : 'Profile',
        component : Profile
      }
    ],
    props : true,
    meta : { requiredAuth : true}

  },
  {
    path: '/booking',
    name : 'Booking',
    component : Booking,
    meta : { requiredAuth : true}

  },
  {
    path :'/:notfound(.*)',
    redirect : to => {
      return '/home'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    
    return { top: 0 }
  },
})

router.beforeEach((to, from, next)=> {
  /*let loggedUser = false
  if (typeof(localStorage.getItem('user')) != (undefined || null)) {
    loggedUser = JSON.parse(localStorage.getItem('user'));
  } else {
    loggedUser = null;
  }*/
  //sessionStorage.clear()
  const loggedUser = sessionStorage.getItem('user');
  const require = to.matched.some(record => record.meta.requiredAuth)
  if (require && !loggedUser) {
    next({ name : 'login'})
  } else if(to.name == 'login' && loggedUser) {
    next({ name : 'Home'})
  } else next()
})

export default router
