<template>
    <div class="sup-container">
        <div class="illustration">
            <div class="logo-ctn">
                    <img :src="brandLogo">
            </div>
            <div class="true-illustraion">
                <img :src="svgLogo"/>
                <p class="bd-lg bd-lg--sbold">
                    {{ langue.reset.quote.content }}
                </p>
            </div>
            <!-- <div class="overlay"></div>
            <blockquote>
                <h4> {{ langue.reset.quote.title }} </h4>
                <cite> &ldquo; {{ langue.reset.quote.content }} &rdquo;</cite>
            </blockquote> -->
        </div>
        <div class="container">
            <div class="sub-header bd-sm bd-sm--sbold">
                <span>{{ langue.reset.account.text }}</span> <a href="https://takafamindset.com" target="_blank">{{ langue.reset.account.signup }}</a>
            </div>
            <header>
                <!-- <div class="logo-container">
                    <img :src="brandLogo">
                </div> -->
                <h3 class="hdl-md hdl-md--sbold"> {{ langue.reset.text1 }} </h3>
                <p> {{ langue.reset.text2 }} </p>
            </header>
            <!-- <hr/> -->
            <section v-if="isVerify == null" >
                <div class="loader">
                    <!-- <div></div>
                    <div></div>
                    <div></div>
                    <div></div> -->
                </div>
            </section>
            <section v-else-if="!isVerify" class="error-occurs">
                <h4>{{ langue.reset.invalidLink }}</h4>
            </section>
            <form class="form-container" @submit.prevent="reset" v-else>
                <div class="input-instance" :class="{'input-instance--content' : user.newPassword == '', 'input-instance--hascontent' : user.newPassword != '' }">
                    <input class="input-pass" :type="showPass(passShow)" name="newpassword" v-model="user.newPassword">
                    <label>{{ langue.reset.input.field1 }}</label>
                    <span class="eye-show"  @click="passShow = !passShow"><i :class="{'pi pi-eye': passShow, 'pi pi-eye-slash': !passShow}"></i></span>
                    <div class="error">
                        <span :class="{ 'error--active': error.state }"> {{ error.pass }} </span> 
                        
                    </div>
                </div>
                <div class="input-instance" :class="{'input-instance--content' : user.confirmPassword == '', 'input-instance--hascontent' : user.confirmPassword != '' }">
                    <input class="input-pass" :type="showPass(passConfShow)" name="loginpassword" v-model="user.confirmPassword">
                    <label>{{ langue.reset.input.field2 }}</label>
                    <span class="eye-show"  @click="passConfShow = !passConfShow"><i :class="{'pi pi-eye': passConfShow, 'pi pi-eye-slash': !passConfShow}"></i></span>
                    <div class="error">
                        <span :class="{ 'error--active': error.state }"> {{ error.confirmpass }} </span> 
                        
                    </div>
                    
                </div>
                <button class="log-in" type="submit" :disabled="loading">
                    <span v-show="!loading">Continue <i class="pi pi-arrow-right"></i></span>
                    <div v-show="loading" class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </button>
            </form>
            <!--<div class="language">  
                <span>Change Language </span> : <span>Français</span>
            </div>-->
        </div>
        <Transition name="success-email">
            <div class="alert-success" v-show="isResetSuccess">
                {{ resetMessage }}
            </div>
        </Transition>
        <!-- <button @click="isResetSuccess = !isResetSuccess">reset</button> -->
    </div>
    <footerNav :lang="currentLang"/>
</template> 

<script>

import takafaLogo from './../../assets/Images/Logo sans slogan.png';
import takafaLogin from './../../assets/Images/login-academy.svg';
import axios from 'axios'

import { useStore, mapState, mapActions, mapMutations } from 'vuex';
import { useRoute } from 'vue-router';
import { onBeforeMount, ref } from 'vue';
import footerNav from '@/components/footer.vue';

export default {
    //inject : ['store'],
    name: 'ResetPassword',
    setup() {
        const store = useStore();
        const route = useRoute();
        //const langue = store.getters['getLang'];
        const isVerify = ref(null);
        const verifyObj = ref([]);
        const langue = ref(null)
        const lang = store.getters['getLang'];
        const currentLang = ref(null)
        console.log(route)
        if (route.params.lang == "fr") {
            console.log("okay")
            langue.value = lang.FR;
            currentLang.value = "FR"
        } else {
            langue.value = lang.EN
            currentLang.value = "EN"
        }
        onBeforeMount(async () => {
            //return
            if (route.query.link == "") {
                //route.push('/login')
                return
            }
            await axios.post(`${process.env.VUE_APP_URL}/auth/forgot/verify/${route.query.link}`).then(res => {
                console.log(res);
                verifyObj.value = res.data;
                if (!verifyObj.value.verify) {
                    isVerify.value = false;
                } else {
                    isVerify.value = true
                }
            })
        })
        return {
            langue,
            currentLang,
            isVerify,
            verifyObj
        }
    },
    data () {
        return {
            user : {
                newPassword : '',
                confirmPassword : '',
            },
            passShow : false,
            passConfShow : false,
            error : {
                name : "",
                pass : "",
                confirmpass : "",
                state : false
            },
            brandLogo : takafaLogo,
            svgLogo : takafaLogin,
            loading : false,
            isResetSuccess : false,
            resetMessage : 'Null'
        }
    },
    computed: {
        ...mapState(['auth']),
        
    },
    components : {
        footerNav
    },
    methods: {
        
        async reset() {
            this.error.state = false
            if (this.user.newPassword  == null || this.user.newPassword.trim()  == "" ) {
                console.log("not")
                this.error.state = true;
                this.error.pass = this.langue.reset.input.error1;
                return
            }  else {
                this.error.pass = "";

            }
            if (this.user.confirmPassword  == null || this.user.confirmPassword.trim()  == "" ) {
                this.error.state = true;
                this.error.confirmpass = this.langue.reset.input.error1;
                return
            } else {
                this.error.confirmpass = "";

            }
            if (this.user.newPassword  !== this.user.confirmPassword ) {
                this.error.state = true;
                this.error.pass = this.langue.reset.input.error2;
                return
            } else {
                this.error.pass = "";
            }
            if(this.error.state) {

                
                return
            }
            
            //if (this.loading) return
            this.loading = true;
            const payload = {
                email : this.verifyObj.email,
                password : this.user.newPassword,
                link : this.$route.query.link
            }
            await axios.put(`${process.env.VUE_APP_URL}/auth/forgot/resetPassword`, payload).then( res => {
                console.log(res);
                if (res.status == 204) {
                    this.loading = false;
                    this.resetMessage = this.langue.reset.validReset
                    this.isResetSuccess = true;
                    setTimeout(()=> {
                        this.isResetSuccess = false;
                    }, 3000)                    
                    setTimeout(()=>{
                        if (this.currentLang == "FR") {
                            this.$router.push({ name: 'login' , params : { lang : 'fr'} })
                        } else {
                            this.$router.push({ name: 'login' })
                        }
                        //this.loading = false
                    }, 3000)
                } else {
                    const data  = res.data
                    if (!data || data.error) {
                        this.loading = false;
                        this.error.pass = data.message                    
                        return
                    } 

                }

            })
            .catch(err => {
                console.log(err)
            })
            

        },
        showPass($state) {
            if ($state) {
                return 'text'
            }
            return 'password'
        },
        ...mapActions([
            'increment',
            'signIn'
        ]),
        ...mapMutations({
            increment:'INCREMENT'
        }),
        
    }

}
</script>

<style lang="scss" scoped>
    
</style>