<template>
    <!-- <onboard-comp/> -->
    <Transition name="mobile-trans">
        <div class="side-list-style" id="sidebar-ctn" v-show="!isMobile || mobileShow" :class="{'side-list-style-mobile-app' : isMobile, 'side-list-style-fixed' : isSidebarFixed, 'side-list-style-reach' : isSidebarReach}" >
            <div class="lg-ctn" v-if="!isMobile">
                <router-link :to="'/home'">
                    <img v-bind:src="brandLogo" alt="Takafa mindset">
                </router-link>
            </div>
            <div class="user-mobile" v-else>
                <div class="profile-container">
                    <div class="profile-image">
                        <img :src=" imgUrl + user.photoUrl" alt="profile-user" v-if="user.photoUrl != null">
                            <i class="fas fa-user-circle" v-else></i>
                    </div>
                    <p> 
                    <span> {{ user.email }} </span> 
                        
                    <span> {{user.firstname }} {{ user.lastname }}</span> 
                    </p>
                </div>
            </div>
            <ul>
                <router-link :to="'/home'" :class="{ 'active bd-lg--sbold': (currentPath == 'Home' ? true : false) }"  class="bd-lg" id="home-btn"> <i class="bd-lg pi pi-home side-icon" ></i> {{ langObj.nav.sidebar.home }} </router-link>
                <router-link :to="'/playlist/' + playlist" :class="{ 'active bd-lg--sbold': (currentPath == 'Playlist' ? true : false) }"  class="bd-lg" id="playlist-btn"> <i class="bd-lg pi pi-list side-icon"></i> {{ langObj.nav.sidebar.playlist }} </router-link>
                <router-link :to="'/booking'" :class="{ 'active bd-lg--sbold': (currentPath == 'Booking' ? true : false) }"  class="bd-lg" id="booking-btn"> <i class="bd-lg pi pi-calendar side-icon" ></i> {{ langObj.nav.sidebar.booking }} </router-link>
                <router-link :to="'/history/watch-history'" :class="{ 'active bd-lg--sbold': (['History', 'Watch-History', 'SearchHistory', 'LikedVideo'].includes(currentPath) ? true : false) }"  class="bd-lg" id="history-btn"> <i class="bd-lg pi pi-history side-icon"></i> {{ langObj.nav.sidebar.history }} </router-link>
                <router-link :to="'/settings/account'" :class="{ 'active bd-lg--sbold': (currentPath == 'Account' ? true : false) }"  class="bd-lg" id="account-btn"> <i class="bd-lg pi pi-cog side-icon"></i> {{ langObj.nav.sidebar.account }} </router-link>
                <router-link :to="'/settings/profile'" :class="{ 'active bd-lg--sbold': (currentPath == 'Profile' ? true : false) }"  class="bd-lg" id="profile-btn"> <i class="bd-lg pi pi-wrench side-icon" ></i> {{ langObj.nav.sidebar.setting }} </router-link>
                <!-- <a> <i class="pi pi-home side-icon"></i> Home </a> -->
                <a v-if="followUp[0]" v-show="isMobile" :href="followUp[1]" target="_blank" id="follow-btn-mobile" class="bd-lg"><i class="pi pi-users" ></i>{{ langObj.nav.dropdown.follow }}</a>
                
                <li v-show="isMobile" @click="signOut" class="bd-lg"><i class="pi pi-sign-out"></i> {{ langObj.nav.dropdown.sign }}</li>
                <li class="btn" @click="playTour('replay')">
                    {{ langObj.nav.sidebar.tour }}
                </li>
            </ul>
            <div class="addition-nav">
                <slot></slot>
            </div>
            <onboardComp v-show="retakeTour" :triggerTour="retakeTour" ref="onboard" @isTourStart="onStateTour" @tourStart="playTour"/>
        </div>
    </Transition>
</template>

<script>
import { useRoute } from 'vue-router';
import { useStore} from 'vuex';
import { ref, computed, inject } from 'vue';
import takafaLogo from './../assets/Images/Logo sans slogan.png';
import onboardComp from '@/components/onboarding.vue';

export default {
    name : 'sidebar',
    setup() {
        const route = useRoute();
        const store = useStore();
        console.log(route);
        const isMobile = inject('isMobile');
        console.log(isMobile)
        const mobileShow = ref(computed(() => store.getters['getMobileShow']))
        let playlist = "Watch Later"
        const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
        //const currentPath = route.path.split('/')[route.path.split('/').length - 1];
        const imgUrl = store.getters['getImgUrl']; 
        const currentPath = route.name;
        console.log(currentPath);
        if (user.value.lang == "FR") {
            playlist = "Regarder plus tard"
        }
        const langObj = ref(store.getters['getLang']).value[user.value.lang]
        const followUp = ref (computed(()=> store.getters['getUserFollowUp']))
        return {
            currentPath,
            user,
            playlist,
            isMobile,
            mobileShow,
            imgUrl,
            langObj,
            followUp
        }
    },
    data() {
        return {
            brandLogo : takafaLogo,
            //isMobile : false,
            //mobileShow : false,
            retakeTour : false,
            isSidebarFixed : true,
            isSidebarReach : false,
        }
    },
    props: {
        
    },
    components : {
        onboardComp
    },
    methods : {
        signOut() {
            let signOut = this.$store.dispatch('signOut')
            if(signOut) {
                this.$router.push('/')
            }
        },
        playTour(state = 'replay') {
            //console.log("playing")
            this.isSidebarReach = false
            if (state == 'play') {
                console.log("first play")
                //this.isSidebarFixed = !this.isSidebarFixed
            }
            setTimeout(()=> {
                this.retakeTour = true
                this.$refs.onboard.replayTour(state)
            }, 500)
        },
        onStateTour(tourState = "start") {
            //this.isSidebarReach = false
            console.log("on state", this.retakeTour)
            if (tourState == "end") this.retakeTour = false
            this.isSidebarFixed = !this.isSidebarFixed
        }
    },

    mounted() {
        this.$nextTick(()=> {
            window.addEventListener("scroll", (event)=> {
                if ((window.innerHeight + Math.ceil(window.pageYOffset)) >= (document.body.offsetHeight - 250)) {
                    console.log("endding")
                    console.log(document.body.offsetHeight)
                    if (this.retakeTour) return
                    this.isSidebarReach = true
                } else {
                    this.isSidebarReach = false
                }
            
            })
        })
    }
}
</script>

<style>

</style>