<template>
    <div class="overlay-modal">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name : 'modalSlot',
    
}
</script>

<style>

</style>