<template>
    <div class="account">
        <div class="title_section">
            <h3 class="hdl-sm hdl-sm--sbold"> 
                {{ langObj.setting.account.title }} 
                <div class="line line-100"></div>
            </h3>  
        </div>
        <div class="account-container">
            <div class="tab-container">
                <h3 class="hdl-sm tag" :class="{'tag--active hdl-sm--sbold' : isProgram}" @click="isProgram = true" v-if="checkSectionProg">{{ langObj.setting.account.program.title }}</h3>
                <h3 class="hdl-sm tag" :class="{'tag--active hdl-sm--sbold' : (checkSectionProg ? !isProgram : true)}" @click="isProgram = false">{{  langObj.setting.account.plan.title  }}</h3>
            </div>
            <div class="program-container" v-show="isProgram" v-if="checkSectionProg">
                <!---<div class="header-sub-container">
                    <div class="title-base">
                        <h2>
                            {{ langObj.setting.account.program.title }}
                            <div class="line line-100"></div>
                        </h2>
                    </div>
                </div>-->
                <div class="info-container">
                    <div class="user-info">
                        <h4 class="bd-lg bd-lg--sbold">{{ langObj.setting.account.program.current }}</h4>
                        <p class="bd-lg" v-if="program != null"> {{ program.title }} </p>
                        <div class="dot-load" v-else></div>
                        
                    </div>
                    <div class="user-info">
                        <h4 class="bd-lg bd-lg--sbold">{{ langObj.setting.account.plan.current }}</h4>
                        <p class="bd-lg" v-if="pack != null"> {{ pack.title }} </p>
                        <div class="dot-load" v-else></div>
                    </div>
                    <div class="user-info">
                        <h4 class="bd-lg bd-lg--sbold">{{ langObj.setting.account.program.enroll }}</h4>
                        <p class="bd-lg"> {{ enrolled }} </p>
                    </div>
                    <div class="user-info">
                        <h4 class="bd-lg bd-lg--sbold">{{ langObj.setting.account.program.expire }}</h4>
                        <p class="bd-lg"> {{ expiredOn }} </p>
                    </div>
                </div>
            </div>
            <div class="plan-container" v-show="checkSectionProg ? !isProgram : true">
                <!--<div class="header-sub-container">
                    <div class="title-base">
                        <h2>
                            Subscription
                            <div class="line line-100"></div>
                        </h2>
                    </div>
                </div>-->
                <div class="info-container">
                    <div class="user-info">
                        <h4 class="bd-lg bd-lg--sbold">{{ langObj.setting.account.plan.current }}</h4>
                        <p class="bd-lg" v-if="subscription.subscription != null"> {{ langObj.payment.plan.text(subscription.subscription) }} </p>
                        <div class="dot-load" v-else></div>
                    </div>
                    <!--<div class="user-info">
                        <h4 class="bd-lg bd-lg--sbold">Enrolled</h4>
                        <p class="bd-lg" > 02 May 2022 </p>
                    </div>-->
                    <div class="user-info">
                        <h4 class="bd-lg bd-lg--sbold">{{ langObj.setting.account.program.expire }}</h4>
                        <p class="bd-lg" v-if="subscription.subscription != null"> {{ transDate(subscription.subscription_exp) }} </p>
                        <div class="dot-load" v-else></div>
                    </div>
                    <div class="user-info renew-ctn" v-if="user.subscription_status == 1 && program.title == 'No Program'">
                        <button class="btn bd-lg" type="submit" @click="isModal = true">
                            {{ langObj.setting.account.plan.renew }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <modal v-show="isModal">
            <div class="price-sub-ctn">
                <div class="title_section">
                    <h3>{{ langObj.payment.title }}</h3>
                </div>
                <p class="bd-lg bd-lg--sbold desc-text">
                    {{ langObj.payment.description }}
                </p>
                <button @click="isModal = false">
                    <i class="pi pi-times"></i>
                </button>
                <div class="price-container" v-show="isPriceActive">
                    <div class="each-price-container" v-for="plan in subscription.plans" :key="plan.title">
                        <header class="price-header_container">
                            <p class="hdl-md hdl-md--sbold"> {{ langObj.payment.plan.text(plan.title) }} </p>
                        </header>
                        <div class="price-sub_container">
                            <div class="true-price">
                                <div class="price-show hdl-sm hdl-sm--sbold">
                                    <p>{{ plan.price }}$</p>
                                    
                                </div>
                                <p class="bd-md"> {{ langObj.payment.plan.currency }} </p>
                                <div class="price-show bd-lg bd-lg--sbold" >
                                    <p>
                                        {{ plan.local_price[0] }}
                                    </p>
                                    
                                </div>
                            </div>
                            <div class="duration">
                                <!-- <div v-html="$feathericons['clock'].toSvg()"></div> -->
                                <i class="pi pi-clock bd-md bd-md--sbold"></i>
                                <p class="bd-md bd-md--sbold">
                                    {{ langObj.payment.plan.text(plan.title) }}
                                </p>
                            </div>
                            <button class="btn bd-md" @click="processPayModal(plan)">{{ langObj.payment.plan.checkout }}
                            </button>
                        
                            <div class="">
                                
                                <ul class="">
                                    <!--<li v-for="asset in langueSection.plans.Basic.features.list" :key="asset.title">
                                        <div v-html="$feathericons['check'].toSvg()"></div>
                                        <p class="bd-sm"> {{ asset.title }}</p>
                                    </li>-->
                                </ul>
                            </div>
                        </div>
                    
                    </div>
                    
                </div>
                <div v-if="subscription.plans.length <= 0 || isPaymentProcess" class="spinner-ctn">
                    <div class="inner-spinner">

                    </div>
                </div>
                <div class="success-ctn" v-show="isPaymentSuccess">
                    <div class="load-complete">
                        <div class="checkmark draw"></div>
                    </div>
                </div>
                <div class="process bd-lg bd-lg--sbold" v-show="isPaymentProcess || isPaymentSuccess || isPaymentFail">
                    <div v-if="isPaymentProcess">
                        <span>{{ langObj.payment.process.default }}</span>
                        <div class="dot-process"></div>
                    </div>
                    <div v-else-if="isPaymentSuccess">
                        <span>{{ langObj.payment.process.complete }}</span>
                    </div>
                    <div v-else-if="isPaymentFail">
                        <span> {{ processText }} </span>
                    </div>
                </div>
                
            </div>
        </modal>
        
    </div>
</template>

<script>
import { ref , computed, onBeforeMount } from 'vue';
import { useStore, mapGetters } from 'vuex';
import programService from '@/services/ProgramService.js';
import subscriptionService from '@/services/SubscriptionService.js';
import paymentService from '@/services/PaymentService.js';
import moment from 'moment';
import modal from '@/components/modal';
export default {
    name : 'Account',
    setup() {
        const store = useStore(); 
        const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
        console.log(user);
        let lang = user.value.lang;
        const langObj = ref(store.getters['getLang']).value[user.value.lang];
        const program = ref(null);
        const pack = ref(null);
        const subscription = ref({
            plans : [],
            subscription : null
        });
        //createOnboadCookie
        //cookieManager.deleteCookie("onBoard")
        // Get current Program
        onBeforeMount(async()=> {
            
            await programService.getProgram(user.value.program_status).then( res => {
                console.log(res.data)
                program.value = res.data
                if (lang == "FR") {
                    //moment.locale('fr')
                    if (program.value.title == "Self Empowerment Program") {
                        program.value.title = "Programme d'Autonomisation de soi"
                    } else if (program.value.title == "Character and Career Development Program") {
                        program.value.title = "Programme du développement du caractère et de la carrière"
                    }
                }
            })
            await programService.getPackage(user.value.program_package).then (res => {
                console.log(res)
                pack.value = res.data
            })
            await subscriptionService.getUserSubscription(user.value.id).then( res => {
                console.log(res)
                subscription.value = res.data
                subscription.value.plans.shift()
                console.log(subscription.value)
            })
        })
        return {
            langObj,
            program,
            pack,
            lang,
            user,
            subscription
        }
    },
    data () {
        return {
            isProgram : true,
            //isPriceActive : true,
            isModal : false,
            isPaymentProcess : false,
            isPaymentFail : false,
            isPaymentSuccess : false,
            processText : this.langObj.payment.process.default
        }
    },
    props : {
        link : String
    },
    components : {
        modal
    },
    computed : {
        enrolled () {
            let date  = new Date(this.user.createdAt.split(" ")[0]);
            //return date.getDay() + " " + date.getMonth() + " " + date.getFullYear()
            //return date.toLocaleDateString()
            if (this.lang == "FR") {
                moment.locale("fr")
                return moment(date).format("Do MMM YYYY")

            }
            return moment(date).format("MMM Do YYYY")
        },
        expiredOn() {
            let date  = new Date(this.user.subscription_exp.split(" ")[0]);
            //return date.getDay() + " " + date.getMonth() + " " + date.getFullYear()
            //return date.toLocaleDateString()
            if (this.lang == "FR") {
                moment.locale("fr")
                return moment(date).format("Do MMM YYYY")

            }
            return moment(date).format("MMM Do YYYY")
        },
        checkSectionProg() {
            if(this.program && (this.program.title != 'No Program')) {
                return true
            }
            return false 
        },
        ...mapGetters(['currentUser', 'getToken']),
        isPriceActive (){
            if (this.isPaymentFail || this.isPaymentProcess || this.isPaymentSuccess) {
                return false
            }
            if (this.subscription.plans && this.subscription.plans.length > 0) {
                return true
            }
            return false
        }
    },
    methods : {
        transDate(dateTo) {
            let date  = new Date(dateTo.split(" ")[0]);
            if (this.lang == "FR") {
                moment.locale("fr")
                return moment(date).format("Do MMM YYYY")

            }
            return moment(date).format("MMM Do YYYY")
        },
        initScripts() {
            let flutterwave =  document.createElement('script');
            flutterwave.setAttribute('src' , 'https://checkout.flutterwave.com/v3.js');
            document.body.appendChild(flutterwave);
            
            let fedapay = document.createElement('script');
            fedapay.setAttribute('src' , 'https://cdn.fedapay.com/checkout.js?v=1.1.7');
            
            document.body.appendChild(fedapay);
        },
        processPayModal(plan) {
            console.log(plan)
            let payload= {
                userId : this.user.id,
                payment : this.subscription.payment,
                tx_ref : this.generateReference(),
                amount : plan.local_price[1],
                currency : plan.local_price[2],
                firstname : this.user.firstname,
                lastname : this.user.lastname,
                email : this.user.email
            }
            console.log(payload)
            this.isPaymentProcess = true
            //return
            subscriptionService.renewSubscription(payload).then((res) => {
                console.log(res)
                console.log(paymentService)
                let pay = "Nope"
                if (this.subscription.payment == "FlutterWave") {
                    console.log("flutterwave")
                    pay = "Flutterwave"
                } else if (this.subscription.payment == "FedaPay") {
                    console.log("fedapay")
                    pay = "Fedapay"
                }
                paymentService.processPayment(pay, payload, async (payment, modal = null) => {
                    console.log("payfull", payment)
                    if (payment.status == "success") {
                        let postPayload = {
                            transactionId : payment.transaction,
                            state : 'success',
                            meta_data : payment.meta,
                            tx_ref : payload.tx_ref,
                            userId : this.user.id,
                            subscriptionId : plan.id
                        }
                        console.log(postPayload)
                        await subscriptionService.updateSubscription(postPayload).then(async (res) => {
                            console.log(res)
                            if (res.data.success) {
                                if (pay == "Flutterwave") modal.close()
                                console.log("success ohhh")
                                //return "success"
                                this.isPaymentProcess = false
                                this.isPaymentSuccess = true
                                await this.$store.dispatch('getCurrentUser', [this.getToken, this.user.id])
                                await this.updateSubscription()
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }, (imcomplete) => {
                    if (imcomplete) {
                        //alert("Imcomplete payment")
                        this.isPaymentProcess = false;
                        this.processText = this.langObj.payment.process.fail
                        this.isPaymentFail = true
                    } else {
                        alert("payment success")
                    }
                })
            })
            .catch((err) => {
                console.log(err)
            })
            
        },
        generateReference(){
            let date = new Date()
            return date.getTime().toString();
        },
        async updateSubscription() {
            await subscriptionService.getUserSubscription(this.user.id).then( res => {
                console.log(res)
                this.subscription = res.data
                this.subscription.plans.shift()
                console.log(this.subscription)
            })
        }
    },
    mounted() {
        //console.log(this.subscription)
        this.initScripts()
        //console.log(this.$store.getters.getToken)
        console.log(process.env)
        //cookieManager.displayCookies("#cookie_id")
    }
}

</script>

<style lang="scss" scoped>
    /*@import '../../assets/styles/_variables.scss';
    @import '../../assets/styles/_globals.scss';

    .account {
        width : 100%;
        .account-container {
            box-sizing: border-box;
            //outline: 1px solid red;
            padding: 5px 20px;
        }

        .info-container {
            //outline: 1px solid green;
            display: grid;
            grid-template-columns: auto auto;
            grid-row-gap: 20px;
            grid-column-gap: 0px;
            box-sizing: border-box;
            padding: 5px;
            @media screen and (max-width : $tablets) {
                grid-template-columns: auto;
                justify-items : center;
            }
            .user-info {
                //outline: 1px solid orangered;
                display: flex;
                //justify-content: space-around;
                align-items: center;
                position : relative;
                //min-width: 50%;
                //width: 325px;
                max-width: 80%;
                box-sizing: border-box;
                height: 75px;
                padding: 0 10px;
                //margin: 0 200px;
                
                h4 {
                    display: inline-block;
                    font-weight: 600;
                    text-align: left;
                    //outline: 1px solid blue;
                    //width: 100px;
                    margin: 0;
                }
                input {
                    @extend %input-base;
                    display: inline-flex;
                    position : relative;
                    box-sizing : border-box;
                    outline : none;
                    border: 1px solid $input_border_color;
                    height: 50%;
                    min-width: 100px;
                    border-radius: 2px;
                    padding: 5px 10px;
                    &:hover {
                        outline: none;
                        border-color: $general_black_color;
                    }
                    &:focus {
                        outline: none;
                        border-color: $main_color;
                        transition: border .2s cubic-bezier(0.39, 0.575, 0.565, 1);
                    }
                }
                .error-input {
                    border-color : $form_error;
                }
                p {
                    display: inline-flex;
                    box-sizing: border-box;
                    padding: 5px 10px;
                    margin-left: 20px;
                    background: $hover_white_back;
                    height: 50%;
                    //display: flex;
                    //min-width: 100px;
                    justify-content: flex-start;
                    align-items: center;
                    border-radius: 2px;
                }
                span {
                    position: absolute;
                    //right: calc(50% - 100px);
                    right: 0px;
                    bottom : -2px;
                    display: none;
                    color : $form_error;
                }
                .error {
                    display : unset
                }
            }
        }
    }*/
</style>