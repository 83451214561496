import Api from '@/services/Api'

export default {
    getUserPlaylists(userId) {
        return Api().get(`${process.env.VUE_APP_URL}/users/playlists/${userId}`)
    }, 
    /*getBookByUserId(userId) {
        return Api().get(`${process.env.VUE_APP_URL}/booking/${userId}`)
    },
    insertBooking(data) {
        return Api().post(`${process.env.VUE_APP_URL}/booking/add`, data)
    },
    updateBooking(userId, data) {
        return Api().put(`${process.env.VUE_APP_URL}/booking/update/${userId}`, data)
    },*/
    updateUserEmail(userId, data) {
        return Api().put(`${process.env.VUE_APP_URL}/users/updateEmail/${userId}`, data)
    },
    updateUserInfo(userId, data) {
        return Api().put(`${process.env.VUE_APP_URL}/users/updateInfo/${userId}`, data)
    },
    updateUserPassword(userId, data) {
        return Api().put(`${process.env.VUE_APP_URL}/users/updatePassword/${userId}`, data)
    },
    updateUserProfile(userId, data, headers) {
        return Api().post(`${process.env.VUE_APP_URL}/users/updateProfile/${userId}`, data, headers)

    },
    deleteById(userId) {
        return Api().delete(`${process.env.VUE_APP_URL}/booking/${userId}`)
    }
}