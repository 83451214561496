import Api from '@/services/Api'

export default {
    getAllBook() {
        return Api().get(`${process.env.VUE_APP_URL}/booking`)
    }, 
    getBookByUserId(userId) {
        return Api().get(`${process.env.VUE_APP_URL}/booking/${userId}`)
    },
    insertBooking(data) {
        return Api().post(`${process.env.VUE_APP_URL}/booking/add`, data)
    },
    updateBooking(userId, data) {
        return Api().put(`${process.env.VUE_APP_URL}/booking/update/${userId}`, data)
    },
    
    deleteById(userId) {
        return Api().delete(`${process.env.VUE_APP_URL}/booking/${userId}`)
    }
}