<template>
    <div class="profile-setting">
        <div class="title_section">
            <h3 class="hdl-sm hdl-sm--sbold">
                {{  langObj.setting.profile.title }}
                <div class="line line-100"></div>
            </h3>
        </div>
        <div class="profile-container">
            <div class="tab-container">
                <h3 class="hdl-sm tag" :class="{'tag--active hdl-sm--sbold' : isProfileInfo}" @click="isProfileInfo = true">
                    {{ langObj.setting.profile.info.title }}
                </h3>
                <h3 class="hdl-sm tag" :class="{'tag--active hdl-sm--sbold' : !isProfileInfo}" @click="isProfileInfo = false">
                    {{ langObj.setting.profile.password.title }}
                </h3>
            </div>
            <div class="form-container" v-show="isProfileInfo">
                <div class="row" v-show="!isEdit">
                    <!-- <span class="bd-md bd-sm--sbold"> Edit </span> -->
                    <button  class="button" @click="isEdit = true">
                        <i class="pi pi-pencil"></i>
                    </button>
                </div>
                <div class="inner-form">
                    <div class="image-profile">
                        <div class="avatar">
                            <div class="profile">
                                <img :src="imgUrl + photoUpdateUrl" v-if="user.photoUrl != null"/>
                                <i v-else class="pi pi-user"></i>
                            </div>
                            <button class="button-cam bd-lg"  v-show="isEdit" @click.prevent="onPickFile" :disabled="isProfileUpload">
                                <i class="pi pi-camera" v-if="!isProfileUpload"></i>
                                <div class="loading-add" v-else>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                            <input ref="fileUpload" type="file" id="file-handler" @change.prevent="onFilePicked"/>
                        </div>
                        <div class="error-image">
                            <span :class="{'error' : validate.image.error }"> {{ validate.image.message }} </span>
                        </div>
                    </div>
                    <form @submit.prevent="submitForm">
                        <div class="info-container">
                            <div class="user-info">
                                <h4>{{ langObj.setting.profile.info.firstname }}</h4>
                                <p v-if="!isEdit"> {{ user.firstname }} </p>
                                <input :class="{'error-input' : validate.errorName }" v-else type="text" :placeholder="langObj.setting.profile.info.placeholder.firstname"  v-model="updateInfo.firstName"/>
                                <span :class="{'error' : validate.errorName }"> {{ validate.message }} </span>
                            </div>
                            <div class="user-info">
                                <h4>{{ langObj.setting.profile.info.lastname }}</h4>
                                <p v-if="!isEdit"> {{ user.lastname }} </p>
                                <input :class="{'error-input' : validate.errorLast }" v-else type="text" :placeholder="langObj.setting.profile.info.placeholder.lastname" v-model="updateInfo.lastName"/>
                                <span :class="{'error' : validate.errorLast }" > {{ validate.message }} </span>
                            </div>
                            <div class="user-info">
                                <h4>{{ langObj.setting.profile.email.email }}</h4>
                                <p v-if="!isEdit"> {{ user.email }} </p>
                                <input :class="{'error-input' : validate.errorMail }" v-else type="email" :placeholder="langObj.setting.profile.email.placeholder.email" v-model="updateInfo.email"/>
                                <span :class="{'error' : validate.errorMail }"> {{ validate.message }} </span>
                            </div>
                        </div>
                        <div class="row submit-part" v-show="isEdit">
                            <span class="button-save cancel btn-outline bd-lg" @click="cancelEdit">
                                {{ langObj.setting.button.cancel }}
                            </span>
                            <button class="button-save btn bd-lg" type="submit" :disabled="isSaving1">
                                <span v-if="!isSaving1">{{ langObj.setting.button.save }}</span>
                                <div v-else class="save-edit">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="form-container" v-show="!isProfileInfo">
                <div class="row">
                    <button class="button" @click="isPasswordEdit = true">
                        <i class="pi pi-pencil"></i>
                    </button>
                </div>
                <form @submit.prevent="submitPasswordForm">
                    <div class="info-container">
                        <div v-show="!isPasswordEdit" class="user-info">
                            <h4>{{ langObj.setting.profile.password.password }}</h4>
                            <p> ******************** </p>
                        </div>
                        <div v-show="isPasswordEdit" class="user-info">
                            <h4>{{ langObj.setting.profile.password.currentPass }}</h4>
                            <input :class="{'error-input' : passwordValidate.errorOld }" type="password" placeholder="*****" v-model="passwordInfo.oldPassword"/>
                            <span :class="{'error' : passwordValidate.errorOld }"> {{ passwordValidate.message }} </span>
                        </div>
                        <div v-show="isPasswordEdit" class="user-info">
                            <h4>{{ langObj.setting.profile.password.newPass }}</h4>
                            <input :class="{'error-input' : passwordValidate.errorNew }" type="password" placeholder="*****" v-model="passwordInfo.newPassword"/>
                            <span :class="{'error' : passwordValidate.errorNew }"> {{ passwordValidate.message }} </span>
                        </div>
                        <div v-show="isPasswordEdit" class="user-info">
                            <h4>{{ langObj.setting.profile.password.confirmPass }}</h4>
                            <input :class="{'error-input' : passwordValidate.errorNew }" type="password" placeholder="*****" v-model="passwordInfo.confirmPassword"/>
                            <span :class="{'error' : passwordValidate.errorNew }"> {{ passwordValidate.message }} </span>
                        </div>
                    </div>
                    <div class="row submit-part" v-show="isPasswordEdit">
                        <span class="button-save cancel btn-outline bd-lg" @click="cancelEditPassword">
                            {{ langObj.setting.button.cancel }}
                        </span>
                        <button class="button-save btn bd-lg" type="submit" :disabled="isSaving2">
                            <span v-if="!isSaving2">{{ langObj.setting.button.save }}</span>
                            <div v-else class="save-edit">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="title_section">
            <h1>
                {{ langObj.setting.profile.email.title }}
                <div class="line line-100"></div>
            </h1>
        </div> -->
        <!-- <div class="form-container">
            <div class="row">
                <button class="button" @click="isEmailEdit = true">
                    <i class="fas fa-pen"></i>
                </button>
            </div>
            <form @submit.prevent="submitEmailForm">
                <div class="info-container">
                    <div class="user-info">
                        <h4>{{ langObj.setting.profile.email.email }}</h4>
                        <p v-if="!isEmailEdit"> {{ user.email }} </p>
                        <input :class="{'error-input' : validate.errorMail }" v-else type="email" :placeholder="langObj.setting.profile.email.placeholder.email" v-model="updateInfo.email"/>
                        <span :class="{'error' : validate.errorMail }"> {{ validate.message }} </span>
                    </div>
                    
                </div>
                <div class="row submit-part" v-show="isEmailEdit">
                    <span class="button-save cancel" @click="cancelEditEmail">
                        {{ langObj.setting.button.cancel }}
                    </span>
                    <button class="button-save" type="submit" :disabled="isDisableEmail">
                        <span v-if="!isEmailSave">{{ langObj.setting.button.save }}</span>
                        <div v-else class="save-edit">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </button>
                </div>
            </form>
        </div> -->
        <!-- <div class="title_section">
            <h1>
                {{ langObj.setting.profile.password.title }}
                <div class="line line-100"></div>
            </h1>
        </div> -->
        <!-- <profileUpload /> -->
        <alertModal :text="textAlert" v-show="isAlert" :isStand="isAlive" :class="{'up-alert' : isAlive}" @closing="closeAlert"/>

    </div>
</template>

<script>
//import profileUpload from '@/components/uploadProfile.vue';
    import { useStore} from 'vuex';
    import { ref, computed, } from 'vue';
    import alertModal from '@/components/alertModal';
    //import Api from '@/services/Api';
    //import AuthenticationService from '@/services/AuthenticationService';
    import userApi from '@/services/UserService.js';
    import axios from 'axios';
export default {
    name : 'Profile',
    setup() {
        const store = useStore(); 
        const user = ref(computed(()=> JSON.parse(store.getters['currentUser'])));
        const langObj = ref(store.getters['getLang']).value[user.value.lang];
        const imgUrl = store.getters['getImgUrl']; 
        console.log(user);
        /*const update = ref(computed(()=> store.getters['getStateUpdate']));
        const token = ref(computed(()=> store.getters['getToken']))
        if (update.value) {
            let getMe = store.dispatch('me', token, user.value.id )
            if(getMe.authorized) {
              //console.log(res.data)
                user.value = computed(()=> JSON.parse(store.getters['currentUser']))
                store.commit('SET_UPDATE', false)
            }
        }*/
        let fileUpdate = false;
        console.log(user);
        return {
            user,
            langObj,
            fileUpdate,
            imgUrl
        }
    },
    data () {
        return {
            isProfileInfo : true,
            isEdit : false,
            isEmailEdit : false,
            isPasswordEdit : false,
            isProfileUpload : false,
            isAlert : false,
            textAlert : '',
            isAlive : false,
            isSaving1 : false,
            isSaving2 : false,
            isEmailSave : false,
            updateInfo : {
                firstName : this.user.firstname,
                lastName : this.user.lastname,
                email : this.user.email,
                image : null,
            },
            passwordInfo : {
                oldPassword : null,
                newPassword : null,
                confirmPassword : null
            },
            passwordValidate : {
                error : false,
                errorOld : false,
                errorNew : false,
                message : ""
            },
            photoUpdateUrl : this.user.photoUrl,
            validate  : {
                error : false,
                errorName : false,
                errorLast : false,
                errorMail : false,
                image : {
                    error : false,
                    message : "fdv"
                },
                message : ""
            }
        }
    },
    computed : {
        getPhotoUrl() {
            if (this.fileUpdate) {
                return this.photoUpdateUrl;
            } else {
                return this.user.photoUrl;
            }
        },
        isDisableEmail() {
            if (this.updateInfo.email == this.user.email) {
                return true
            } else if (this.isEmailSave) {
                return true
            } else {
                return false
            }
        },
    },
    components : {
        //profileUpload,
        alertModal
    },

    methods : {
        onPickFile() {
            this.$refs.fileUpload.click();
        },
        onFilePicked(event) {
            console.log("okay");
            const files = event.target.files;
            console.log(files);
            this.updateInfo.image = files[0];
            console.log(this.updateInfo.image);
            this.uploadProfile();
        },
        cancelEdit() {
            console.log("cancelling");
            this.isEdit = false;
            this.validate.message = "";
            this.validate.errorName = false;
            this.validate.errorLast = false;
            this.validate.errorMail = false;
            this.validate.image.error = false;
            this.validate.image.message = "";
            this.cancelEditEmail()

        },
        cancelEditPassword() {
            this.isPasswordEdit = false;
            this.passwordValidate.error = false;
            this.passwordValidate.errorOld = false;
            this.passwordValidate.errorNew = false;
            this.passwordValidate.message = "";
        },
        cancelEditEmail() {
            this.isEmailEdit = false;
            this.validate.message = "";
            this.validate.errorMail = false;
            
        },
        
        validateInput() {
            this.validate.error = false;
            this.validate.errorName = false;
            this.validate.errorLast = false;
            console.log(this.updateInfo.firstName);
            if (!this.validateTextInput(this.updateInfo.firstName)) {
                //
                this.validate.error = true;
                this.validate.errorName = true;
            }  
            if (!this.validateTextInput(this.updateInfo.lastName)) {
                this.validate.error = true; 
                this.validate.errorLast = true;
            } 
            /*if (this.updateInfo.email == (null || "" || undefined)) {
                this.validate.error = true; 
                this.validate.errorMail = true;
            } else {
                console.log("no error");
                this.validate.error = false; 
                this.validate.errorMail = false;
            }*/
            if (this.validateMail()) {
                this.validate.error = true; 
            }
            console.log(this.validate.error)

            this.validate.message = this.langObj.setting.profile.info.error;
            return this.validate.error;
        },
        validateTextInput(text) {
            if ((text == undefined) || (text == null)) {
                return false
            }
            text = text.trim()
            if (text == "") {
                return false
            }
            return true;
        },
        mailTesting(email) {
            //let pattern = new RegExp("[a-z0-9]+", "i");
            let pattern2 = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            if (pattern2.test(email)) {
                return true
            }
            return false
        },
        validateMail() {
            this.validate.error = false; 
            this.validate.errorMail = false;
            if (!this.validateTextInput(this.updateInfo.email) || !this.mailTesting(this.updateInfo.email)) {
                this.validate.error = true; 
                this.validate.errorMail = true;
            }
            this.validate.message = this.langObj.setting.profile.email.error;
            return this.validate.errorMail;
        },
        validateFile() {
            let imageUpload = this.updateInfo.image;
            console.log(imageUpload);
            let allowType = ['jpg', 'jpeg', 'png'];
            if (imageUpload != null) {
                if (!allowType.includes(imageUpload.name.split('.')[1])) {
                    this.validate.error = true;
                    this.validate.image.error = true;
                    this.validate.image.message =  this.langObj.setting.profile.info.image.error1;
                } else if (imageUpload.size >= 2000000) {
                    this.validate.error = true;
                    this.validate.image.error = true;
                    this.validate.image.message =  this.langObj.setting.profile.info.image.error2;
                }
            }
            return this.validate.image.error
        },
        validatePassword() {
            if (this.passwordInfo.oldPassword == null || this.passwordInfo.oldPassword == ""  || this.passwordInfo.oldPassword == undefined) {
                this.passwordValidate.error = true;
                this.passwordValidate.errorOld = true;
                this.passwordValidate.message = this.langObj.setting.profile.password.error;

            } else {
                this.passwordValidate.errorOld = false;
                this.passwordValidate.error = false;
            } 
            if(this.passwordInfo.newPassword == (null || "" || undefined)) {
                this.passwordValidate.error = true;
                this.passwordValidate.errorNew = true;
                this.passwordValidate.message = this.langObj.setting.profile.password.error;
            } else if (this.passwordInfo.confirmPassword !== this.passwordInfo.newPassword) {
                this.passwordValidate.error = true;
                this.passwordValidate.errorNew = true;
                this.passwordValidate.message = this.langObj.setting.profile.password.error2;
            } 
            return this.passwordValidate.error;
        },
        async submitForm() {
            console.log("submiting");
            let isNotValidate = this.validateInput();
            if (isNotValidate) {
                return
            }
            
            this.isSaving1 = true;
            const payload = {
                firstName : this.updateInfo.firstName,
                lastName : this.updateInfo.lastName,
                email : this.user.email,
                //file : this.updateInfo.image
            }
            /*let send = new FormData();
            send.append('firstName', this.updateInfo.firstName);
            send.append('lastName', this.updateInfo.lastName);
            send.append('email', this.updateInfo.email);
            send.append('file', this.updateInfo.image);*/
            console.log(payload);
            //return
            await userApi.updateUserInfo(this.user.id, payload).then( async (res) => {
                console.log(res);
                if (res.data.error) {
                    this.isSaving1 = false;
                    this.validate.error = true; 
                    //this.validate.errorMail = true;
                    this.validate.message = res.data.message;
                    return
                }
                if (res.data.success) {
                    if (this.user.email != this.updateInfo.email) {
                        let payload =  {
                            email : this.updateInfo.email
                        }
                        await userApi.updateUserEmail(this.user.id, payload).then( async (res) => {
                            console.log(res);

                            if (res.data.error) {
                                this.isSaving1 = false;
                                this.validate.error = true; 
                                this.validate.errorMail = true;
                                this.validate.message = res.data.message;
                                return
                            } else if (res.data.success) {
                                this.textAlert = this.langObj.setting.profile.alertBox.profile + res.data.message
                                await this.$store.dispatch('getCurrentUser', [this.$store.getters.getToken, this.user.id]).then( (res) => {
                                    if (res) {
                                        this.isSaving1 = false;
                                        this.isEdit = false;
                                        this.isAlert = true;
                                        this.isAlive = true
                                    }
                                })
                            }
                
                        })
                    } else {
                        await this.$store.dispatch('getCurrentUser', [this.$store.getters.getToken, this.user.id]).then(res => {
                            if (res) {
                                this.isSaving1 = false;
                                this.textAlert = this.langObj.setting.profile.alertBox.profile
                                this.isEdit = false;
                                this.isAlert = true;
                                setTimeout(() => {
                                    this.isAlert = false
                                }, 2000);

                            }
                        })
                    }
                }
            })
        },
        async submitPasswordForm() {
            let checkValidatePassword = this.validatePassword();
            if (checkValidatePassword) {
                return
            }
            this.isSaving2 = true;

            const payload = {
                oldPassword : this.passwordInfo.oldPassword,
                newPassword : this.passwordInfo.newPassword,                
            }
            console.log(payload);
            //return
            await userApi.updateUserPassword(this.user.id, payload).then( async (res) => {
                console.log(res);
                if (res.data.error) {
                    this.isSaving2 = false;
                    this.passwordValidate.error = true; 
                    this.passwordValidate.errorOld = true; 
                    this.passwordValidate.message = res.data.message;
                    return
                }
                this.textAlert = this.langObj.setting.profile.alertBox.password
                this.isSaving2 = false;
                this.isPasswordEdit = false;
                this.isAlert = true;
                setTimeout(() => {
                    this.isAlert = false
                }, 2000);
                await this.$store.dispatch('getCurrentUser', [this.$store.getters.getToken, this.user.id])
            })
        },
        async submitEmailForm () {
            let isValidatMail = this.validateMail();
            if (isValidatMail) {
                //alert(isValidatMail)
                return
            }
            const payload =  {
                email : this.updateInfo.email
            }
            this.isEmailSave = true;
            //return validatMail;
            await userApi.updateUserEmail(this.user.id, payload).then( async (res) => {
                console.log(res);

                if (res.data.error) {
                    this.isEmailSave = false;
                    this.validate.error = true; 
                    this.validate.errorMail = true;
                    this.validate.message = res.data.message;
                    return
                }
                
                this.textAlert = res.data.message
                await this.$store.dispatch('getCurrentUser', [this.$store.getters.getToken, this.user.id]).then( (res) => {
                    if (res) {
                        this.isEmailSave = false;
                        this.isEmailEdit = false;
                        this.isAlert = true;
                        this.isAlive = true
                    }
                })
                /*setTimeout(() => {
                    this.isAlert = false
                }, 5000);*/
            })
        },
        uploadProfile() {
            if (this.validate.image.error) {
                return
            }
            this.isProfileUpload = true;
            let send = new FormData();
            send.append('file', this.updateInfo.image);
            console.log(send);
            send.forEach(el => console.log(el))
            //users/updateProfile
            //Services/ProfileUpload.php
            userApi.updateUserProfile(this.user.id, send, { headers: {
                    'Content-Type': 'multipart/form-data',
                }}).then( async (res) => {

                console.log(res);
                if (res.data.success) {
                    this.textAlert = this.langObj.setting.profile.alertBox.image
                    this.photoUpdateUrl = res.data.photoUrl,
                    this.$store.commit('SET_UPDATE', true);
                    
                    this.isProfileUpload = false;
                    this.isAlert = true;
                    setTimeout(() => {
                        this.isAlert = false
                    }, 2000);
                    await this.$store.dispatch('getCurrentUser', [this.$store.getters.getToken, this.user.id]);
                }
                //console.log(this.$store.getters.getToken);
                
                //console.log(data);
                /*if (data.authorized) {
                    //return;
                    console.log(this.user)
                }*/
            })
        },
        closeAlert() {
            this.isAlert = false;
            setTimeout(()=> {
                this.isAlive = false;
            }, 1000)
        }
    }
}
</script>

<style lang="scss" scoped>
    /*@import '../../assets/styles/_variables.scss';
    @import '../../assets/styles/_globals.scss';

    .profile-setting {
        box-sizing: border-box;
        width : 100%;
        //outline: 1px solid purple;
        //padding: 0 50px;
        .title_section {
            @extend %title-base;
            
        }
        .form-container {
            position: relative;
            width: 100%;
            //outline: 1px solid red;
            margin: 0;
            box-sizing: border-box;
            padding: 10px 5px;
            border-radius: 5px;
            &:hover {
                box-shadow: 2px 2px 15px $box-shadow-color;
                transition: box-shadow .2s ease-out;
            }
            .row {
                width: 100%;
                //outline: 1px solid purple;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .button {
                    @extend %btn-base;
                    height: auto;
                    padding: 5px 10px;
                    border-radius: 4px;
                    background-color: transparent;
                    color : $main_color;
                    &:hover {
                        background-color: $hover_white_back;
                    }
                }
                .button-save {
                    @extend %btn-base;
                    font-size : $base-font;
                    font-weight : normal;
                    box-sizing : border-box;
                    border-radius: 4px;
                    justify-self : center;
                    padding: 5px 15px;
                    &:hover {
                        background-color: $main_color_darker;
                    }
                    .save-edit {
                        @extend %lds-ring;
                        width: 20px;
                        height: 20px;
                        & div {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

            }
            .submit-part {
                @media screen and (max-width : $tablets) {
                    justify-content: space-around;
                }
                .cancel {
                    margin-right : 20px;
                    background-color: $white_text_color;
                    color : $main_color;
                    border : 2px solid $main_color;
                    &:hover {
                        background-color: $main_color;
                        color : $white_text_color;
                    }
                    @media screen and (max-width : $tablets) {
                        margin-right: unset;
                    }
                }
            }

            .avatar {
                @extend %profileStyle;
                position : relative;
                flex-direction: column;
                height: 100px;
                width: 100px;
                //margin: 0 auto;
                margin-left: 30px;
                justify-content: center;
                //outline: 1px solid green;
                @media screen and (max-width : $tablets) {
                    margin : 0 auto;
                }
                .profile {
                    @extend %img-profile-style;
                    //outline: 1px solid green;
                    height: 100%;
                    width: 100%;
                    font-size: 6.3em;
                    border-radius : 50%;
                    color: $icon-color;
                    & img {
                        position: relative;
                        width : 100px;
                        height : 100px;
                        border-radius : 50%;
                        object-fit: cover;
                    }
                }
                button {
                    @extend %btn-base;
                    height: auto;
                    position : absolute;
                    right : -10px;
                    top: 50%;
                    padding: 10px;
                    border-radius: 50%;
                    background-color: transparent;
                    color : $main_color;
                    display : flex;
                    align-items : center;
                    justify-content : center;
                    &:hover {
                        background-color: $hover_white_back;
                    }
                    .loading-add {
                        @extend %lds-ring;
                        width : 20px;
                        height : 20px;
                        margin-top : 5px;
                        //margin-right : 10px;
                        & div {
                            border-color : $main_color transparent transparent transparent;
                            height : 18px;
                            width : 18px;
                            margin : 0;
                        }
                    }
                }
                button:disabled {
                    background-color: $hover_white_back;
                }
                
                #file-handler {
                    display : none;
                }
            }
            .error-image {
                text-align : left;
                margin-left : 30px;
                & span {
                    margin-top : 2px;
                    display: none;
                    //width : 200px;
                }
                & .error {
                    display : unset;
                    color : $form_error
                }
            }
            form {
                //outline: 1px solid purple;
                box-sizing: border-box;
                padding: 5px 0px;
                margin-top: 10px;
                .info-container {
                    //outline: 1px solid green;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-row-gap: 20px;
                    grid-column-gap: 0px;
                    box-sizing: border-box;
                    padding: 5px;
                    @media screen and (max-width : $tablets) {
                        grid-template-columns: auto;
                        justify-items : center;
                    }
                    .user-info {
                        //outline: 1px solid orangered;
                        display: flex;
                        //justify-content: space-around;
                        align-items: center;
                        position : relative;
                        //min-width: 50%;
                        width: 325px;
                        max-width: 80%;
                        box-sizing: border-box;
                        height: 75px;
                        padding: 0 10px;
                        //margin: 0 200px;
                        
                        h4 {
                            display: inline-block;
                            font-weight: 600;
                            text-align: left;
                            //outline: 1px solid blue;
                            width: 100px;
                            margin: 0;
                        }
                        input {
                            @extend %input-base;
                            display: inline-flex;
                            position : relative;
                            box-sizing : border-box;
                            outline : none;
                            border: 1px solid $input_border_color;
                            height: 50%;
                            min-width: 100px;
                            border-radius: 2px;
                            padding: 5px 10px;
                            font-family : inherit;
                            &:hover {
                                outline: none;
                                border-color: $general_black_color;
                            }
                            &:focus {
                                outline: none;
                                border-color: $main_color;
                                transition: border .2s cubic-bezier(0.39, 0.575, 0.565, 1);
                            }
                        }
                        .error-input {
                            border-color : $form_error;
                        }
                        p {
                            display: inline-flex;
                            box-sizing: border-box;
                            padding: 5px 10px;
                            margin-left: 20px;
                            background: $hover_white_back;
                            height: 50%;
                            //display: flex;
                            //min-width: 100px;
                            justify-content: flex-start;
                            align-items: center;
                            border-radius: 2px;
                        }
                        span {
                            position: absolute;
                            //right: calc(50% - 100px);
                            right: 0px;
                            bottom : -2px;
                            display: none;
                            color : $form_error;
                        }
                        .error {
                            display : unset
                        }
                    }
                }   
                
            }
        }
        .up-alert {
            bottom : 75px;
        }
        
    } */
</style>