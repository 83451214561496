<template>
    <div class="liked-video">
        <div class="title_section">
            <h3 class="hdl-sm hdl-sm--sbold"> 
                {{ langObj.history.title }} / {{ langObj.history.sidebar.like }}
                <div class="line line-100"></div>
            </h3>  
        </div>
        <div v-if="videoEmpty == true" class="video_empty">
            <h2> {{ langObj.history.like.empty }} </h2>
        </div>
        <div class="video_list" v-else-if="videoLiked.length > 0">
            <div class="playlist_instance" v-for="play in videoLiked" :key="play.id">
                <div class="playlist_cover">
                    <img class="video-cover" :src="play.coverUrl + '-a.jpg'"/>
                    <div class="play-cover-overlay">
                        <router-link :to="'/Video/' + play.catTitle + '/' + play.vidTitle" class="btn-neutral bd-md">
                            {{ langObj.videoDetail.playlist.viewVideo }} <i class="fa fa-eye"></i>
                        </router-link>
                    </div>
                </div>
                <div class="playlist_description">
                    <p class="hdl-sm">{{ isMobile ? (wordCount(titleDisplay(play.vidTitle)))[0] : titleDisplay(play.vidTitle) }} </p>
                    <p class="author_desc hot-spot bd-lg bd-lg--sbold">{{ play.firstname }} {{ play.lastname }}</p>

                </div>
            </div>
        </div>
        <div v-else class="playlist-loading">
            <div class="play-loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="pagination" v-if="videoLiked != 0">
            <div class="pagination-wrapper">
                <button class="pg-left" v-if="activePage > 0" @click="getHistoryByPage(activePage -1)">
                    <i class="fas fa-chevron-left"></i>
                </button>
                <ul class="pages-number">
                    <li v-for="(page, index) in pageLength " :key="index" :class="{'page-active' : (index == activePage )}" @click="getHistoryByPage(index)">
                        {{page}}
                    </li>
                </ul>
                <button class="pg-right" v-if="activePage < (pageLength-1)" @click="getHistoryByPage(activePage + 1)">
                    <i class="fas fa-chevron-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
//import { useRoute } from 'vue-router';
import Api from '@/services/Api';
import { ref, onBeforeMount, computed, inject } from 'vue'
import { useStore } from 'vuex';
import reuseFunctions from '@/composables/function';

export default {
    name : 'VideoLiked',
    setup() {
        //const route  = useRoute();
        const store = useStore();
        const videoLiked = ref([]);
        //const playlists = ref([])
        const user = ref(computed(() => JSON.parse(store.getters['currentUser'])));
        const langObj = ref(store.getters['getLang']).value[user.value.lang]
        //console.log(route);
        const videoEmpty = ref();
        const videoLength = ref(0);
        const isMobile = inject('isMobile')
        onBeforeMount( async ()=> {
            await Api().get(`${process.env.VUE_APP_URL}/users/likedVideo/${user.value.id}`).then( res => {
                console.log(res)
                if (res.data.status == "empty") {
                    videoEmpty.value = true;
                    return
                } 
                videoLiked.value = res.data.likes
                videoLength.value = res.data.total

            })
        })

        return {
            videoLiked,
            user,
            langObj,
            videoEmpty,
            videoLength,
            isMobile
        }
    },
    data() {
        return {
            activePage : 0
        }
    },
    computed : {
        pageLength() {
            if (this.videoEmpty) {
                return 0;
            }
            return Math.ceil(this.videoLength / 4)
        },

    },
    methods : {
        titleDisplay(title) {
            return reuseFunctions.displayTitle(title);
        },
        wordCount(title) {
            return reuseFunctions.titleCount(title, 5)
        },
        getHistoryByPage(offset) {
            console.log(offset)
        }
    }

}
</script>

<style lang="scss">
    /*
    @import '../../assets/styles/_variables.scss';
    @import '../../assets/styles/_globals.scss';
    .liked-video {
        
        padding : 0;
        position: relative;
        min-height : 100%;
        .video_empty {
            width: 100%;
            h2 {
                font-weight : normal;

            }
        }
        .video_list {
            @extend %playlist-layout;
            height : 80vh;
            width: 100%;
            box-sizing : border-box;
            //outline :  2px solid red;
            
        }
        .playlist-loading {
            position : relative;
            display : flex;
            height : 80vh;
            width :100%;
            //outline : 2px solid red;
            justify-content: center;
            align-items : center;
            .play-loader {
                @extend %lds-ring;
                width : 40px;
                height : 40px;
                & div {
                    border-color : $main_color transparent transparent transparent;
                    border-width : 4px;
                    height : 35px;
                    width : 35px;
                    margin : 0;
                }
            }
            @media screen and (max-width : $mobile) {
                    width: 100vw;
            }
        }
        .pagination {
            @extend %pagination-bar
        }
        
    } */
</style>