<template>
  <section class="video">
    <div class="title_section">
      <h3 class="hdl-sm hdl-sm--sbold"> {{ titleCategory }} <div class="line line-50"></div></h3> 
    </div>
    
      <div class="agile_container video-slide-ctn">
        <button class="arrow left_arrow" @click="prevSlide" v-show="currentSlide !== 1">
          <i class="pi pi-angle-left"></i>
        </button>
        <div class="slider_part">
          <div class="slider_container" :style="{ transform : 'translateX(' + slidingSize + '%)'}">
            <slide v-for="(dataSet, current) in newDataVideo" :key="current" :datas="dataSet" :style="{ transform : 'translateX(' + transformSize * (current+0) + '%)'}" v-show="currentSlide === current + 1" :class="{'slide-single' : dataSet.length == 1, 'slide-double' : dataSet.length == 2, 'slide-single-mobile' : dataSet.length == 1 && isMobile,}">
            </slide>
          </div>
        </div>
        <button class="arrow right_arrow" @click="nextSlide" v-show="currentSlide  !== newDataVideo.length">
          <i class="pi pi-angle-right"></i>
        </button>
      </div>
      
  </section>
</template>

<script>
//import  { VueAgile } from 'vue-agile'
import { provide, inject } from 'vue'; 
//import { Slider, SliderItem } from 'vue-easy-slider';
import slide from '@/components/slide.vue';

export default {
    name : "videoSlider",
    setup (props) {
      //const currentSlide = ref(1);
      /*return {
        currentSlide,
      }*/
      const isMobile = inject('isMobile')
      provide('categoryTitle', props.titleCategory)
      return {
        isMobile
      }
    },
    props : {
      titleCategory : String,
      datas : Array,
    },
    data () {
      return {
        dataComputed : [],
        currentSlide : 1,
        sliderCount : null,
        transformSize : 100,
        slidingSize : 0,
        lengthSize : 3

      }
    },
    computed : {
      dataLength: {

        get() {
          let thLength
          //console.log(this.datas.length)
          if ((this.datas.length % this.lengthSize == 0)) {
            thLength = this.datas.length / this.lengthSize;
            
          } else {
            thLength = Math.ceil(this.datas.length / this.lengthSize);
            //thLength = Math.ceil(this.datas.length / this.lengthSize) + 1 ;
          }
          //console.log(thLength)
          let dataLen = this.datas.length;
          let finalLength = [];
          let num = -1;
          for (let i = 0; i < thLength; i++) {
            if ((i == thLength - 1) && dataLen < this.lengthSize) {
              finalLength.push(num + dataLen)
            } else {
              finalLength.push(num + this.lengthSize);
            }
            num += this.lengthSize;
            dataLen -= this.lengthSize
          }
          //console.log(finalLength);
          return finalLength
          //return this.set(3);
        },

        set(newSize) {
          this.lengthSize = newSize
        }
        //return thLength;
      },
      newDataVideo() {
        // [0, 1, 2, 3, 4, 5]
        // [2, 5] ; [1, 3, 5]
        let thisLength  = this.dataLength;
        //let thisLength  = this.dataLen;
        
        let arrayNew = [];
        let subArray = [];
        /*for (let index = 0; index < this.datas.length; index++) {
          
          thisLength.forEach(thisindex => {
            if (index == thisindex) {
              let indexLeft = index - (this.lengthSize - 1);
              let indexRight = index + 1 ;
              subArray = this.datas.slice(indexLeft, indexRight);
              arrayNew.push(subArray);
              subArray = [];
            } 
          });
        }*/
        let countInd = 0;
        thisLength.forEach(thisindex => {
          subArray = this.datas.slice(countInd, thisindex + 1);
          arrayNew.push(subArray);
          countInd = thisindex + 1
          subArray = [];
            
          });
        ////console.log(arrayNew)
        return arrayNew; 
      },
      countSlide() {
        return this.newDataVideo.length;
      }
    },
    components : {
      //Slider,
      //SliderItem
      //agile : VueAgile,
      slide
    },
    methods : {
      /*mountData () {
        let thisLength  = this.dataLength;
        ////console.log(thisLength)
        let arrayNew = [];
        let subArray = [];
        for (let index = 0; index < this.datas.length; index++) {
          //subArray.push(this.datas[index]);
          thisLength.forEach(thisindex => {
            if (index == thisindex) {
              let indexLeft = index - 2;
              let indexRight = index + 1;
              subArray = this.datas.slice(indexLeft, indexRight);
              arrayNew.push(subArray);
              subArray = [];
            } 
          });
        }
        //console.log(arrayNew)
        return arrayNew;
      },*/
      getSliderCount () {
        //let slidersLength = document.querySelectorAll(".wrapper").length;
        let slidersLength = this.newDataVideo.length;
        ////console.log(this.sliderCount);
        return slidersLength;
      },
      nextSlide() {
        /*if (this.currentSlide.value == this.sliderCount.value) {
          this.currentSlide.value = 1;
        }*/
        this.slidingSize -= 100;
        this.currentSlide += 1;
        ////console.log(this.slidingSize);
      },
      prevSlide() {
        this.slidingSize += 100;
        this.currentSlide -= 1;
      },
      onResize() {
        if (window.innerWidth <= 860) {
          this.dataLength = 1;
          ////console.log("mobile")
        } else if (window.innerWidth <= 960) {
          this.dataLength = 2
          ////console.log("resizing");
          ////console.log(Math.ceil(2.1))
        }   else {
          this.dataLength = 3
        }
      },
    },
    
    updated() {
      //this.dataComputed = this.mountData();
      this.sliderCount = this.getSliderCount();
      ////console.log(this.slidingSize);
    },
    mounted() {
      this.$nextTick(()=> {
        window.addEventListener('resize', this.onResize)
      })
      this.onResize();
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.onResize);
    },
    created() {
      this.onResize;
      
    }
}


</script>

<style lang="scss" scoped>


</style>